import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-popupjadwalinput',
  templateUrl: './popupjadwalinput.page.html',
  styleUrls: ['./popupjadwalinput.page.scss'],
})
export class PopupjadwalinputPage implements OnInit {

  tipe: number = 1;

  kliniks: any = null;
  jadwals: any = null;
  dates: any = null;
  selectedDate: any = null;

  root: any = null;

  calendar = {
    mode: 'month',
    currentDate: new Date(),
    locale: 'id',
  };

  minDate = new Date().toISOString();
  markDisabled = (date: Date) => {
    return date < new Date(this.minDate);
  }

  constructor(
    public auth: AuthService,
    private mc: ModalController,
    private params: NavParams,
  ) { }

  ngOnInit() {
    if (this.params.data.tipe != null){
      this.tipe = this.params.data.tipe;
    }
    if (this.params.data.kliniks != null){
      this.kliniks = this.params.data.kliniks;
    }
    if (this.params.data.jadwals != null){
      this.jadwals = this.params.data.jadwals;
    }
    if (this.params.data.dates != null){
      this.dates = this.params.data.dates;
    }
    if (this.params.data.selectedDate != null){
      this.selectedDate = this.params.data.selectedDate;
    }
    if (this.params.data.root != null){
      this.root = this.params.data.root;
    }
    if (this.tipe == 3){
      this.setMinDate();
    }
  }

  getText(tipe: number){
    if (tipe == 1){
      return 'Klinik';
    } else if (tipe == 2){
      return 'Waktu';
    } else if (tipe == 3){
      return 'Tanggal';
    }
  }

  selectKlinik(klinik){

  }

  onTimeSelected(ev) {
    this.selectedDate = ev.selectedTime;
    // const selected = new Date(ev.selectedTime);
    // this.dateChosen = true;
  }

  onSelectTime(jadwal) {
    const selectedJadwal = this.jadwals.find(data => data.id == jadwal.id);
    if (selectedJadwal.status == 'available') {
      this.jadwals.find(data => data.id == jadwal.id).status = 'fill';
    } else if (selectedJadwal.status == 'fill') {
      this.jadwals.find(data => data.id == jadwal.id).status = 'available';
    } else {
      this.auth.showToast('Waktu ini tidak tersedia.');
    }
  }

  setMinDate() {
    let today = new Date();
    if (today.getHours() >= 15) {
      this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, today.getHours()).toISOString();
      this.selectedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2, today.getHours()).toISOString();
    } else {
      this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours()).toISOString();
      this.selectedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, today.getHours()).toISOString();
    }
  }

}
