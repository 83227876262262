import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonSlides, IonInfiniteScroll } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { daftarHargaResponse } from 'src/app/services/api/api-response';

@Component({
  selector: 'app-daftarharga',
  templateUrl: './daftarharga.page.html',
  styleUrls: ['./daftarharga.page.scss'],
})
export class DaftarhargaPage implements OnInit {

  daftarHarga: any = null;
  daftarEdukasi: any = null;

  activeTab: number = 1;
  selectedContent = '1';
  sliderConfig = {
    slidesPerView: 1,
    spaceBetween: 20,
    centeredSlides: true
  };

  searchQuery: string = '';
  lastSearch: string = '';
  isSearching: boolean = false;

  tmpList: any = null;
  isLoadingList: boolean = false;
  firstCall: boolean = true;
  activePage: number = 1;

  @ViewChild('slider') slider: IonSlides;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  constructor(
    public auth: AuthService,
    public mc: ModalController,
    private api: ApiService,
    private iab: InAppBrowser,
  ) { }

  ngOnInit() {
    //this.auth.auto_show_popup_checkout = true;
  }

  ionViewWillEnter(){
    if (this.activePage == 1 && this.firstCall){
      this.daftarHarga = {data: []};
    }
    this.isLoadingList = true;

    let exec = '?search=' + this.searchQuery.split(' ').join('+');
    exec += '&page=' + this.activePage;
    this.api.apiGetDaftarHarga(this.auth.USER_TOKEN, exec).subscribe(res => {
      this.tmpList = res;
      if (res.data != null){
        for (let item of res.data){
          this.daftarHarga.data.push(item);
        }
      }
      if (this.firstCall){
        this.activePage++;
      }
      this.isLoadingList = false;
      this.firstCall = false;
    });
  }

  loadData(event) {
    this.tmpList = null;
    setTimeout(() => {
      this.doSearch();
      this.checkList(event);
    }, 100)
  }

  checkList(event: any){
    if (!this.isLoadingList && this.tmpList != null){
      if (this.tmpList.data.length < 10){
        event.target.disabled = true;
      } else {
        this.activePage++;
      }
      event.target.complete();
    } else {
      setTimeout(() => {
        this.checkList(event);
      }, 100);
    }
  }

  doSearch(reset: boolean = false){
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.daftarHarga = {data: []};
    }
    this.isLoadingList = true;

    if (this.lastSearch != this.searchQuery){
      let tmp = this.searchQuery;
      let exec = '?search=' + this.searchQuery.split(' ').join('+');

      exec += '&page=' + this.activePage;

      this.api.apiGetDaftarHarga(this.auth.USER_TOKEN, exec).subscribe(res => {
        this.tmpList = res;
        if (res.data != null && this.lastSearch == tmp){
          for (let item of res.data){
            this.daftarHarga.data.push(item);
          }
          this.isSearching = false;
        }

        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      });
      this.lastSearch = this.searchQuery;
      this.isSearching = true;
    } else {
      let tmp = this.searchQuery;
      let exec = '?search=' + this.searchQuery.split(' ').join('+');

      exec += '&page=' + this.activePage;

      this.api.apiGetDaftarHarga(this.auth.USER_TOKEN, exec).subscribe(res => {
        this.tmpList = res;
        if (res.data != null && this.lastSearch == tmp){
          for (let item of res.data){
            this.daftarHarga.data.push(item);
          }
          this.isSearching = false;
        }
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      });
      this.lastSearch = this.searchQuery;
      this.isSearching = true;
    }
  }

  displayHarga(harga){
    return "Rp" + Number(harga).toLocaleString('id') + ",00";
  }

  openPdf(target: string){
    if (target != null){
      // this.fo.open(target, 'application/pdf').then(() => {
      //
      // }). catch(e => console.log('Error opening file', e));
      let x = this.iab.create(target, '_system', 'location=no');
    } else {
      this.auth.showToast("Tidak ada file edukasi");
    }
  }

  segmentChanged(e) {
    if (e.detail.value == '1') {
      this.slider.slideTo(0);
      this.activeTab = 1;
    } else {
      this.slider.slideTo(1);
      this.activeTab = 2;
    }
  }

  sliderChanged(e) {
    this.slider.getActiveIndex().then((v) => {
      if (v == 0) {
        this.selectedContent = '1';
        this.activeTab = 1;
      } else {
        this.selectedContent = '2';
        this.activeTab = 2;
      }
    });
  }

}
