// Used to manage API requests through the whole app

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { retry } from 'rxjs/operators';
import { AppSettings } from 'src/app/settings/settings';
import { ErrorManagementService } from './error-management.service';
import {
  baseResponse, loginResponse, logoutResponse, doctorAuthResponse, pasienResponse, catatanResponse,
  listCatatanResponse, postPasienResponse, gigiResponse, posisiResponse, permukaanResponse, odontogramResponse,
  fotoResponse, lampiranResponse, examResponse, oralExamResponse, oralXResponse, oralTambahanResponse,
  kondisiResponse, statusSubResponse, statusResponse, rekomendasiResponse, singleGigiResponse, historyGigiResponse,
  listPasienResponse, pasienXResponse, riwayatResponse, listRiwayatResponse, cekPasienResponse, searchResponse,
  userSearchResponse, newSearchResponse, versionResponse, versiV1Response, checkinResponse, checkoutResponse,
  jadwal12Response, jadwalNextResponse, kliniksResponse, kosongResponse, currentPasienResponse, currentCheckResponse,
  fullDayResponse, kosongv2Response, listOralResponse, listOralRekomendasiResponse, listOdonRekomendasiResponse,
  plaqueResponse, calculusResponse, bleedingResponse, mobilityResponse, periodontalResponse, periodontalItemResponse, todayPasienResponse,
  biodataResponse, dataRekapResponse, listPerawatanResponse, listPasienTerlayaniResponse, detailPerawatanResponse,
  pasienBulanResponse, pasienTanggalResponse, chatManajemenResponse, prePerawatanResponse, listDiagnosaResponse, listPrognosaResponse,
  listRekomendasiActionResponse, edukasiResponse, listTindakanResponse, daftarHargaResponse,
} from './api-response';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  API_SERVER_ADDRESS: string = AppSettings.getAPIAddress();
  VERSION_API_SERVER_ADDRESS: string = AppSettings.VERSION_ADDRESS;
  API_KEY: string = AppSettings.API_KEY;
  API_KEY_NAME: string = 'key';

  API_SESSION_ID: string = '';

  constructor(
    private http: HttpClient,
    private ems: ErrorManagementService,
  ) { }

  makeUrl(urlPart: string) {
    let url = this.API_SERVER_ADDRESS + urlPart;
    this.setSession();
    if (this.API_SESSION_ID.length > 0) {
      if (url.indexOf('?') == -1) {
        url += '?sessionid=' + this.API_SESSION_ID;
      } else {
        url += '&sessionid=' + this.API_SESSION_ID;
      }
    }
    return url;
  }

  setSession() {
    var ran = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var idLength = 11;
    for (var i = 0; i < idLength; i++) {
      ran += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    this.API_SESSION_ID = ran;
  }

  apiLogin(loginData: any) {
    const body = new HttpParams()
      .set('username', loginData.username)
      .set('password', loginData.password);

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('key', this.API_KEY)
    }
    if (AppSettings.isAppRegular() || AppSettings.isAppPWA()){
      return this.ems.requestErrorHandler(this.http.post<loginResponse>(this.makeUrl('/dokter-login'), body.toString(), options).pipe(retry(2)), 1)
    } else if (AppSettings.isAppCompact()){
      return this.ems.requestErrorHandler(this.http.post<loginResponse>(this.makeUrl('/dokter-lite-login'), body.toString(), options).pipe(retry(2)), 1)
    }
  }

  apiLogout(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<logoutResponse>(this.makeUrl('/dokter-logout'), options).pipe(retry(2)), 2)
  }

  apiGetDoctor(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<doctorAuthResponse>(this.makeUrl('/get-profile-dokter'), options).pipe(retry(2)), 3)
  }

  apiGetDetailPasien(idpasien: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<pasienResponse>(this.makeUrl('/get-profile-pasien/' + idpasien), options).pipe(retry(2)), 4)
  }

  apiGetCatatan(idpasien: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<listCatatanResponse>(this.makeUrl('/get-list-catatan/' + idpasien), options).pipe(retry(2)), 5)
  }

  apiDeleteCatatan(idpasien: string, idcatatan, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.delete<baseResponse>(this.makeUrl("/delete-catatan/" + idcatatan), options).pipe(retry(2)), 6)
  }

  apiPostCatatan(formData: FormData, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl("/post-catatan/0"), formData, options).pipe(retry(2)), 7)
  }

  apiPostPasien(formData: any, token: string) {
    const body = new HttpParams()
      .set('kode', formData.code)
      .set('nama', formData.nama)
      .set('tempatlahir', formData.tm_lahir)
      .set('tanggallahir', formData.tgl_lahir)
      .set('jeniskelamin', formData.jk)
      .set('agama', formData.agama)
      .set('pekerjaan', formData.pekerjaan)
      .set('alamatlengkap', formData.alamat_rumah)
      .set('handphone', formData.no_hp)
      .set('email', formData.email)
      .set('provinsi', formData.provinsi)
      .set('kota', formData.kota)
      .set('namawali', formData.nama_wali)
      .set('hubunganwali', formData.jenis_wali)
      .set('goldarah', formData.goldar)
      .set('penyakit', formData.penyakit)
      .set('tinggibadan', formData.tinggibadan)
      .set('beratbadan', formData.beratbadan)
      .set('alergi', formData.alergi)
      .set('emergency;', formData.kontakdarurat);

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<postPasienResponse>(this.makeUrl("/insert-data-pasien"), body, options).pipe(retry(2)), 8)
  }

  apiGetOdontogramStatus(token: string){
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl("/get-odontogram-status"), options).pipe(retry(2)), 9)
  }

  apiGetGigiAll(idpasien: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<odontogramResponse>(this.makeUrl("/get-odontogram-pasien/" + idpasien), options).pipe(retry(2)), 9)
  }

  apiDeleteFotoLampiran(idfoto: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl("/delete-foto-lampiran/" + idfoto), options).pipe(retry(2)), 10)
  }

  apiGetFotoLampiran(idpasien: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<lampiranResponse>(this.makeUrl("/get-oral-foto/" + idpasien), options).pipe(retry(2)), 11)
  }

  apiPostFotoLampiran(formData: FormData, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl("/post-foto-oral"), formData, options).pipe(retry(2)), 12)
  }

  apiPostFotoPasien(formData: FormData, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl("/post-foto-pasien"), formData, options).pipe(retry(2)), 13)
  }

  apiPostFotoDokter(formData: FormData, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl("/post-foto-dokter"), formData, options).pipe(retry(2)), 14)
  }

  apiGetOralExam(idpasien: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<oralExamResponse>(this.makeUrl("/get-oral/" + idpasien), options).pipe(retry(2)), 15)
  }

  apiPostOralExam(idpasien: string, formData: any, rekomendasi, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    let body = new HttpParams()
      .set('idpasien', idpasien);

    let i = 1;
    let useJenis = true;
    for (let item of formData) {
      if (i == 1 && item.jenis > i) {
        useJenis = false;
        body = body.set('' + i, item.hasil);
      } else {
        if (useJenis) {
          body = body.set('' + item.jenis, item.hasil);
        } else {
          body = body.set('' + i, item.hasil);
        }
      }
      i++;
    }

    for (let i = 0; i < rekomendasi.length; i++) {
      body = body.set('rekomendasi[' + i + ']', rekomendasi[i]);
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/post-oral'), body, options).pipe(retry(2)), 16)
  }

  apiGetOralTambahan(idpasien: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<oralTambahanResponse>(this.makeUrl("/get-oral-tambahan/" + idpasien), options).pipe(retry(2)), 17)
  }

  apiPostOralTambahan(idpasien: string, formData: any, rekomendasi, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    let body = new HttpParams()
      .set('idpasien', idpasien);

    let i = 1;
    let useJenis = true;
    for (let item of formData) {
      if (i == 1 && item.jenis > i) {
        useJenis = false;
        body = body.set('' + i, item.hasil);
      } else {
        if (useJenis) {
          body = body.set('' + item.jenis, item.hasil);
        } else {
          body = body.set('' + i, item.hasil);
        }
      }
      i++;
    }

    for (let i = 0; i < rekomendasi.length; i++) {
      body = body.set('rekomendasi[' + i + ']', rekomendasi[i]);
    }

    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/post-oral-tambahan'), body, options).pipe(retry(2)), 18)
  }

  apiGetGigi(idpasien: string, nogigi: number, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<singleGigiResponse>(this.makeUrl('/get-gigi/' + idpasien + '/' + nogigi), options).pipe(retry(2)), 19)
  }

  apiGetHistoryGigi(idpasien: string, nogigi: number, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<singleGigiResponse>(this.makeUrl('/get-history-gigi/' + idpasien + '/' + nogigi), options).pipe(retry(2)), 20)
  }

  apiPostStatusGigi(idpasien, idgigi, idstatus, idkondisi, tanggal, waktu, keterangan, permukaan, rekomendasi, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }

    let params = new HttpParams()
      .set('idpasien', idpasien)
      .set('idgigi', idgigi)
      .set('idstatus', idstatus)
      .set('idkondisi', idkondisi)
      .set('tanggal', tanggal)
      .set('waktu', waktu)
      .set('keterangan', keterangan)

    let zona = ['', 'm', 'o', 'd', 'v', 'l'];
    for (let i = 0; i < permukaan.length; i++) {
      for (let j = 0; j < zona.length; j++) {
        if (permukaan[i] == zona[j]) {
          params = params.set('jenispermukaan[' + i + ']', (j + 1).toString());
        }
      }
    }
    for (let i = 0; i < rekomendasi.length; i++) {
      params = params.set('rekomendasi[' + i + '][kode]', rekomendasi[i].kode);
      params = params.set('rekomendasi[' + i + '][masalah]', rekomendasi[i].masalah);
      params = params.set('rekomendasi[' + i + '][harapan]', rekomendasi[i].harapan);
      params = params.set('rekomendasi[' + i + '][resiko]', rekomendasi[i].resiko);
    }

    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl("/input-odontogram"), params, options).pipe(retry(2)), 21);
  }

  apiDeleteHistoryGigi(idpasien: string, idgigi, idodontogram, idkondisi, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    let params = new HttpParams()
      .set('idpasien', idpasien)
      .set('idgigi', idgigi)
      .set('idodontogram', idodontogram)
      .set('idkondisi', idkondisi)
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl("/delete-odontogram/" + idpasien), params, options).pipe(retry(2)), 22);
  }

  apiGetListPasien(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<listPasienResponse>(this.makeUrl("/get-list-pasien"), options).pipe(retry(2)), 23)
  }

  apiCekPasien(code: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<cekPasienResponse>(this.makeUrl("/cek-pasien/" + code), options).pipe(retry(2)), 24)
  }

  apiGetRiwayat(idpasien, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<listRiwayatResponse>(this.makeUrl("/history-all/" + idpasien), options).pipe(retry(2)), 25)
  }

  apiGetRiwayatPage(idpasien, page, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<listRiwayatResponse>(this.makeUrl("/history-all/" + idpasien + "/" + page), options).pipe(retry(2)), 25)
  }

  apiGetCatatanDokter(idpasien: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<listCatatanResponse>(this.makeUrl('/get-list-catatan-dokter/' + idpasien), options).pipe(retry(2)), 26)
  }

  apiDeleteCatatanDokter(idpasien: string, idcatatan, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.delete<baseResponse>(this.makeUrl("/delete-catatan/" + idcatatan), options).pipe(retry(2)), 27)
  }

  apiPostCatatanDokter(formData: FormData, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl("/post-catatan/1"), formData, options).pipe(retry(2)), 28)
  }

  apiSearchPasien(val, col, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    let params = '';
    let first = true;
    for (let i = 0; i < col.length; i++) {
      if (val[i].length > 0) {
        if (!first) {
          params += '&';
        }
        params += col[i] + '=' + val[i];
        first = false;
      }
    }
    return this.ems.requestErrorHandler(this.http.get<newSearchResponse>(this.makeUrl('/search-pasien?' + params), options).pipe(retry(2)), 29)
  }

  apiSearchPasienAlamat(alamat: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<newSearchResponse>(this.makeUrl('/search-pasien-by-alamat/' + alamat), options).pipe(retry(2)), 30)
  }

  apiSearchPasienTL(tl: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<searchResponse>(this.makeUrl('/search-pasien-by-tanggallahir/' + tl), options).pipe(retry(2)), 31)
  }

  apiGantiPass(oldpass: string, newpass: string, token: string) {
    const body = new HttpParams()
      .set('oldpassword', oldpass)
      .set('newpassword', newpass);

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<oralTambahanResponse>(this.makeUrl('/change-password'), body, options).pipe(retry(2)), 32)
  }

  apiVersionCheck() {
    return this.ems.requestErrorHandler(this.http.get<versionResponse>(this.VERSION_API_SERVER_ADDRESS + '/api/app-version/0'), 33)
  }

  apiVersionCheckV1(c_ver: string) {
    return this.ems.requestErrorHandler(this.http.get<versiV1Response>(this.VERSION_API_SERVER_ADDRESS + '/api/app-version-status/' + c_ver + '/0'), 34)
  }

  apiCheckin(link: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<checkinResponse>(link, options).pipe(retry(2)), 35);
  }

  apiCheckout(idpenempatan, idklinik, token: string) {
    let body = new HttpParams()
    .set('idpenempatan', idpenempatan)
    .set('idklinik', idklinik);

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<checkoutResponse>(this.makeUrl('/checkout-dokter'), body.toString(), options).pipe(retry(2)), 36);
  }

  apiJadwal12(idklinik, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<jadwal12Response>(this.makeUrl('/jadwal-12/' + idklinik), options).pipe(retry(2)), 37)
  }

  apiPasienNext(idpasien, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<jadwalNextResponse>(this.makeUrl('/jadwal-next/' + idpasien), options).pipe(retry(2)), 38)
  }

  apiExtendJadwal(idklinik, jam, token: string) {
    const body = new HttpParams()
      .set('idklinik', idklinik)
      .set('jam', jam);

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/jadwal-extend'), body.toString(), options).pipe(retry(2)), 39);
  }

  apiPindahJadwal(idklinik, idpenjadwalan, tanggal, mulai, selesai, newklinik, token: string) {
    const body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan)
      .set('tanggal', tanggal)
      .set('mulai', mulai)
      .set('selesai', selesai)
      .set('newklinik', newklinik);

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/update-jadwal'), body.toString(), options).pipe(retry(2)), 40);
  }

  apiGetKlinikList(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<kliniksResponse>(this.makeUrl('/klinik/list'), options).pipe(retry(2)), 41)
  }

  apiGetJadwalKosong(idklinik, tgl, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<kosongResponse[]>(this.makeUrl('/jadwal/kosong/' + idklinik + '/' + tgl), options).pipe(retry(2)), 42)
  }

  apiGetJadwalKosongV2(idklinik, tgl, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<kosongv2Response>(this.makeUrl('/jadwal-list/' + idklinik + '/' + tgl), options).pipe(retry(2)), 43)
  }

  apiCancelJadwal(idklinik, idpenjadwalan, token: string) {
    const body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan);

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/cancel-jadwal'), body, options).pipe(retry(2)), 44);
  }

  apiCurrentPasien(idklinik, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<currentPasienResponse>(this.makeUrl('/jadwal-current/' + idklinik), options).pipe(retry(2)), 45)
  }

  apiCekPasienCheckin(idklinik, idpasien, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<currentCheckResponse>(this.makeUrl('/jadwal-current-check/' + idklinik + '/' + idpasien), options).pipe(retry(2)), 46)
  }

  apiFullDayJadwal(idklinik, tanggal, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<fullDayResponse>(this.makeUrl('/jadwal-date/' + idklinik + '/' + tanggal), options).pipe(retry(2)), 47)
  }

  apiCreateJadwal(idklinik, idpasien, tanggal, mulai, selesai, token: string) {
    const body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpasien', idpasien)
      .set('tanggal', tanggal)
      .set('mulai', mulai)
      .set('selesai', selesai);

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/create-jadwal'), body, options).pipe(retry(2)), 48);
  }

  apiCheckoutPasien(idjadwal, idklinik, perawatan, token: string, idpasien = '', idperawatan = '') {
    let body = new HttpParams()
      .set('idpenjadwalan', idjadwal)
      .set('idklinik', idklinik)
      .set('perawatan', perawatan)
      .set('idpasien', idpasien)
      .set('idperawatan', idperawatan);

    // if (idpasien.length > 0 && parseInt(idpasien) > 0 && idperawatan.length > 0 && parseInt(idperawatan)){
    //   body = body.set('idpasien', idpasien);
    //   body = body.set('idperawatan', idperawatan);
    // }

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/checkout-pasien'), body, options).pipe(retry(2)), 49);
  }

  apiListOralExam(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<listOralResponse>(this.makeUrl('/oralexam/list'), options).pipe(retry(2)), 50);
  }

  apiListOralRekomendasi(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<listOralRekomendasiResponse>(this.makeUrl('/oralexam/rekomendasi'), options).pipe(retry(2)), 51);
  }

  apiOpsiOralRekomendasi(jenisprecheck, jenishasil, token: string) {
    if (jenisprecheck == null) {
      jenisprecheck = 0;
    }
    if (jenishasil == null) {
      jenishasil = 0;
    }

    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<listOralRekomendasiResponse>(this.makeUrl('/oralexam/rekomendasi/' + jenisprecheck + '/' + jenishasil), options).pipe(retry(2)), 52);
  }

  apiListOdonRekomendasi(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<listOdonRekomendasiResponse>(this.makeUrl('/odontogram/rekomendasi'), options).pipe(retry(2)), 53);
  }

  apiOpsiOdonRekomendasi(idkondisi, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<listOdonRekomendasiResponse>(this.makeUrl('/odontogram/rekomendasi/' + idkondisi), options).pipe(retry(2)), 54);
  }

  apiListPlaque(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<plaqueResponse>(this.makeUrl('/periodontal/get/plaque'), options).pipe(retry(2)), 55);
  }

  apiListCalculus(token: string){
      const options = {
        headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + token)
          .set('key', this.API_KEY)
      }
      return this.ems.requestErrorHandler(this.http.get<calculusResponse>(this.makeUrl('/periodontal/get/calculus'), options).pipe(retry(2)), 55);
  }

  apiListBleeding(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<bleedingResponse>(this.makeUrl('/periodontal/get/bleeding'), options).pipe(retry(2)), 56);
  }

  apiListMobility(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<mobilityResponse>(this.makeUrl('/periodontal/get/mobility'), options).pipe(retry(2)), 57);
  }

  apiPeriodontalGetAll(idpasien, tipe, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<periodontalResponse>(this.makeUrl('/periodontal/get/all/' + tipe + '/' + idpasien), options).pipe(retry(2)), 58);
  }

  apiPeriodontalSingle(idpasien, tipe, nogigi, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<periodontalItemResponse>(this.makeUrl('/periodontal/get/detail/' + nogigi + '/' + tipe + '/' + idpasien), options).pipe(retry(2)), 59);
  }

  apiPeriodontalHistory(idpasien, tipe, nogigi, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<periodontalResponse>(this.makeUrl('/periodontal/get/history/' + nogigi + '/' + tipe + '/' + idpasien), options).pipe(retry(2)), 60);
  }

  apiPeriodontalSave(data, idpasien, nogigi, idpermukaan, token: string) {
    const body = new HttpParams()
      .set('idpasien', idpasien)
      .set('idgigi', nogigi)
      .set('idpermukaan', idpermukaan)
      .set('recess_1', data.recess[0])
      .set('recess_2', data.recess[1])
      .set('recess_3', data.recess[2])
      .set('pocket_1', data.pocket[0])
      .set('pocket_2', data.pocket[1])
      .set('pocket_3', data.pocket[2])
      .set('idbleeding', data.bleeding)
      .set('idmobility', data.mobility)
      .set('idplaque', data.plaque)
      .set('idcalculus', data.calculus);


    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/periodontal/save'), body, options).pipe(retry(2)), 61);
  }

  apiDoLog(idlog: number, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/save-log/' + idlog), options).pipe(retry(2)), 62)
  }

  //Update Januari 2020

  apiJadwalToday(idklinik: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<todayPasienResponse>(this.makeUrl('/jadwal-today/' + idklinik), options).pipe(retry(2)), 63)
  }

  apiBiodataDokter(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<biodataResponse>(this.makeUrl('/get-biodata-dokter'), options).pipe(retry(2)), 64)
  }

  apiRekapPasien(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/get-rekap-pasien'), options).pipe(retry(2)), 65)
  }

  apiListJenisRekap(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<dataRekapResponse>(this.makeUrl('/get-list-jenis-perawatan'), options).pipe(retry(2)), 66)
  }

  apiListPasienTerlayani(awal: string, akhir: string, token: string, sort?: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<listPasienTerlayaniResponse>(this.makeUrl('/get-list-pasien-terlayani?start=' + awal + '&end=' + akhir + (sort != null ? '&sort=' + sort: '')), options).pipe(retry(2)), 67)
  }

  apiListPasienTerlayaniByPage(awal: string, akhir: string, page: number = 1, token: string, sort?: string, search?: string, cabang?: string, status?: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<listPasienTerlayaniResponse>(this.makeUrl('/get-list-pasien-terlayani/'+ page +'?start=' + awal + '&end=' + akhir + (sort != null ? '&sort=' + sort: '') + (search != null ? '&search=' + search: '') + (cabang != null ? '&cabang=' + cabang: '') + (status != null ? '&status=' + status: '')), options).pipe(retry(2)), 67)
  }

  apiDetailPerawatan(iddokter, idperawatan, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<detailPerawatanResponse>(this.makeUrl('/get-detail-perawatan/' + iddokter + '/' + idperawatan), options).pipe(retry(2)), 68)
  }

  apiJumlahPasien30(bulan, tahun, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<pasienBulanResponse>(this.makeUrl('/get-count-jadwal-bulan/' + bulan + '/' + tahun), options).pipe(retry(2)), 69)
  }

  apiListPasienPertanggal(tanggal, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<pasienTanggalResponse>(this.makeUrl('/get-pasien-per-tanggal/' + tanggal), options).pipe(retry(2)), 70)
  }

  apiDetailJadwal(idklinik, idpenjadwalan, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<detailPerawatanResponse>(this.makeUrl('/get-jadwal-detail/' + idklinik + '/' + idpenjadwalan), options).pipe(retry(2)), 71)
  }

  apiChatManajemen(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<chatManajemenResponse>(this.makeUrl('/chat/manajemen/list'), options).pipe(retry(2)), 72)
  }

  apiChatManajemenCount(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/chat/manajemen/count'), options).pipe(retry(2)), 72)
  }

  apiPostChatManajemen(formData, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/chat/manajemen/send'), formData, options).pipe(retry(2)), 73);
  }

  apiPostCatatanDokterV2(formData: FormData, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/save-chat-per-pasien'), formData, options).pipe(retry(2)), 74);
  }

  apiDeleteCatatanDokterV2(idpasien: string, idcatatan, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/delete-chat-per-pasien/' + idpasien + '/' + idcatatan), options).pipe(retry(2)), 75);
  }

  apiCatatanNotReaded(idpasien: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/count-chat-per-pasien/' + idpasien), options).pipe(retry(2)), 76);
  }

  apiRekomendasiPasien(idpasien: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/rekomendasi/all/' + idpasien), options).pipe(retry(2)), 77);
  }

  apiPreviewPerawatan(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<prePerawatanResponse>(this.makeUrl('/action/preview'), options).pipe(retry(2)), 78);
  }

  apiSavedObservasi(idklinik, idpenjadwalan, token: string){
    const options = {
        headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + token)
          .set('key', this.API_KEY)
    }

    let body = '?' + new HttpParams()
      .set('id1', idklinik)
      .set('id2', idpenjadwalan)
      .toString();

    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/action/saved-observasi' + body), options).pipe(retry(2)), 79);
  }

  apiPostObservasi(data, token: string){
    let body = new HttpParams()
      .set('idklinik', data.idklinik)
      .set('idpenjadwalan', data.idpenjadwalan)
      .set('idobservation', data.idobservation)
      .set('value', data.value)
      .set('keterangan', data.keterangan);

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/action/post-observasi'), body, options).pipe(retry(2)), 79);
  }

  apiDeleteObservasi(data, token: string){
    let body = new HttpParams()
      .set('idklinik', data.idklinik)
      .set('idpenjadwalan', data.idpenjadwalan)
      .set('idobservation', data.idobservation);

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/action/delete-observasi'), body, options).pipe(retry(2)), 79);
  }

  apiListObservasi(token: string, q: string = '', page?: number) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    let temp = '?page=';
    if (q.length > 0){
      q = '?q=' + q;
      temp = '&page=';
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/action/list-observasi' + q + ((page != null) ? temp + page : '')), options).pipe(retry(2)), 79);
  }

  apiListDiagnosa(idstatusgigi: string, token: string, q: string = '', page?: number) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    let temp = '?page=';
    if (q.length > 0){
      q = '?q=' + q;
      temp = '&page=';
    }
    return this.ems.requestErrorHandler(this.http.get<listDiagnosaResponse>(this.makeUrl('/action/list-diagnosa/' + idstatusgigi + q + ((page != null) ? temp + page : '')), options).pipe(retry(2)), 79);
  }

  apiListPrognosa(idstatusgigi: string, token: string, q: string = '', page?: number) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    let temp = '?page=';
    if (q.length > 0){
      q = '?q=' + q;
      temp = '&page=';
    }
    return this.ems.requestErrorHandler(this.http.get<listPrognosaResponse>(this.makeUrl('/action/list-prognosa/' + idstatusgigi + q + ((page != null) ? temp + page : '')), options).pipe(retry(2)), 79);
  }

  apiListPerawatan(idstatusgigi: string, token: string, q: string = '', page?: number) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    let temp = '?page=';
    if (q.length > 0){
      q = '?q=' + q;
      temp = '&page=';
    }
    return this.ems.requestErrorHandler(this.http.get<listRekomendasiActionResponse>(this.makeUrl('/action/list-rekomendasi/' + idstatusgigi + q + ((page != null) ? temp + page : '')), options).pipe(retry(2)), 79);
  }

  apiListPerawatanICD(idrekomendasis: string, token: string, q: string = '', page?: number) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    let temp = '?page=';
    if (q.length > 0){
      q = '?q=' + q;
      temp = '&page=';
    }
    if (idrekomendasis.length > 0){
      if (q.length == 0 && (page == null || page == 0)){
        idrekomendasis = '?ids=' + idrekomendasis;
      } else {
        idrekomendasis = '&ids=' + idrekomendasis;
      }
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/action/list-icd/' + q + ((page != null) ? temp + page : '')) + idrekomendasis, options).pipe(retry(2)), 79);
  }

  apiEdukasiPerawatan(idrekomendasiperawatan: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<edukasiResponse>(this.makeUrl('/action/list-rekomendasi/edukasi/' + idrekomendasiperawatan), options).pipe(retry(2)), 80);
  }

  apiListTindakan(idstatusgigi: string, idrekomendasi: string, token: string, q:string = '', page?: number) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    let temp = '?page=';
    if (q.length > 0){
      q = '?q=' + q;
      temp = '&page=';
    }
    return this.ems.requestErrorHandler(this.http.get<listTindakanResponse>(this.makeUrl('/action/list-tindakan/' + idstatusgigi + '/' + idrekomendasi + q + ((page != null) ? temp + page : '')), options).pipe(retry(2)), 81);
  }

  apiTermNAgreement(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/action/tna'), options).pipe(retry(2)), 82);
  }

  apiLastIdPerawatan(idpasien: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/action/lastid/' + idpasien), options).pipe(retry(2)), 83);
  }

  apiPerawatanFirst(perawatan_data: any, token: string, imgBlob = null, filename = null) {
    let body = new FormData();
    body.append('idpasien', perawatan_data.idpasien)
    body.append('idperawatan', perawatan_data.idperawatan)
    body.append('anamnesa', perawatan_data.anamnesa);

    if (imgBlob != null && filename != null){
      body.append('ttd[]', imgBlob, filename);

      for (let i = 0; i < perawatan_data.klinis.length; i++) {
        body.append('klinis[' + i + ']', perawatan_data.klinis[i])
        for (let j = 0; j < perawatan_data.diagnosa[i].length; j++) {
          body.append('diagnosa[' + i + '][' + j + ']', perawatan_data.diagnosa[i][j])
        }
      }

      const options = {
        headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + token)
          .set('key', this.API_KEY)
      }
      return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/action/save-first'), body, options).pipe(retry(2)), 84);

    } else {
      for (let i = 0; i < perawatan_data.klinis.length; i++) {
        body.append('klinis[' + i + ']', perawatan_data.klinis[i])
        for (let j = 0; j < perawatan_data.diagnosa[i].length; j++) {
          body.append('diagnosa[' + i + '][' + j + ']', perawatan_data.diagnosa[i][j])
        }
      }
      const options = {
        headers: new HttpHeaders()
          .set('Authorization', 'Bearer ' + token)
          .set('key', this.API_KEY)
      }
      return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/action/save-first'), body, options).pipe(retry(2)), 84);
    }
  }

  apiPerawatanSecond(perawatan_data: any, token: string) {
    var body = new HttpParams()
      .set('idpasien', perawatan_data.idpasien)
      .set('idperawatan', perawatan_data.idperawatan)
      .set('klinis', perawatan_data.klinis)
      .set('idstatus', perawatan_data.idstatus)
      .set('idrekomendasi', perawatan_data.idrekomendasi)
      .set('rekomendasi', perawatan_data.rekomendasi)
      .set('idtindakan', perawatan_data.idtindakan)
      .set('tindakan', perawatan_data.tindakan)
      .set('harga', perawatan_data.harga)

    if (perawatan_data.idklinik != null && perawatan_data.idpenjadwalan != null){
      body = body
            .set('idklinik', perawatan_data.idklinik)
            .set('idpenjadwalan', perawatan_data.idpenjadwalan);
    }

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/action/save-second'), body, options).pipe(retry(2)), 85);
  }

  apiAutoSaveOdontogram(datagigi, token: string) {
    let body = new HttpParams()
      .set('idpasien', datagigi.idpasien);

    for (let i = 0; i < datagigi.idgigi.length; i++) {
      body = body.set('idgigi[' + i + ']', datagigi.idgigi[0])
      body = body.set('idstatus[' + i + ']', datagigi.idstatus[0]);
    }

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/action/auto-save'), body, options).pipe(retry(2)), 86);
  }

  apiSaveNotes(data, token: string, imgBlob = null, filename = null) {
    let body = new FormData();
    body.append('idpasien', data.idpasien);
    body.append('idperawatan', data.idperawatan);
    body.append('idklinik', data.idklinik);
    body.append('idpenjadwalan', data.idpenjadwalan);
    body.append('notes', data.notes);

    if (imgBlob != null && filename != null){
      body.append('ttd', imgBlob, filename);
    }

    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/action/save-notes'), body, options).pipe(retry(2)), 87);
  }

  apiGetNotesPusat(idpasien: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/pasien/notes-pusat/' + idpasien), options).pipe(retry(2)), 88);
  }

  apiRekapPasienNew(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/get-rekap-pasien/new'), options).pipe(retry(2)), 89)
  }

  apiListPasienTerlayaniNew(awal: string, akhir: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<listPasienTerlayaniResponse>(this.makeUrl('/get-list-pasien-terlayani/new?start=' + awal + '&end=' + akhir), options).pipe(retry(2)), 90)
  }

  apiDetailPerawatanNew(iddokter, idpenjadwalan, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<detailPerawatanResponse>(this.makeUrl('/get-detail-perawatan/new/' + iddokter + '/' + idpenjadwalan), options).pipe(retry(2)), 91)
  }

  apiGetDaftarHarga(token: string, search?) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<daftarHargaResponse>(this.makeUrl('/daftar-harga' + (search != null ? search : '')), options).pipe(retry(2)), 92)
  }

  apiGetListJadwalKonsultasi(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/consult/list'), options).pipe(retry(2)), 93)
  }

  apiGetListJadwalKonsultasiNext(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/consult/next'), options).pipe(retry(2)), 94)
  }

  apiPostBatalKonsultasi(idklinik, idpenjadwalan, alasan, token: string) {
    let body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan)
      .set('alasan', alasan)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/consult/cancel'), body, options).pipe(retry(2)), 95);
  }

  apiWaktuKonsul(idklinik, idpenjadwalan, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/consult/time-left/' + idklinik + '/' + idpenjadwalan), options).pipe(retry(2)), 96)
  }

  apiStartKonsultasi(idklinik, idpenjadwalan, token: string) {
    let body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/consult/start'), body, options).pipe(retry(2)), 97);
  }

  apiWaktuPerawatan(idklinik, idpenjadwalan, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/jadwal/time-left/' + idklinik + '/' + idpenjadwalan), options).pipe(retry(2)), 98)
  }

  apiListEdukasi(token: string){
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/edukasi'), options).pipe(retry(2)), 99)
  }

  apiListAsuransi(token: string){
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/asuransi'), options).pipe(retry(2)), 99)
  }

  apiListVoucher(idpasien, token: string){
    let body = '?' + new HttpParams()
      .set('idpasien', idpasien)
    .toString();

    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/promo-voucher' + body), options).pipe(retry(2)), 99)
  }

  apiPreviewJadwal(idklinik, idpenjadwalan, token: string){
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/jadwal-preview/' + idklinik + '/' + idpenjadwalan), options).pipe(retry(2)), 100)
  }

  apiGetInformConsent(token: string){
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/action/inform-consent'), options).pipe(retry(2)), 101)
  }

  apiHapusGigiSusu(idpasien, token: string) {
    let body = new HttpParams()
      .set('idpasien',idpasien)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/hapus-gigi-susu'), body, options).pipe(retry(2)), 102);
  }

  apiCheckinPasien(idklinik, idpenjadwalan, token: string) {
    let body = new HttpParams()
      .set('idklinik',idklinik)
      .set('idpenjadwalan', idpenjadwalan)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/checkin-pasien'), body, options).pipe(retry(2)), 103);
  }

  apiInformasiCheckout(idklinik, idpenjadwalan, token: string){
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/info-checkout/' + idklinik + '/' + idpenjadwalan), options).pipe(retry(2)), 104)
  }

  apiVoucherAvailability(kodevoucher, token: string){
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/cek-voucher/' + kodevoucher), options).pipe(retry(2)), 105)
  }

  apiTotalPoin(phone, token: string){
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/total-poin/' + phone), options).pipe(retry(2)), 106)
  }

  apiDoPayment(idklinik, idpenjadwalan, nominal, keterangantambahan, hargatambahan, usevoucher, kodevoucher, usepoin, pointelp, pembayar, metodepembayaran, token: string) {
    let body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan)
      .set('nominal', nominal)
      .set('keterangantambahan', keterangantambahan)
      .set('hargatambahan', hargatambahan)
      .set('usevoucher', usevoucher)
      .set('kodevoucher', kodevoucher)
      .set('usepoin', usepoin)
      .set('pointelp', pointelp)
      .set('pembayar', pembayar)
      .set('metodepembayaran', metodepembayaran)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/do-payment'), body, options).pipe(retry(2)), 107);
  }

  apiCheckinByKode(kode: string, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<checkinResponse>(this.makeUrl('/checkin-dokter-by-kode/' + kode), options).pipe(retry(2)), 108);
  }

  apiGetPKOdontogramConfig(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<checkinResponse>(this.makeUrl('/action/pk-config'), options).pipe(retry(2)), 109);
  }

  apiDashboardSEPasien(startDate, endDate, search, status, page, token: string) {
    let body = '?' + new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('page', page)
      .set('search', search)
      .set('status', status)
    .toString();

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/pasien' + body), options).pipe(retry(2)), 110);
  }

  apiDashboardSEInti(startDate, endDate, search, page, token: string, search_base?, status?) {
    if (search_base == null){
      search_base = '';
    }
    let body = '?' + new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('page', page)
      .set('search', search)
      .set('status', status,)
      .set('search_base', search_base)
    .toString();

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/inti' + body), options).pipe(retry(2)), 111);
  }

  apiDashboardSESE(startDate, endDate, search, page, token: string, search_base?, status?) {
    if (search_base == null){
      search_base = '';
    }
    let body = '?' + new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('page', page)
      .set('search', search)
      .set('status', status)
      .set('search_base', search_base)
    .toString();

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/se' + body), options).pipe(retry(2)), 112);
  }

  apiDashboardSETotalPotensi(startDate, endDate, token: string, search_base?, status?) {
    if (search_base == null){
      search_base = '';
    }
    let body = '?' + new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('search_base', search_base)
      .set('status', status)
    .toString();

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/total-potensi' + body), options).pipe(retry(2)), 113);
  }

  apiDashboardSERating(startDate, endDate, search, page, token: string) {
    let body = '?' + new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('page', page)
      .set('search', search)
    .toString();

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/rating' + body), options).pipe(retry(2)), 114);
  }

  apiDashboardSEIntiPenjadwalan(idklinik, idpenjadwalan, search, page, token: string) {
    let body = '?' + new HttpParams()
      .set('page', page)
      .set('search', search)
    .toString();

    let ext = '/' + idklinik + '/' + idpenjadwalan;

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/inti' + ext + body), options).pipe(retry(2)), 115);
  }

  apiDashboardSESEPenjadwalan(idklinik, idpenjadwalan, search, page, token: string) {
    let body = '?' + new HttpParams()
      .set('page', page)
      .set('search', search)
    .toString();

    let ext = '/' + idklinik + '/' + idpenjadwalan;

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/se' + ext + body), options).pipe(retry(2)), 116);
  }

  apiDashboardSETotalPotensiPenjadwalan(idklinik, idpenjadwalan, token: string) {
    let ext = '/' + idklinik + '/' + idpenjadwalan;

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/total-potensi' + ext), options).pipe(retry(2)), 117);
  }

  apiDashboardSETindakan(page, search, token: string) {
    let body = '?' + new HttpParams()
      .set('page', page)
      .set('search', search)
    .toString();

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/list/tindakan' + body), options).pipe(retry(2)), 118);
  }

  apiDashboardSERekomendasi(idklinik, idpenjadwalan, token: string) {
    let body = '?' + new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan)
    .toString();

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/list/rekomendasi' + body), options).pipe(retry(2)), 119);
  }

  apiDashboardSEStoreInti(idklinik, idpenjadwalan, tindakan, nama, jumlah, harga, rekomendasi, catatan, todo, berhasil, inforekomendasi, idgigi, token: string) {
    let body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan)
      .set('tindakan', tindakan)
      .set('rencana', nama)
      .set('jumlah', jumlah)
      .set('harga', harga)
      .set('rekomendasi', rekomendasi)
      .set('catatan', catatan)
      .set('todo', todo)
      .set('berhasil', berhasil)
      .set('inforekomendasi', inforekomendasi)
      .set('idgigi', idgigi)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/dashboard-se/store/inti'), body, options).pipe(retry(2)), 120);
  }

  apiDashboardSEStoreSE(idklinik, idpenjadwalan, tindakan, nama, jumlah, harga, rekomendasi, catatan, todo, berhasil, inforekomendasi, idgigi, token: string) {
    let body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan)
      .set('tindakan', tindakan)
      .set('rencana', nama)
      .set('jumlah', jumlah)
      .set('harga', harga)
      .set('rekomendasi', rekomendasi)
      .set('catatan', catatan)
      .set('todo', todo)
      .set('berhasil', berhasil)
      .set('inforekomendasi', inforekomendasi)
      .set('idgigi', idgigi)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/dashboard-se/store/se'), body, options).pipe(retry(2)), 121);
  }

  apiDashboardSEUpdateInti(idklinik, idpenjadwalan, idrencana, tindakan, nama, jumlah, harga, rekomendasi, catatan, todo, berhasil, inforekomendasi, idgigi, token: string) {
    let body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan)
      .set('idrencana', idrencana)
      .set('tindakan', tindakan)
      .set('rencana', nama)
      .set('jumlah', jumlah)
      .set('harga', harga)
      .set('rekomendasi', rekomendasi)
      .set('catatan', catatan)
      .set('todo', todo)
      .set('berhasil', berhasil)
      .set('inforekomendasi', inforekomendasi)
      .set('idgigi', idgigi)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/dashboard-se/update/inti'), body, options).pipe(retry(2)), 122);
  }

  apiDashboardSEUpdateSE(idklinik, idpenjadwalan, idrencana, tindakan, nama, jumlah, harga, rekomendasi, catatan, todo, berhasil, inforekomendasi, idgigi, token: string) {
    let body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan)
      .set('idrencana', idrencana)
      .set('tindakan', tindakan)
      .set('rencana', nama)
      .set('jumlah', jumlah)
      .set('harga', harga)
      .set('rekomendasi', rekomendasi)
      .set('catatan', catatan)
      .set('todo', todo)
      .set('berhasil', berhasil)
      .set('inforekomendasi', inforekomendasi)
      .set('idgigi', idgigi)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/dashboard-se/update/se'), body, options).pipe(retry(2)), 123);
  }

  apiDashboardSEIntiSingle(idklinik, idpenjadwalan, idrencana, token: string) {
    let ext = '/' + idklinik + '/' + idpenjadwalan + '/' + idrencana;

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/inti' + ext), options).pipe(retry(2)), 124);
  }

  apiDashboardSESESingle(idklinik, idpenjadwalan, idrencana, token: string) {
    let ext = '/' + idklinik + '/' + idpenjadwalan + '/' + idrencana;

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/se' + ext), options).pipe(retry(2)), 125);
  }

  apiDashboardSEDeleteInti(idklinik, idpenjadwalan, idrencana, token: string) {
    let body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan)
      .set('idrencana', idrencana)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/dashboard-se/delete/inti'), body, options).pipe(retry(2)), 126);
  }

  apiDashboardSEDeleteSE(idklinik, idpenjadwalan, idrencana, token: string) {
    let body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan)
      .set('idrencana', idrencana)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/dashboard-se/delete/se'), body, options).pipe(retry(2)), 127);
  }

  apiDashboardSEKeluhan(idklinik, idpenjadwalan, token: string) {
    let ext = '/' + idklinik + '/' + idpenjadwalan;

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/keluhan' + ext), options).pipe(retry(2)), 128);
  }

  apiDashboardSERefInti(idklinik, idpenjadwalan, search, page, token: string) {
    let body = '?' + new HttpParams()
      .set('page', page)
      .set('search', search)
    .toString();

    let ext = '/' + idklinik + '/' + idpenjadwalan;

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/ref/inti' + ext + body), options).pipe(retry(2)), 129);
  }

  apiDashboardSERefSE(idklinik, idpenjadwalan, search, page, token: string) {
    let body = '?' + new HttpParams()
      .set('page', page)
      .set('search', search)
    .toString();

    let ext = '/' + idklinik + '/' + idpenjadwalan;

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/ref/se' + ext + body), options).pipe(retry(2)), 130);
  }

  apiDashboardSEImportInti(idklinik, idpenjadwalan, refidklinik, refidpenjadwalan, refidrencana, berhasil, token: string) {
    let body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan)
      .set('refidklinik', refidklinik)
      .set('refidpenjadwalan', refidpenjadwalan)
      .set('refidrencana', refidrencana)
      .set('berhasil', berhasil)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/dashboard-se/import/inti'), body, options).pipe(retry(2)), 131);
  }

  apiDashboardSEImportSE(idklinik, idpenjadwalan, refidklinik, refidpenjadwalan, refidrencana, berhasil, token: string) {
    let body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan)
      .set('refidklinik', refidklinik)
      .set('refidpenjadwalan', refidpenjadwalan)
      .set('refidrencana', refidrencana)
      .set('berhasil', berhasil)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/dashboard-se/import/se'), body, options).pipe(retry(2)), 132);
  }

  apiDashboardSEImportAllInti(idklinik, idpenjadwalan, token: string) {
    let body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan);

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/dashboard-se/import/all/inti'), body, options).pipe(retry(2)), 131);
  }

  apiDashboardSEImportAllSE(idklinik, idpenjadwalan, token: string) {
    let body = new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan);

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/dashboard-se/import/all/se'), body, options).pipe(retry(2)), 132);
  }

  apiDashboardSESearchPasien(nama, tanggal_lahir, kode, status_gigi, rekomendasi_odontogram, rekomendasi_precheck, page, token: string) {
    let body = '?' + new HttpParams()
      .set('nama', nama)
      .set('tanggal_lahir', tanggal_lahir)
      .set('kode', kode)
      .set('status_gigi', status_gigi)
      .set('rekomendasi_odontogram', rekomendasi_odontogram)
      .set('rekomendasi_precheck', rekomendasi_precheck)
      .set('page', page)
    .toString();

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/search-pasien' + body), options).pipe(retry(2)), 133);
  }

  apiDashboardSESearchStatus(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/search/status'), options).pipe(retry(2)), 134);
  }

  apiDashboardSESearchRekomendasi(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/search/rekomendasi'), options).pipe(retry(2)), 135);
  }

  apiDashboardSECalonPasien(search, page, token: string) {
    let body = '?' + new HttpParams()
      .set('page', page)
      .set('search', search)
    .toString();

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/calon-pasien' + body), options).pipe(retry(2)), 136);
  }

  apiDashboardSEStoreCalon(idpasien, token: string) {
    let body = new HttpParams()
      .set('idpasien', idpasien)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/dashboard-se/store/calon'), body, options).pipe(retry(2)), 137);
  }

  apiDashboardSERemoveCalon(idcalon, token: string) {
    let body = new HttpParams()
      .set('idcalon', idcalon)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/dashboard-se/remove/calon'), body, options).pipe(retry(2)), 138);
  }

  apiLabelGetList(token: string){
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/label/list'), options).pipe(retry(2)), 139);
  }

  apiLabelAttach(idpasien, idlabel, token: string){
    let body = new HttpParams()
      .set('idpasien', idpasien)
      .set('id', idlabel)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/label/attach'), body, options).pipe(retry(2)), 140);
  }

  apiPerawatanSaveTemp(idklinik, idpenjadwalan, data, ttdBlob = null, consent = null, is_posted = null, post_error_encounter = null, token: string) {
    let body = new FormData();
    body.append('idklinik', idklinik);
    body.append('idpenjadwalan', idpenjadwalan);
    body.append('data', JSON.stringify(data));
    body.append('is_posted', is_posted);
    body.append('post_error_encounter', post_error_encounter);

    if (ttdBlob != null){
      body.append('ttd', ttdBlob);
      body.append('consent', consent);
    }

    // let body = new HttpParams()
    //   .set('idklinik', idklinik)
    //   .set('idpenjadwalan', idpenjadwalan)
    //   .set('data', JSON.stringify(data))
    //   .set('is_posted', is_posted)
    //   .set('post_error_encounter', post_error_encounter);

    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/action/temp-save'), body, options).pipe(retry(2)), 141);
  }

  apiPerawatanSaveAll(idklinik, idpenjadwalan, data, ttdBlob, filename = null, token: string) {
    let body = new FormData();
    body.append('idklinik', idklinik);
    body.append('idpenjadwalan', idpenjadwalan);
    if (ttdBlob != null){
      body.append('ttd', ttdBlob);
    }
    body.append('data', JSON.stringify(data));

    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/action/save-all'), body, options).pipe(retry(2)), 142);
  }

  apiPerawatanGetTemp(idklinik, idpenjadwalan, token: string) {
    let body = '?' + new HttpParams()
      .set('idklinik', idklinik)
      .set('idpenjadwalan', idpenjadwalan)
    .toString();

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/action/temp-get' + body), options).pipe(retry(2)), 143);
  }

  apiReportGet(token: string){
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/report/get'), options).pipe(retry(2)), 144);
  }

  apiReportGetTotal(token: string){
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/report/get-total'), options).pipe(retry(2)), 145);
  }

  apiReportResolve(idreport, response, token: string) {
    let body = new HttpParams()
      .set('idreport', idreport)
      .set('response', response);

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/report/resolve'), body, options).pipe(retry(2)), 146);
  }

  apiGetDokterID(token: string){
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/identify'), options).pipe(retry(2)), 147);
  }

  apiDokterIDTryValidate(token: string){
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/identify/try-validate'), options).pipe(retry(2)), 148);
  }

  apiSetDokterID(tipe_id, nomor_id, nama_id, alamat_id, tanggallahir_id, tempatlahir_id, token: string){
    let body = new HttpParams()
      .set('nomor_id', nomor_id)
      .set('tipe_id', tipe_id)
      .set('nama_id', nama_id)
      .set('alamat_id', alamat_id)
      .set('tanggallahir_id', tanggallahir_id)
      .set('tempatlahir_id', tempatlahir_id)

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.post<baseResponse>(this.makeUrl('/identify/set'), body, options).pipe(retry(2)), 149);
  }

  apiDashboardSEIntiCatatan(idklinik, idpenjadwalan, idrencana, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/catatan/inti/' + idklinik + '/' + idpenjadwalan + '/' + idrencana), options).pipe(retry(2)), 150);
  }

  apiDashboardSESECatatan(idklinik, idpenjadwalan, idrencana, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/dashboard-se/catatan/se/' + idklinik + '/' + idpenjadwalan + '/' + idrencana), options).pipe(retry(2)), 151);
  }

  apiGetResumeToken(token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/resume/token'), options).pipe(retry(2)), 152);
  }

  apiGetRiwayatResume(idpasien, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl("/resume/list/" + idpasien), options).pipe(retry(2)), 153);
  }

  apiRoadmapListPasien(startDate, endDate, search, status, page, token: string) {
    let body = '?' + new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('page', page)
      .set('search', search)
      .set('status', status)
    .toString();

    const options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/roadmap/list' + body), options).pipe(retry(2)), 154);
  }

  apiRoadmapGetPasien(idpasien, token: string) {
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + token)
        .set('key', this.API_KEY)
    }
    return this.ems.requestErrorHandler(this.http.get<baseResponse>(this.makeUrl('/roadmap/pasien/' + idpasien), options).pipe(retry(2)), 155);
  }
}
