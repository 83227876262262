import { Component, OnInit, ViewChild  } from '@angular/core';
import { Storage } from '@ionic/storage';
import { File, IWriteOptions, FileEntry } from '@ionic-native/file/ngx';
import { Platform, ModalController, LoadingController, NavController } from '@ionic/angular';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { fromEvent } from 'rxjs';
import { map, tap, switchMap, takeUntil, finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';
import { AppSettings } from 'src/app/settings/settings';
import { PopupcheckoutPage } from "../popupcheckout/popupcheckout.page";

const TTD_FINAL = "KLINIKOO_TTD_FINAL";

interface point{
  x: number,
  y: number,
}

interface path{
  initialPoint : point,
  nextPoint: point[],
  endPoint: point,
  color: string,
}

@Component({
  selector: 'app-popupttdfinal',
  templateUrl: './popupttdfinal.page.html',
  styleUrls: ['./popupttdfinal.page.scss'],
})
export class PopupttdfinalPage implements OnInit {

  colorPallete = [
    '#000000',
  ];
  storedImages = [];
  canvasElement: any;
  activeColor = '#000000';
  activeX = 0;
  activeY = 0;
  strokeIndex = 0;
  trackPath : path[];
  tmpPath : path;

  keyToUse = 'klinikoo_ttd_final';

  isSaveDone: boolean = false;
  ttd: any = null;

  imgBlob = null;

  @ViewChild('ttdCanvas') canvas: any;

  constructor(
    public auth: AuthService,
    private file: File,
    private storage: Storage,
    private plt: Platform,
    private webview: WebView,
    private sanitizer: DomSanitizer,
    private mc: ModalController,
    private lc: LoadingController,
    private api: ApiService,
    private nav: NavController,
  ) { }

  ngOnInit() {
    this.trackPath = [];
    this.tmpPath = {
      initialPoint : {x : 0, y: 0},
      nextPoint: [],
      endPoint: {x : 0, y: 0},
      color: "#000000",
    };

    this.storage.remove(TTD_FINAL);
  }

  ionViewDidEnter(){
    this.canvasElement = this.canvas.nativeElement;
    this.canvasElement.width = this.canvas.nativeElement.width;
    this.canvasElement.height = this.canvas.nativeElement.height;

    if (this.canvasElement != null){
      const mouseDownStream = fromEvent(this.canvas.nativeElement, 'mousedown');
      const mouseMoveStream = fromEvent(this.canvas.nativeElement, 'mousemove');
      const mouseUpStream = fromEvent(window, 'mouseup');

      let context = this.canvasElement.getContext('2d');
      mouseDownStream.pipe(
        tap((event: MouseEvent) => {
          context.beginPath();
          context.strokeStyle = this.activeColor;
          context.lineWidth = 3;
          context.lineJoin = 'round';
          context.moveTo(event.offsetX, event.offsetY);

          this.activeX = event.offsetX;
          this.activeY = event.offsetY;

          this.tmpPath.initialPoint.x = this.activeX;
          this.tmpPath.initialPoint.y = this.activeY;
          this.tmpPath.color = this.activeColor;
        }),
        switchMap(() => mouseMoveStream.pipe(
          tap((event: MouseEvent) => {
            context.lineTo(event.offsetX, event.offsetY);
            context.stroke();
            this.activeX = event.offsetX;
            this.activeY = event.offsetY;

            this.tmpPath.nextPoint.push({
              x : this.activeX,
              y : this.activeY,
            });
          }),
          takeUntil(mouseUpStream),
          finalize(() => {
            context.closePath();
            this.createStack(null);
          })
        ))
      ).subscribe();
    }
  }

  startDrawing(ev){
    var canvasPosition = (this.canvasElement != null) ? this.canvasElement.getBoundingClientRect() : {x: 0, y: 0};
    if (ev.touches != null){
      this.activeX = ev.touches[0].pageX - canvasPosition.x;
      this.activeY = ev.touches[0].pageY - canvasPosition.y;
    } else {
      this.activeX = ev.offsetX;
      this.activeY = ev.offsetY;
    }

    this.tmpPath.initialPoint.x = this.activeX;
    this.tmpPath.initialPoint.y = this.activeY;
    this.tmpPath.color = this.activeColor;
  }

  move(ev){
    var canvasPosition = (this.canvasElement != null) ? this.canvasElement.getBoundingClientRect() : {x: 0, y: 0};
    let context = this.canvasElement.getContext('2d');
    let currentX = 0;
    let currentY = 0;
    if (ev.touches != null){
      currentX = ev.touches[0].pageX - canvasPosition.x;
      currentY = ev.touches[0].pageY - canvasPosition.y;
    } else {
      currentX = ev.offsetX;
      currentY = ev.offsetY;
    }
    context.lineJoin = 'round';
    context.strokeStyle = this.activeColor;
    context.lineWidth = 3;

    context.beginPath();
    context.moveTo(this.activeX, this.activeY);
    context.lineTo(currentX, currentY);
    context.closePath();
    context.stroke();
    this.activeX = currentX;
    this.activeY = currentY;

    this.tmpPath.nextPoint.push({
      x : this.activeX,
      y : this.activeY,
    });
  }

  createStack(ev){
    let currentX = this.tmpPath.nextPoint[this.tmpPath.nextPoint.length - 1].x;
    let currentY = this.tmpPath.nextPoint[this.tmpPath.nextPoint.length - 1].y;
    this.tmpPath.endPoint.x = currentX;
    this.tmpPath.endPoint.y = currentY;
    this.strokeIndex += 1;
    this.trackPath.push(this.tmpPath);
    this.tmpPath = {
      initialPoint : {x : 0, y: 0},
      nextPoint: [],
      endPoint: {x : 0, y: 0},
      color: "#000000",
    };
    //let context = this.canvasElement.getContext('2d');
  }

  drawStroke(path: path){
    if (this.activeColor != path.color){
      this.activeColor = path.color;
    }

    let last : point = {
      x : path.initialPoint.x,
      y : path.initialPoint.y,
    }

    let context = this.canvasElement.getContext('2d');
    context.lineJoin = 'round';
    context.strokeStyle = this.activeColor;
    context.lineWidth = 3;

    for (let i = 0; i < path.nextPoint.length; i++){
      let item = path.nextPoint[i];
      context.beginPath();
      if (i == path.nextPoint.length - 1){
        context.moveTo(last.x, last.y);
        context.lineTo(path.endPoint.x, path.endPoint.y);
      } else {
        context.moveTo(last.x, last.y);
        context.lineTo(item.x, item.y);
        last.x = item.x;
        last.y = item.y;
      }
      context.stroke();
      context.closePath();
    }
  }

  canvasUndo(){
    //console.log(this.trackPath.length);
    let context = this.canvasElement.getContext('2d');
    context.clearRect(0,0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.trackPath.splice(this.trackPath.length - 1, 1);
    for (let item of this.trackPath){
      this.drawStroke(item);
    }
  }

  canvasClear(){
    //var canvasPosition = this.canvasElement.getBoundingClientRect();
    let context = this.canvasElement.getContext('2d');
    context.clearRect(0,0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.trackPath = [];

    this.ttd = null;
    this.storage.remove(TTD_FINAL);
  }

  canvasImage(){
    // try{
    //   var dataUrl = this.canvasElement.toDataURL();
    //   let context = this.canvasElement.getContext('2d');
    //   context.clearRect(0,0, context.canvas.width, context.canvas.height);
    //   let name = new Date().getTime() + '.png';
    //   let path = this.file.dataDirectory;
    //   let options : IWriteOptions = { replace: true, }
    //
    //   var data = dataUrl.split(',')[1];
    //   let blob = this.b64toBlob(data, 'image/png');
    //
    //   this.file.writeFile(path, name, blob, options).then(res => {
    //     this.storeImage(name);
    //     //post
    //     this.saveNotes();
    //     //this.mc.dismiss();
    //   }, err => {
    //     if (AppSettings.isAppPWA()){
    //         this.saveNotes();
    //     }
    //     console.log('error: ', err);
    //   });
    // } catch(err) {
    //   this.saveNotes();
    // }

    var dataUrl = this.canvasElement.toDataURL();
    let context = this.canvasElement.getContext('2d');
    context.clearRect(0,0, context.canvas.width, context.canvas.height);

    var data = dataUrl.split(',')[1];
    let blob = this.b64toBlob(data, 'image/png');

    this.storeImage(blob);
    this.mc.dismiss();
  }

  b64toBlob(b64Data, contentType){
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize){
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++){
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  storeImage(imgBlob){
    // let filePath = this.file.dataDirectory + imageName;
    // let resPath = this.pathForImage(filePath);
    // let saveObj = { name: imageName, path: resPath, filePath: filePath };

    // this.storedImages.push(saveObj);
    // console.log(saveObj);
    // this.ttd = saveObj;
    // this.auth.extra_perawatan_data.ttd = saveObj;
    // this.storage.set(TTD_FINAL, this.storedImages);

    this.imgBlob = imgBlob;
    this.auth.tmpTTDFinalBlob = imgBlob;
    console.log(this.auth.tmpTTDFinalBlob);
    this.saveNotes();
  }

  pathForImage(img){
    if (img == null){
      return '';
    } else {
      if (this.plt.is('cordova')){
        const url = this.webview.convertFileSrc(img);
        return this.sanitizer.bypassSecurityTrustUrl(url);
      } else {
        let converted = this.webview.convertFileSrc(img);
        return converted;
      }
    }
  }

  removeImageAtIndex(index){
    let removed = this.storedImages.splice(index, 1);
    this.file.removeFile(this.file.dataDirectory, removed[0].img).then(res => {
    }, err => {
      console.log('remove err; ' ,err);
    });
    this.storage.set(TTD_FINAL, this.storedImages);
  }

  async saveNotes(){
    this.auth.in_checkout_process = false;
    this.isSaveDone = false;

    let loading = await this.lc.create({
      message: 'Menyimpan...',
    });
    await loading.present();

    let data = {
      idpasien: this.auth.ACTIVE_PATIENT,
      idperawatan: 0,
      idklinik: 0,
      idpenjadwalan: 0,
      notes: this.auth.extra_perawatan_data.notes,
    }

    if (this.auth.session_checkout_data.idperawatan != null && this.auth.session_checkout_data.idperawatan != 0){
      data.idperawatan = this.auth.session_checkout_data.idperawatan;

      this.api.apiCekPasienCheckin(this.auth.DOKTER_KLINIK, this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res1 => {
        if (res1.status) {
          data.idklinik = parseInt(this.auth.ACTIVE_PATIENT_KLINIK);
          data.idpenjadwalan = parseInt(this.auth.ACTIVE_PATIENT_PENJADWALAN);

          if (this.auth.tmpTTDFinalBlob != null){
            this.api.apiSaveNotes(data, this.auth.USER_TOKEN, this.auth.tmpTTDFinalBlob, 'ttd-final.png').subscribe(res2 => {
              this.isSaveDone = true;
              this.auth.showToast(res2.message);
            });
          } else {
            this.api.apiSaveNotes(data, this.auth.USER_TOKEN).subscribe(res2 => {
              this.isSaveDone = true;
              this.auth.showToast(res2.message);
            });
          }
        } else {
          this.lc.dismiss();
          this.mc.dismiss();
          this.auth.showAlert('Pemberitahuan', 'Pasien tidak sedang checkin');
          this.nav.navigateForward('/pasien');
        }
      });

    } else {
      this.api.apiLastIdPerawatan(this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res => {
        data.idperawatan = res.data - 1;

        this.api.apiCekPasienCheckin(this.auth.DOKTER_KLINIK, this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res1 => {
          if (res1.status) {
            data.idklinik = parseInt(this.auth.ACTIVE_PATIENT_KLINIK);
            data.idpenjadwalan = parseInt(this.auth.ACTIVE_PATIENT_PENJADWALAN);

            if (this.auth.tmpTTDFinalBlob != null){
              this.api.apiSaveNotes(data, this.auth.USER_TOKEN, this.auth.tmpTTDFinalBlob, 'ttd-final.png').subscribe(res2 => {
                this.isSaveDone = true;
                this.auth.showToast(res2.message);
              });
            } else {
              this.api.apiSaveNotes(data, this.auth.USER_TOKEN).subscribe(res2 => {
                this.isSaveDone = true;
                this.auth.showToast(res2.message);
              });
            }

            // if (this.ttd != null && this.ttd.path != null){
            //   this.file.resolveLocalFilesystemUrl(this.ttd.filePath).then(entry => {
            //     (<FileEntry>entry).file(file => {
            //       const reader = new FileReader();
            //       reader.onloadend = () => {
            //         const imgBlob = new Blob([reader.result], {
            //           type: file.type,
            //         });
            //         this.api.apiSaveNotes(data, this.auth.USER_TOKEN, imgBlob, file.name).subscribe(res2 => {
            //           this.isSaveDone = true;
            //           this.auth.showToast(res2.message);
            //         });
            //       };
            //     });
            //   });
            // } else {
            //   this.api.apiSaveNotes(data, this.auth.USER_TOKEN).subscribe(res2 => {
            //     this.isSaveDone = true;
            //     this.auth.showToast(res2.message);
            //   });
            // }
          } else {
            this.lc.dismiss();
            this.mc.dismiss();
            this.auth.showAlert('Pemberitahuan', 'Pasien tidak sedang checkin');
            this.nav.navigateForward('/pasien');
          }
        });
      });
    }
    this.autoCheck();
  }

  autoCheck(){
    setTimeout(async () => {
      if (this.isSaveDone){
        this.auth.auto_show_popup_checkout = true;
        this.lc.dismiss();
        this.mc.dismiss();

        let modal = await this.mc.create({
          component: PopupcheckoutPage,
        });
        await modal.present();

        this.nav.navigateForward('/pasien');
      } else {
        this.autoCheck();
      }
    }, 100)
  }
}
