import { Component, OnInit } from '@angular/core';
import { NavParams, AlertController, ToastController, ModalController } from '@ionic/angular';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-viewpdf',
  templateUrl: './viewpdf.page.html',
  styleUrls: ['./viewpdf.page.scss'],
})
export class ViewpdfPage implements OnInit {

  pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";

  constructor(
    private params : NavParams,
    public mc: ModalController,
  ) { }

  ngOnInit() {
    if (this.params.data.target != null){
      this.pdfSrc = this.params.data.target;
    }
  }

}
