import { Component, OnInit, NgZone } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';
import { DentalrecordPage } from 'src/app/pages/perawatan/dentalrecord/dentalrecord.page';
import { listOdonRekomendasiResponse } from 'src/app/services/api/api-response';

@Component({
  selector: 'app-popupgigi',
  templateUrl: './popupgigi.page.html',
  styleUrls: ['./popupgigi.page.scss'],
})

export class PopupgigiPage implements OnInit {

  noGigi: number = 0;
  activeAct : number = 0;
  isGraham : boolean = false;
  hideStatus : boolean = true;

  root : DentalrecordPage = null;
  multiId : number[];
  isMulti : boolean = false;

  teethName : any = ['Seri', 'Seri', 'Taring', 'Graham', 'Graham', 'Graham', 'Graham', 'Graham']
  jawName : any = ['Atas Kiri', 'Atas Kanan', 'Bawah Kanan', 'Bawah Kiri', 'Atas Kiri', 'Atas Kanan', 'Bawah Kanan', 'Bawah Kiri',]
  toothName : string = "";

  opsi = {
    dewasa: [
      {
        key: [1, 2, 3, 4, 5, 6, 7, 8],
        value: ['Incisive Central', 'Incisive Central', 'Caninus',
        'Premolar Pertama', 'Premolar Kedua', 'Molar Pertama',
        'Molar Kedua', 'Molar Ketiga'],
      }, {
        key: [1, 2, 3, 4],
        value: ['Maksila Kanan', 'Maksila Kiri', 'Mandibula Kiri', 'Mandibula Kanan'],
      }
    ],
    susu: [
      {
        key: [1, 2, 3, 4, 5],
        value: ['Incisive Central', 'Incisive Central', 'Caninus',
        'Molar Pertama', 'Molar Kedua'],
      }, {
        key: [5, 6, 7, 8],
        value: ['Maksila Kanan', 'Maksila Kiri', 'Mandibula Kiri', 'Mandibula Kanan'],
      }
    ]
  }

  state : number = 1;
  // state 1 = menu pilih status via tambah status
  // state 2 = konfirmasi penyimpanan status
  // state 3 = menu tambah status via klik gigi
  // state 4 = menu pilih status via masukan status gigi terbaru
  // state 5 = menu masukan posisi permukaan jika tambalan / karies
  // state 6 = konfirmasi penyimpanan status
  // state 7 = detail history gigi

  zones : string[] = [];
  isZoned : boolean = false;

  gigi = {
    id : 0,
    noGigi: 0,
    status : [],
    zones : [],
    tgl_catat: "-",
    catatan: "-",
    dokter : "",
    isMulti : false,
  }

  gigiKeterangan = '';

  historyGigi: any = [
    {
      id : 0,
      status : [],
      zones : [],
      tgl_catat : "1 Juli 2019",
      catatan: "Lorem ipsum dolor sit amet",
      dokter : "Budi Wardana",
    }
  ]

  activeHistory = 0;

  catatanBaru : string = "";

  actClass: any[] = [];
  zoneActClass: any[] = [];

  isTopOnly : boolean = true;
  isHistoryShown : boolean = false;
  isLoadingGigi : boolean = true;
  isLoadingHistory : boolean = true;
  noHistory : boolean = false;
  isLoadingTheHistory : boolean = false;
  rekomen: string = '';

  listRekomendasi = [];

  allRekomendasi: listOdonRekomendasiResponse;
  actRekomendasi: listOdonRekomendasiResponse;
  collectingText = 'Mengambil data';
  isCollectingRekom: boolean = false;

  constructor(
    public auth: AuthService,
    private params: NavParams,
    private mc: ModalController,
    private ac: AlertController,
    private api: ApiService,
    private zone: NgZone,
  ) { }

  ngOnInit() {
    this.noGigi = this.params.data.noGigi;
    this.isMulti = this.params.data.isMulti;
    this.activeAct = this.params.data.activeAct;
    this.changeState(this.params.data.state);

    this.gigi.noGigi = this.noGigi;

    if (this.noGigi != 0){
      this.hideStatus = false;
    }
    if (this.params.data.root != null){
      this.root = this.params.data.root;
    }
    if (this.params.data.multiId != null){
      this.multiId = this.params.data.multiId;
    }

    // this.toothName = "Gigi ";
    if (this.noGigi % 10 > 3){
      this.isGraham = true;
    } else {
      this.isGraham = false;
    }
    // this.toothName += this.teethName[(this.noGigi % 10) - 1] + " " + this.jawName[Math.floor(this.noGigi / 10) - 1];
    // this.toothName += " ("+ ((this.noGigi > 50) ? "Susu" : "Tetap") +")";

    this.toothName = this.namaGigi(this.noGigi);

    if (this.state == 3){
      this.getDataGigi();
      this.getDataHistory();
      this.reload();
    }
    this.loadActClass();
    this.getListRekomendasi();
  }

  ionViewDidEnter(){
    this.changeState(this.state);
  }

  getListRekomendasi(){
    this.api.apiListOdonRekomendasi(this.auth.USER_TOKEN).subscribe(res => {
      this.allRekomendasi = res;
    });
  }

  getActRekomendasi(act: number){
    this.isCollectingRekom = true;
    this.api.apiOpsiOdonRekomendasi(act, this.auth.USER_TOKEN).subscribe(res => {
      this.actRekomendasi = res;
      this.isCollectingRekom = false;
    });
  }

  getDataGigi(){
    this.gigi.status = [1];
    this.isLoadingGigi = true;
    this.api.apiGetGigi(this.auth.ACTIVE_PATIENT, this.noGigi, this.auth.USER_TOKEN).subscribe(res => {
      if (res != null){
        this.listRekomendasi = [];
        let status = res.status;
        let isMulti = (res.status.length > 1) ? true : false;
        this.gigi.status = [];

        if (status.length == 0){
          this.gigi.status.push(1);
        }

        for (let i = 0; i < res.status.length; i++){
          let x = res.status[i];
          this.gigi.status.push(Number(x.idstatus));
          if (i == 0){
            if (this.allRekomendasi != null){
              for (let item of res.status[i].rekomendasi){
                for (let x of this.allRekomendasi.data){
                  if (item.kode == x.kode){
                    this.listRekomendasi.push({
                      kode: x.kode,
                      text: x.keterangan,
                      masalah: item.masalah,
                      harapan: item.harapan,
                      resiko: item.resiko,
                    });
                  }
                }
              }
            } else {
              let that = this;
              setTimeout(function() {
                for (let item of res.status[i].rekomendasi){
                  for (let x of that.allRekomendasi.data){
                    if (item.kode == x.kode){
                      that.listRekomendasi.push({
                        kode: x.kode,
                        text: x.keterangan,
                        masalah: item.masalah,
                        harapan: item.harapan,
                        resiko: item.resiko,
                      });
                    }
                  }
                }
              }, 1000);
            }

            this.gigi.tgl_catat = this.auth.getTanggal(x.tanggal);
            this.gigi.dokter = x.dokter.namadokter;
            this.gigi.isMulti = isMulti;
            if (x.kondisi[0] != null){
              this.gigi.catatan = x.kondisi[0].keterangan;
            }
            if (this.isStatusZoned(x.idstatus)){
              this.gigi.zones = [];
              for (let z of x.posisi){
                let tmp_zone = z.permukaan[0].singkatan.toLowerCase();
                if ((this.noGigi > 20 && this.noGigi < 40) || (this.noGigi > 60 && this.noGigi < 80)){
                  if (z.permukaan[0].singkatan.toLowerCase() == 'm'){
                    tmp_zone = 'd';
                  } else if (z.permukaan[0].singkatan.toLowerCase() == 'd'){
                    tmp_zone = 'm';
                  }
                }
                if ((this.noGigi > 30 && this.noGigi < 50) || (this.noGigi > 70 && this.noGigi < 90)){
                  if (z.permukaan[0].singkatan.toLowerCase() == 'l'){
                    tmp_zone = 'v';
                  } else if (z.permukaan[0].singkatan.toLowerCase() == 'v'){
                    tmp_zone = 'l';
                  }
                }
                this.gigi.zones.push(tmp_zone);
              }
            }
          }
        }
      } else {
        this.gigi = {
          id : 0,
          noGigi: this.noGigi,
          status : [1],
          zones : [],
          tgl_catat: "-",
          catatan: "-",
          dokter : "",
          isMulti : false,
        }
      }
      this.isLoadingGigi = false;
    }, err => {
      this.gigi = {
        id : 0,
        noGigi: this.noGigi,
        status : [1],
        zones : [],
        tgl_catat: "-",
        catatan: "-",
        dokter : "",
        isMulti : false,
      }
      this.isLoadingGigi = false;
    })
  }

  getDataHistory(){
    this.historyGigi = [];
    this.isLoadingHistory = true;
    this.api.apiGetHistoryGigi(this.auth.ACTIVE_PATIENT, this.noGigi, this.auth.USER_TOKEN).subscribe(res => {
      if (res.status.length > 0){
        for (let i = 0; i < res.status.length; i++){
          let x = res.status[i]

          let rekomendasi = [];
          if (x.rekomendasi.length > 0){
            rekomendasi = x.rekomendasi;
          }

          let catatan = "";
          if (x.kondisi[0] != null){
            catatan = x.kondisi[0].keterangan;
          }
          let zones = [];
          if (this.isStatusZoned(x.idstatus)){
            for (let z of x.posisi){
              let tmp_zone = z.permukaan[0].singkatan.toLowerCase();
              if ((this.noGigi > 20 && this.noGigi < 40) || (this.noGigi > 60 && this.noGigi < 80)){
                if (z.permukaan[0].singkatan.toLowerCase() == 'm'){
                  tmp_zone = 'd';
                } else if (z.permukaan[0].singkatan.toLowerCase() == 'd'){
                  tmp_zone = 'm';
                }
              }
              if ((this.noGigi > 30 && this.noGigi < 50) || (this.noGigi > 70 && this.noGigi < 90)){
                if (z.permukaan[0].singkatan.toLowerCase() == 'l'){
                  tmp_zone = 'v';
                } else if (z.permukaan[0].singkatan.toLowerCase() == 'v'){
                  tmp_zone = 'l';
                }
              }
              zones.push(tmp_zone);
            }
          }

          this.historyGigi.push({
            id : Number(x.idodontogram),
            status : [Number(x.idstatus)],
            zones : zones,
            catatan : catatan,
            tgl_catat : this.auth.getTanggal(x.tanggal),
            dokter : x.dokter.namadokter,
            rekomendasi: rekomendasi,
          })
        }
      } else {
        this.noHistory = true;
      }
      this.isLoadingHistory = false;
    })
  }

  getHistoryRekomendasi(rekomendasi){
    let res = '';
    if (this.allRekomendasi != null && this.allRekomendasi.data != null){
      for (let item of this.allRekomendasi.data){
        if (rekomendasi.kode == item.kode){
          res = item.keterangan;
          break;
        }
      }
    }
    return res;
  }

  historyDetail(index){
    this.changeState(7);
    this.activeHistory = index;
    this.isLoadingTheHistory = true;
    this.loadTheHistory();
  }

  loadTheHistory(){
    setTimeout(()=> {
      if (this.state != 7){
        this.loadTheHistory();
      } else {
        this.resetGigiHistory();
      }
    }, 100)
  }

  async changeAction(i, e) {

    this.root.activeAct = i;
    this.root.isMultiOn = true;
    this.root.multiId = [];
    this.root.gigiWhiteAll();
    const alert = await this.ac.create({
      header: 'Peringatan',
      message: "Beralih ke mode pemilihan gigi, silahkan pilih gigi yang akan diberikan status <b>" + this.getActClass(this.root.activeAct).text + "</b>",
      buttons: ['OK']
    });
    this.mc.dismiss();

    await alert.present();
  }

  showAct(){
    this.isTopOnly = false;
    this.resetList();
  }

  topAct(){
    let x = Array.from(this.actClass);
    for(let y of this.actClass){
      if (!y.top){
        let index = x.indexOf(y);
        x.splice(index, 1);
      }
    }
    return x;
  }

  async baseGigi(targetid){
    let element = await this.waitForElm(targetid) as HTMLElement;
    if (element != null){
      for (let k = 0; k < this.actClass.length; k++){
        element.classList.remove("status-zoned");
        element.classList.remove("status-not-zoned");
        element.classList.remove(this.actClass[k].class);
        element.classList.remove("status-" + this.actClass[k].inText.toLowerCase());
        element.setAttribute('style', '');
      }
      let sides = element.querySelectorAll('.side');
      for (let k = 0; k < sides.length; k++){
        for (let zoneClass of this.zoneActClass){
          sides[k].classList.remove(zoneClass.class);
          sides[k].setAttribute('style', '');
        }
      }
      if (element.querySelector('.status-ipx') != null){
        element.querySelector('.status-ipx').innerHTML = "";
        element.querySelector('.status-ipx').classList.remove('black');
      }
      if (element.querySelector('.status-ue') != null){
        element.querySelector('.status-ue').innerHTML = "";
        element.querySelector('.status-ue').classList.remove('black');
      }
      if (element.querySelector('.status-text') != null){
        element.querySelector('.status-text').innerHTML = "";
        element.querySelector('.status-text').classList.remove('black');
      }

      if (element.querySelector('.status-custom-path') != null){
        element.querySelector('.status-custom-path').remove();
      }
    }
  }

  async backToStatus(dataGigi, targetid: string = "ed_gigi_gigi", nogigi: number = 1, idstatus: number = 1, list: boolean = false){
    let element = await this.waitForElm(targetid) as HTMLElement;
    if (dataGigi == null){
      dataGigi = {
        isMulti: false,
        status: [idstatus],
        noGigi: nogigi,
        zones: [],
      }
    }

    if (dataGigi != null){
      if (dataGigi.isMulti){
        element.classList.add('status-multi');
        element.classList.add('status-m');
        element.querySelector('.status-text').innerHTML="M";
      } else {
        if (dataGigi.status.length > 0){
          if (dataGigi.status[0] < 1){
            dataGigi.status[0] = 1;
          }
          let cActClass = this.getActClass(dataGigi.status[0]);
          let cZoneClass = this.getZoneActClass(dataGigi.status[0]);
          if (this.isStatusZoned(dataGigi.status[0]) && !list) {
            element.classList.add('status-zoned');
          }
          if (this.isStatusZoned(dataGigi.status[0]) && list) {
            element.classList.add('status-ignore');
          }

          if (cActClass.customStyle != null && cActClass.customStyle.length > 0){
            //not applying class, just style affecting status-caption
            element.querySelector('.status-caption').setAttribute('style', cActClass.customStyle);
          } else {
            element.classList.add(cActClass.class);
          }

          if (cActClass.customPath != null && cActClass.customPath.length > 0){
            //append custom path to element
            let xStyle = '';
            let xPath = '';
            let offsetX = 25;
            let offsetY = 25;
            let check = cActClass.customPath.split("^^^");
            if (check.length > 0){
              xPath = check[0];
              xStyle = check[1];
              offsetX = parseInt(check[2]);
              offsetY = parseInt(check[3]);
            }
            let targetCaption = element.querySelector('.status-caption');
            if (targetCaption != null){
              offsetX += parseInt(targetCaption.getAttribute('x'));
              offsetY += parseInt(targetCaption.getAttribute('y'));
            }

            let pathElem = '<path class="status status-custom-path" id="status_status-custom-path-'+ dataGigi.noGigi +'" d="M'+ offsetX +','+ offsetY +''+ xPath +'" style="'+ xStyle +'"></path>';
            if (dataGigi.noGigi == 1){
              pathElem = '<path class="status status-custom-path" d="M'+ offsetX +','+ offsetY +''+ xPath +'" style="'+ xStyle +'"></path>';
            } else {
              pathElem = '<path class="status status-custom-path" id="status_status-custom-path-'+ dataGigi.noGigi +'" d="M'+ offsetX +','+ offsetY +''+ xPath +'" style="'+ xStyle +'"></path>';
            }
            element.innerHTML += pathElem;
          }

          element.classList.add('status-' + cActClass.inText.toLowerCase());

          if (cActClass.inText.length == 1){
            element.querySelector('.status-text').innerHTML = cActClass.inText;
          } else if (cActClass.inText.length == 2){
            element.querySelector('.status-ue').innerHTML = cActClass.inText;
          } else if (cActClass.inText.length == 3){
            element.querySelector('.status-ipx').innerHTML = cActClass.inText;
          }

          if (this.isStatusZoned(dataGigi.status[0]) && !list && dataGigi.zones != null){
            if (dataGigi.zones != null && !dataGigi.zones.includes("o")) {
              element.querySelector(".status-text").classList.add("black");
              element.querySelector(".status-ue").classList.add("black");
              element.querySelector(".status-ipx").classList.add("black");
            } else {
              element.querySelector(".status-text").classList.remove("black");
              element.querySelector(".status-ue").classList.remove("black");
              element.querySelector(".status-ipx").classList.remove("black");
            }
            for(let j = 0; j < dataGigi.zones.length; j++){
              if (element.querySelector(".side-" + dataGigi.zones[j]) != null){
                if (cZoneClass.customStyle != null && cZoneClass.customStyle.length > 0){
                  element.querySelector(".side-" + dataGigi.zones[j]).setAttribute('style', cZoneClass.customStyle);
                } else {
                  element.querySelector(".side-" + dataGigi.zones[j]).classList.add(cZoneClass.class);
                }
              }
            }
          }
        }
      }
    }
  }

  resetGigi(){
    this.zone.run(()=> {
      this.baseGigi("ed_gigi_gigi");
      this.backToStatus(this.gigi, "ed_gigi_gigi", null, null, false);
    });
  }

  resetGigiConfirm(noGigi, status){
    this.zone.run(()=> {
      this.baseGigi("modal_gigi_gigi" + noGigi);
      this.backToStatus(null, "modal_gigi_gigi" + noGigi, noGigi, status, false);
    });
  }

  resetGigiHistory(){
    this.zone.run(()=> {
      this.baseGigi("ed_gigi_gigi");
      this.backToStatus(this.historyGigi[this.activeHistory], "ed_gigi_gigi", null, null, false);
    });
  }

  resetList(){
    this.zone.run(()=> {
      for (let i = 0; i < this.actClass.length; i++){
        var item = this.actClass[i];
        this.baseGigi("status-listed-" + item.no);
        this.backToStatus(null, "status-listed-" + item.no, 1, item.no, true);
      }
    });
  }

  resetHistoryList(){
    this.zone.run(()=> {
      for (let i = 0; i < this.historyGigi.length; i++){
        var item = this.historyGigi[i];
        this.baseGigi("status-history-" + item.status + '-' + item.id);
        this.backToStatus(null, "status-history-" + item.status + '-' + item.id, 1, item.status, true);
      }
    });
  }

  showHistoryList(){
    this.isHistoryShown = !this.isHistoryShown;
    if (this.isHistoryShown){
      this.resetHistoryList();
    }
  }

  back(state){
    this.changeState(state);
  }

  selectAct(){
    this.changeState(4);
  }

  doAction(act : number){
    this.activeAct = act;
    if (this.isStatusMultid(act)){
      this.root.activeAct = this.activeAct;
      this.root.isMultiOn = true;
      this.root.multiId = [this.noGigi];
      this.root.gigiWhiteAll();
      this.root.changeTo(this.noGigi, this.activeAct);
      this.mc.dismiss();
    } else if (this.isStatusZoned(act)){
      this.changeState(5);
      this.zones = [];
      this.activeAct = act;
    } else {
      this.changeState(6);
      if (!this.isStatusZoned(act)){
        this.zones = [];
      }
      this.activeAct = act;
    }
  }

  zoneClick(zone : string, mode: number){
    if (this.isStatusZoned(this.activeAct)){
      let zoneSide = zone;
      if (((this.noGigi > 20 && this.noGigi < 40) || (this.noGigi > 60 && this.noGigi < 80)) && mode == 2){
        if (zone == 'm'){
          zoneSide = 'd';
        } else if (zone == 'd'){
          zoneSide = 'm';
        }
      } else if (((this.noGigi > 20 && this.noGigi < 40) || (this.noGigi > 60 && this.noGigi < 80)) && mode == 1){
        if (zone == 'm'){
          zone = 'd';
        } else if (zone == 'd'){
          zone = 'm';
        }
      }
      if (((this.noGigi > 30 && this.noGigi < 50) || (this.noGigi > 70 && this.noGigi < 90)) && mode == 2){
        if (zone == 'l'){
          zoneSide = 'v';
        } else if (zone == 'v'){
          zoneSide = 'l';
        }
      } else if (((this.noGigi > 30 && this.noGigi < 50) || (this.noGigi > 70 && this.noGigi < 90)) && mode == 1){
        if (zone == 'l'){
          zone = 'v';
        } else if (zone == 'v'){
          zone = 'l';
        }
      }
      let element = document.getElementById("zone-"+zone);
      let zone_e = document.getElementById("modal_side_side-"+zoneSide);
      let x = this.activeAct;

      let cZoneClass = this.getZoneActClass(x);
      var index = 0;

      for (let i = 0; i < this.zones.length; i++){
        if (zone == this.zones[i]){
          index = i;
        }
      }
      if (element.classList.contains('selected')){
        this.zones.splice(index, 1);
        element.classList.remove('selected');
        if (cZoneClass.customStyle != null && cZoneClass.customStyle.length > 0){
          element.setAttribute('style', '');
          zone_e.setAttribute('style', '');
        } else {
          zone_e.classList.remove(cZoneClass.class);
          element.classList.remove(cZoneClass.class);
        }
      } else {
        // if (mode == 2){
        //   zone = zoneSide;
        // }
        this.zones.push(zone);
        element.classList.add('selected');
        if (cZoneClass.customStyle != null && cZoneClass.customStyle.length > 0){
          element.setAttribute('style', cZoneClass.customStyle);
          zone_e.setAttribute('style', cZoneClass.customStyle);
        } else {
          zone_e.classList.add(cZoneClass.class);
          element.classList.add(cZoneClass.class);
        }
      }
    }
  }

  cancelZonedAct(){
    if (this.isMulti){
      this.zones = [];
      this.changeState(1);
      this.activeAct = 0;
      this.mc.dismiss();
    } else {
      this.zones = [];
      this.changeState(4);
      this.activeAct = 0;
    }
  }

  async saveZonedAct(){
    if (this.zones.length > 0){
      this.changeState(6);
      this.isZoned = true;
      // this.isMulti = false;
      let x = this.activeAct;
      let cZoneClass = this.getZoneActClass(x);

      setTimeout(()=>{
        let element = document.getElementById('modal_gigi_gigi'+ this.noGigi);
        element.classList.add('status-' + this.getActClass(this.activeAct).inText.toLowerCase());

        if (!this.zones.includes('o')){
          let text = element.querySelector('.status-text');
          text.classList.add('black');
        }
        for (let i = 0; i < this.zones.length; i++){
          var zone = this.zones[i];
          if ((this.noGigi > 20 && this.noGigi < 40) || (this.noGigi > 60 && this.noGigi < 80)){
            if (zone == 'm'){
              zone = 'd';
            } else if (zone == 'd'){
              zone = 'm';
            }
          }
          if ((this.noGigi > 30 && this.noGigi < 50) || (this.noGigi > 70 && this.noGigi < 90)){
            if (zone == 'l'){
              zone = 'v';
            } else if (zone == 'v'){
              zone = 'l';
            }
          }
          let zone_e = document.getElementById("modal_side_side-"+zone);
          if (cZoneClass.customStyle != null && cZoneClass.customStyle.length > 0){
            zone_e.setAttribute('style', cZoneClass.customStyle);
          } else {
            zone_e.classList.add(cZoneClass.class);
          }
        }
      }, 100);
    } else {
      const alert = await this.ac.create({
        header: "Pemberitahuan",
        message : "Pilih minimal satu zona untuk memasukan status ini",
        buttons: ['OK'],
      });
      await alert.present();
    }
  }

  submit(form){
    this.gigi.status = [this.activeAct];
    this.gigi.zones = Array.from(this.zones);
    this.gigi.catatan = this.catatanBaru;

    this.root.isMultiOn = false;
    this.root.activeAct = 0;
    this.root.multiId = [];

    //API ke db

    let dt = new Date();
    let tgl = dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + dt.getDate();
    let waktu = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();

    let rekomendasi = [];

    if (this.actRekomendasi != null){
      if (this.actRekomendasi.data.length > 0){
        for (let item of this.actRekomendasi.data){
          let element = document.getElementById('rekomen_' + item.koderekomendasi) as HTMLInputElement;
          if (element != null && element.checked){
            let masalah = document.getElementById('rekomen_' + item.koderekomendasi + '_masalah') as HTMLInputElement;
            let harapan = document.getElementById('rekomen_' + item.koderekomendasi + '_harapan') as HTMLInputElement;
            let resiko = document.getElementById('rekomen_' + item.koderekomendasi + '_resiko') as HTMLInputElement;
            rekomendasi.push({
              kode: item.koderekomendasi,
              masalah: masalah.value,
              harapan: harapan.value,
              resiko: resiko.value,
            })
          }
        }
      }
    }

    if (this.state == 2){
      for (let i = 0; i < this.multiId.length; i++){
        this.api.apiPostStatusGigi(
          this.auth.ACTIVE_PATIENT,
          this.multiId[i].toString(),
          this.gigi.status[0].toString(),
          '1', tgl, waktu,
          this.gigiKeterangan,
          this.gigi.zones,
          rekomendasi,
          this.auth.USER_TOKEN
        ).subscribe(res => {
          this.auth.session_checkup_data.odontogram.changed = true;
          this.auth.showToast(res.success);
          this.gigiKeterangan = '';
          this.root.refresh();
        })
      }
    } else if (this.state == 6){
      let x = this.activeAct;
      this.api.apiPostStatusGigi(
        this.auth.ACTIVE_PATIENT,
        this.noGigi.toString(),
        this.gigi.status[0].toString(),
        '1', tgl, waktu,
        this.gigiKeterangan,
        this.gigi.zones,
        rekomendasi,
        this.auth.USER_TOKEN
      ).subscribe(res => {
        this.auth.session_checkup_data.odontogram.changed = true;
        this.auth.showToast(res.success);
        this.gigiKeterangan = '';
        this.root.refresh();
      })
    }
    this.mc.dismiss();
  }

  cancel(){
    if (this.isMulti){
      this.mc.dismiss();
    } else {
      let z = this.activeAct;
      let cActClass = this.getActClass(z);
      let cZoneClass = this.getZoneActClass(z);
      if (this.zones.length > 0 && this.isStatusZoned(z)){
        this.changeState(5);
        setTimeout(()=>{
          for (let i = 0; i < this.zones.length; i++){
            var zone = this.zones[i];
            var zoneButton = zone;
            if ((this.noGigi > 20 && this.noGigi < 40) || (this.noGigi > 60 && this.noGigi < 80)){
              if (zone == 'm'){
                zone = 'd';
              } else if (zone == 'd'){
                zone = 'm';
              }
            }
            if ((this.noGigi > 30 && this.noGigi < 50) || (this.noGigi > 70 && this.noGigi < 90)){
              if (zone == 'l'){
                zone = 'v';
              } else if (zone == 'v'){
                zone = 'l';
              }
            }
            let element = document.getElementById("zone-"+zoneButton);
            let zone_e = document.getElementById("modal_side_side-"+zone);
            element.classList.add('selected');
            if (cZoneClass.customStyle != null && cZoneClass.customStyle.length > 0){
              element.setAttribute('style', cZoneClass.customStyle);
              zone_e.setAttribute('style', cZoneClass.customStyle);
            } else {
              zone_e.classList.add(cZoneClass.class);
              element.classList.add(cZoneClass.class);
            }
          }
        }, 100);
      } else {
        this.zones = [];
        this.changeState(4);
        this.activeAct = 0;
      }
    }
  }

  toggleHistory(){
    if (this.isHistoryShown){
      this.isHistoryShown = false;
    } else {
      this.isHistoryShown = true;
    }
  }

  async deleteHistory(id){
    const alert = await this.ac.create({
      header: "Pemberitahuan",
      message: "Yakin ingin menghapus status ini?",
      buttons: [
        {
          text : "Ya",
          handler : ()=>{
            //delete
            //API ke db
            if (this.state == 7){
              this.changeState(3);
              this.toggleHistory();
            }

            this.api.apiDeleteHistoryGigi(this.auth.ACTIVE_PATIENT, this.gigi.noGigi.toString(), id, '1', this.auth.USER_TOKEN)
            .subscribe(res => {
              this.auth.session_checkup_data.odontogram.changed = true;
              this.auth.showToast(res.success);
              this.isLoadingGigi = true;
              this.isLoadingHistory = true;
              this.getDataHistory();
              this.getDataGigi();
              this.root.refresh();
              this.reload();
              this.reloadHistory();
            })
          }
        },{
          text : "Tidak",
          role: 'cancel',
        }
      ]
    });
    await alert.present();
  }

  rekomendasiChecked(id){
    if (this.actRekomendasi != null && this.actRekomendasi.data != null){
      let element = document.getElementById(id) as HTMLInputElement;
      if (element != null && element.checked){
        return true;
      }
    }
    return false;
  }

  checkedRekomendasi(){
    var id = '';
    if (this.actRekomendasi != null && this.actRekomendasi.data != null){
      for (let item of this.actRekomendasi.data){
        id = 'rekomen_' + item.koderekomendasi;
        let element = document.getElementById(id) as HTMLInputElement;
        if (element != null && element.checked){
          return item.koderekomendasi;
        }
      }
    }
    return null;
  }

  remoteClick(id){
    let element = document.getElementById(id);
    element.click();
  }

  reload(){
    setTimeout(()=>{
      if (this.isLoadingGigi){
        this.reload()
      } else {
        this.resetGigi();
      }
    }, 100)
  }

  reloadHistory(){
    setTimeout(()=>{
      if (this.isLoadingHistory){
        this.reloadHistory()
      } else {
        this.resetGigiHistory();
      }
    }, 100)
  }

  namaGigi(x: number){
    let result = '';
    if (x < 50){
      result = 'Gigi Dewasa ';
      result += this.opsi.dewasa[0].value[x % 10 - 1] + ' ' + this.opsi.dewasa[1].value[Math.floor(x / 10) - 1];
    } else {
      result = 'Gigi Susu ';
      result += this.opsi.susu[0].value[x % 10 - 1] + ' ';
      let active = 0;
      let i = 0;
      for (let item of this.opsi.susu[1].key){
        if (Math.floor(x / 10) == item){
          active = i;
        }
        i++;
      }
      result += this.opsi.susu[1].value[active];
    }
    return result;
  }

  async changeState(state: number){
    this.state = state;
    const delay = ms => new Promise(res => setTimeout(res, ms));
    await delay(100);
    if (state == 2 || state == 6){
      this.getActRekomendasi(this.activeAct);
      this.resetGigiConfirm(this.noGigi, this.activeAct);
    } else if (state == 3){
      this.resetGigi();
      this.resetHistoryList();
    } else if (state == 4 || state == 1){
      this.resetList();
    } else if (state == 5){
      // this.resetGigiConfirm(this.noGigi, this.activeAct);
    }
  }

  waitForElm(id){
    return new Promise(resolve => {
      if (document.getElementById(id)){
        return resolve(document.getElementById(id));
      }

      const observer = new MutationObserver(mutations => {
        if (document.querySelector(id)) {
            observer.disconnect();
            resolve(document.getElementById(id));
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    });
  }

  collectingData(text: string){
    this.collectingText = text;
    setTimeout(()=> {
      if (this.isCollectingRekom){
        this.collectingData(text + '.');
      }
    }, 1000);
  }

  hasHistory(){
    if (this.historyGigi.length > 0){
      return true;
    }
    return false;
  }

  allowSubmit(){
    let rekomendasi = [];
    if (this.actRekomendasi != null){
      if (this.actRekomendasi.data.length > 0){
        for (let item of this.actRekomendasi.data){
          let element = document.getElementById('rekomen_' + item.koderekomendasi) as HTMLInputElement;
          if (element != null && element.checked){
            rekomendasi.push(item.koderekomendasi);
          }
        }
      }
    }
    if (rekomendasi.length > 0){
      return true;
    }
    return false;
  }

  getActClass(idstatus){
    this.loadActClass();
    for (let i = 0; i < this.actClass.length; i++){
      var item = this.actClass[i];
      if (item.no == idstatus){
        return item;
      }
    }
    return {
      no: 1,
      text: 'Gigi Sehat',
      class: 'sehat',
      inText: '',
      top: false,
      isMulti: false,
    };
  }

  getZoneActClass(idstatus){
    this.loadActClass();
    for (let i = 0; i < this.zoneActClass.length; i++){
      var item = this.zoneActClass[i];
      if (item.no == idstatus){
        return item;
      }
    }
    return {
      no: 1,
      class: '',
      color: '',
    };
  }

  loadActClass(){
    if (this.auth.currentActClass != null && this.auth.currentActClass.length > 0){
      this.actClass = this.auth.currentActClass;
      this.zoneActClass = this.auth.currentZoneActClass;
    } else {
      this.auth.getOdontogramStatus();
      setTimeout(function(){
        this.loadActClass();
      }, 1000);
    }
  }

  isStatusZoned(idstatus){
    for (let i = 0; i < this.zoneActClass.length; i++){
      let item = this.zoneActClass[i];
      if (Number(idstatus) == item.no){
        return true;
      }
    }
    return false;
  }

  isStatusMultid(idstatus){
    for (let i = 0; i < this.actClass.length; i++){
      let item = this.actClass[i];
      if (Number(idstatus) == item.no && item.isMulti){
        return true;
      }
    }
    return false;
  }
}
