import { Component, OnInit, NgZone } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/settings/settings';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-popupviewjadwal',
  templateUrl: './popupviewjadwal.page.html',
  styleUrls: ['./popupviewjadwal.page.scss'],
})
export class PopupviewjadwalPage implements OnInit {

  idklinik = null;
  idpenjadwalan = null;
  idpasien = null;
  current = null;

  previewData = null;

  constructor(
    public auth: AuthService,
    private api: ApiService,
    private mc: ModalController,
    private params: NavParams,
    private zone: NgZone,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.params.data.idklinik != null){
      this.idklinik = this.params.data.idklinik;
    }
    if (this.params.data.idpenjadwalan != null){
      this.idpenjadwalan = this.params.data.idpenjadwalan;
    }
    if (this.params.data.idpasien != null){
      this.idpasien = this.params.data.idpasien;
    }
    if (this.params.data.current != null){
      this.current = this.params.data.current;
    }
    this.getAll();
  }

  getAll(){
    this.previewData = null;
    this.api.apiPreviewJadwal(this.idklinik, this.idpenjadwalan, this.auth.USER_TOKEN).subscribe(res => {
      this.previewData = res;
    });
  }

  mulaiSesi(){
    this.auth.ACTIVE_PATIENT = this.idpasien;

    this.auth.ACTIVE_PATIENT_PENJADWALAN = this.idpenjadwalan;
    this.auth.ACTIVE_PATIENT_KLINIK = this.idklinik;
    this.zone.run(() => {
      this.router.navigateByUrl('/pasien');
    });

    if (AppSettings.isAppCompact()){
      this.api.apiCheckinPasien(this.idklinik, this.idpenjadwalan, this.auth.USER_TOKEN).subscribe(res => {
        console.log(res);
      });
    }

    if (this.current){
      this.api.apiWaktuPerawatan(this.idklinik, this.idpenjadwalan, this.auth.USER_TOKEN).subscribe(res => {
        this.auth.waktuPemeriksaan = res.data;
      })
    }

    this.mc.dismiss();
  }

}
