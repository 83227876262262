import { Component, OnInit, ViewChild } from '@angular/core';
import { NavController, ModalController, NavParams, LoadingController, Platform, IonSlides } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { File, IWriteOptions } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupttdfinalPage } from 'src/app/pages/modals/popupttdfinal/popupttdfinal.page';

const TTD_FINAL = "KLINIKOO_TTD_FINAL";

interface point{
  x: number,
  y: number,
}

interface path{
  initialPoint : point,
  nextPoint: point[],
  endPoint: point,
  color: string,
}

@Component({
  selector: 'app-popupnotesperawatan',
  templateUrl: './popupnotesperawatan.page.html',
  styleUrls: ['./popupnotesperawatan.page.scss'],
})
export class PopupnotesperawatanPage implements OnInit {

  mode: string = 'show';
  showForm: boolean = false;

  notes: string = '';
  isSaveDone: boolean = false;

  notesData: any = null;

  colorPallete = [
    '#000000',
  ];
  storedImages = [];
  canvasElement: any;
  activeColor = '#000000';
  activeX = 0;
  activeY = 0;
  strokeIndex = 0;
  trackPath : path[];
  tmpPath : path;

  ttd: any = null;
  perawatan_data: any = null;

  @ViewChild('ttdCanvas') canvas: any;

  rencanaTindakan: any = [
    {
      id: 1,
      text: 'Rencana Tindakan 1',
      harga: 350000,
    }, {
      id: 2,
      text: 'Rencana Tindakan 2',
      harga: 350000,
    }
  ];

  selectedContent = 0;
  @ViewChild(IonSlides) slider: IonSlides;

  constructor(
    public auth: AuthService,
    private nav: NavController,
    private mc: ModalController,
    private api: ApiService,
    private plt: Platform,
    private webview: WebView,
    private params : NavParams,
    private sanitizer: DomSanitizer,
    private lc: LoadingController,
    private storage: Storage,
    private file: File,
  ) { }

  ngOnInit() {
    this.mode = this.params.data.mode;
    if (this.mode == 'show'){
      this.showForm = false;
      this.getNotes();
    } else if (this.mode == 'new'){
      this.showForm = true;
      this.getNotes();
    }

    this.trackPath = [];
    this.tmpPath = {
      initialPoint : {x : 0, y: 0},
      nextPoint: [],
      endPoint: {x : 0, y: 0},
      color: "#000000",
    };

    this.storage.remove(TTD_FINAL);

    if (this.params.data.perawatan_data != null){
      this.perawatan_data = this.params.data.perawatan_data;
      this.setupHarga();
    }
  }

  ionViewDidEnter(){
    this.canvasElement = this.canvas.nativeElement;
    this.canvasElement.width = this.canvas.nativeElement.width;
    this.canvasElement.height = this.canvas.nativeElement.height;
  }

  startDrawing(ev){
    var canvasPosition = this.canvasElement.getBoundingClientRect();
    this.activeX = ev.touches[0].pageX - canvasPosition.x;
    this.activeY = ev.touches[0].pageY - canvasPosition.y;

    this.tmpPath.initialPoint.x = this.activeX;
    this.tmpPath.initialPoint.y = this.activeY;
    this.tmpPath.color = this.activeColor;
  }

  move(ev){
    var canvasPosition = this.canvasElement.getBoundingClientRect();
    let context = this.canvasElement.getContext('2d');
    let currentX = ev.touches[0].pageX - canvasPosition.x;
    let currentY = ev.touches[0].pageY - canvasPosition.y;

    context.lineJoin = 'round';
    context.strokeStyle = this.activeColor;
    context.lineWidth = 3;

    context.beginPath();
    context.moveTo(this.activeX, this.activeY);
    context.lineTo(currentX, currentY);
    context.closePath();
    context.stroke();
    this.activeX = currentX;
    this.activeY = currentY;

    this.tmpPath.nextPoint.push({
      x : this.activeX,
      y : this.activeY,
    });
  }

  createStack(ev){
    let currentX = this.tmpPath.nextPoint[this.tmpPath.nextPoint.length - 1].x;
    let currentY = this.tmpPath.nextPoint[this.tmpPath.nextPoint.length - 1].y;
    this.tmpPath.endPoint.x = currentX;
    this.tmpPath.endPoint.y = currentY;
    this.strokeIndex += 1;
    this.trackPath.push(this.tmpPath);
    this.tmpPath = {
      initialPoint : {x : 0, y: 0},
      nextPoint: [],
      endPoint: {x : 0, y: 0},
      color: "#000000",
    };
    //let context = this.canvasElement.getContext('2d');
  }

  drawStroke(path: path){
    if (this.activeColor != path.color){
      this.activeColor = path.color;
    }

    let last : point = {
      x : path.initialPoint.x,
      y : path.initialPoint.y,
    }

    let context = this.canvasElement.getContext('2d');
    context.lineJoin = 'round';
    context.strokeStyle = this.activeColor;
    context.lineWidth = 3;

    for (let i = 0; i < path.nextPoint.length; i++){
      let item = path.nextPoint[i];
      context.beginPath();
      if (i == path.nextPoint.length - 1){
        context.moveTo(last.x, last.y);
        context.lineTo(path.endPoint.x, path.endPoint.y);
      } else {
        context.moveTo(last.x, last.y);
        context.lineTo(item.x, item.y);
        last.x = item.x;
        last.y = item.y;
      }
      context.stroke();
      context.closePath();
    }
  }

  canvasUndo(){
    //console.log(this.trackPath.length);
    let context = this.canvasElement.getContext('2d');
    context.clearRect(0,0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.trackPath.splice(this.trackPath.length - 1, 1);
    for (let item of this.trackPath){
      this.drawStroke(item);
    }
  }

  canvasClear(){
    //var canvasPosition = this.canvasElement.getBoundingClientRect();
    let context = this.canvasElement.getContext('2d');
    context.clearRect(0,0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.trackPath = [];

    this.ttd = null;
    this.storage.remove(TTD_FINAL);
  }

  canvasImage(){
    var dataUrl = this.canvasElement.toDataURL();
    let context = this.canvasElement.getContext('2d');
    //context.clearRect(0,0, context.canvas.width, context.canvas.height);
    let name = new Date().getTime() + '.png';
    let path = this.file.dataDirectory;
    let options : IWriteOptions = { replace: true, }

    var data = dataUrl.split(',')[1];
    let blob = this.b64toBlob(data, 'image/png');

    this.file.writeFile(path, name, blob, options).then(res => {
      this.storeImage(name);
      this.selectedContent = 0;
    }, err => {
      console.log('error: ', err);
    })
  }

  b64toBlob(b64Data, contentType){
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize){
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++){
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  storeImage(imageName){
    let filePath = this.file.dataDirectory + imageName;
    let resPath = this.pathForImage(filePath);
    let saveObj = { name: imageName, path: resPath, filePath: filePath };

    this.storedImages.push(saveObj);
    console.log(saveObj);
    this.ttd = saveObj;

    this.storage.set(TTD_FINAL, this.storedImages);
  }

  pathForImage(img){
    if (img == null){
      return '';
    } else {
      if (this.plt.is('cordova')){
        const url = this.webview.convertFileSrc(img);
        return this.sanitizer.bypassSecurityTrustUrl(url);
      } else {
        let converted = this.webview.convertFileSrc(img);
        return converted;
      }
    }
  }

  removeImageAtIndex(index){
    let removed = this.storedImages.splice(index, 1);
    this.file.removeFile(this.file.dataDirectory, removed[0].img).then(res => {
    }, err => {
      console.log('remove err; ' ,err);
    });
    this.storage.set(TTD_FINAL, this.storedImages);
  }

  getNotes(){
    this.notesData = null;
    this.api.apiGetNotesPusat(this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res => {
      this.notesData = res;
    });
  }

  allowSave(){
    if (this.notes.length > 0){
      return true;
    }
    return false;
  }

  async saveNotes(){
    this.auth.extra_perawatan_data = {
      ttd: null,
      notes: this.notes,
    }

    setTimeout(async () => {
      const modal = await this.mc.create({
        component: PopupttdfinalPage,
        backdropDismiss: false,
        keyboardClose: false,
      });
      await modal.present();
    }, 200);

    this.mc.dismiss();
  }

  setupHarga(){
    this.rencanaTindakan = [];
    for (let item of this.perawatan_data.tindakan){
      this.rencanaTindakan.push({
        id: item.idtindakan,
        text: item.nama,
        harga: item.harga,
      });
    }
  }

  displayHarga(harga){
    return Number(harga).toLocaleString('id');
  }

  segmentChanged(e) {
    this.slider.slideTo(e.detail.value);
  }

  sliderChanged(e) {
    this.slider.getActiveIndex().then((v) => {
      this.selectedContent = v;
    });
  }

  autoCheck(){
    setTimeout(() => {
      if (this.isSaveDone){
        this.lc.dismiss();
        this.mc.dismiss();
        this.nav.navigateForward('/pasien');
      } else {
        this.autoCheck();
      }
    }, 100)
  }
}
