import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, AlertController } from '@ionic/angular';
import { Socket } from 'ngx-socket-io';
import { Md5 } from 'ts-md5/dist/md5';
import * as CryptoJS from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AppSettings } from 'src/app/settings/settings';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';
import { CatatandokterPage } from 'src/app/pages/modals/catatandokter/catatandokter.page';
import { prePerawatanResponse } from 'src/app/services/api/api-response';

@Component({
  selector: 'app-popupperawatanpreview',
  templateUrl: './popupperawatanpreview.page.html',
  styleUrls: ['./popupperawatanpreview.page.scss'],
})
export class PopupperawatanpreviewPage implements OnInit {

  totalCatatanDokter = 0;
  previewData: prePerawatanResponse = null;

  notes: any = null;

  alertMode: number = 0;

  listInti: any = null;
  listSE: any = null;

  constructor(
    public auth: AuthService,
    private api: ApiService,
    private ngZone: NgZone,
    private nc: NavController,
    private mc: ModalController,
    private router: Router,
    private ac: AlertController,
    private socket: Socket,
    private iab: InAppBrowser,
  ) { }

  ngOnInit() {
    this.checkToken();
  }

  checkToken() {
    setTimeout(() => {
      if (this.auth.checkingToken) {
        this.checkToken();
        this.auth.firstPage = "/pasien";
      } else {
        this.auth.isLoggedIn().subscribe(res => {
          if (res) {
            this.getTotalCatatanDokter();
            this.getNotes();
            this.getAll();
          } else {
            this.ngZone.run(() => {
              this.nc.navigateBack('/home');
            })
          }
        })
      }
    }, 100);
  }

  ionViewWillEnter() {
    this.socket.on('on-chat', () => {
      this.getTotalCatatanDokter();
    });
  }

  ionViewWillLeave(){

  }

  getAll(){
    this.previewData = null;
    this.api.apiPreviewPerawatan(this.auth.USER_TOKEN).subscribe(res => {
      this.previewData = res;
    });

    let idklinik = this.auth.ACTIVE_PATIENT_KLINIK;
    let idpenjadwalan = this.auth.ACTIVE_PATIENT_PENJADWALAN;
    this.listInti = null;
    this.listSE = null;
    this.api.apiDashboardSEIntiPenjadwalan(idklinik, idpenjadwalan, '', '', this.auth.USER_TOKEN).subscribe(res => {
      if (res != null && res.data != null){
        this.listInti = res.data;
      }
    });

    this.api.apiDashboardSESEPenjadwalan(idklinik, idpenjadwalan, '', '', this.auth.USER_TOKEN).subscribe(res => {
      if (res != null && res.data != null){
        this.listSE = res.data;
      }
    });
  }

  isSEUtama(item){
    if (item != null){
      if (item.harga != "Rp0" && item.jumlah > 0){
        return true;
      }
    }
    return false;
  }

  getSECount(){
    if (this.listSE != null){
      return this.listSE.length;
    }
    return 0;
  }

  getNotes(){
    this.notes = null;
    this.api.apiGetNotesPusat(this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res => {
      this.notes = res;
    });
  }

  getTotalCatatanDokter() {
    this.api.apiCatatanNotReaded(this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res => {
      this.totalCatatanDokter = res.jumlah;
    });
  }

  mulaiPerawatan() {
    this.auth.resetSessionPerawatan();
    this.auth.session_perawatan_data.idpasien = parseInt(this.auth.ACTIVE_PATIENT);
    this.nc.navigateForward('/perawatan-input');
    this.mc.dismiss();
  }

  skipAll() {
    if (this.auth.session_checkup_data.odontogram.skip && this.auth.session_checkup_data.oralexam.skip && this.auth.session_checkup_data.periodontal.skip) {
      this.auth.session_checkup_data.odontogram.skip = false;
      this.auth.session_checkup_data.oralexam.skip = false;
      this.auth.session_checkup_data.periodontal.skip = false;
    } else {
      this.auth.session_checkup_data.odontogram.skip = true;
      this.auth.session_checkup_data.oralexam.skip = true;
      this.auth.session_checkup_data.periodontal.skip = true;
    }
  }

  allowToStart(){
    if ((this.auth.session_checkup_data.odontogram.skip || this.auth.session_checkup_data.odontogram.changed) &&
    (this.auth.session_checkup_data.oralexam.skip || this.auth.session_checkup_data.oralexam.changed) &&
    (this.auth.session_checkup_data.periodontal.skip || this.auth.session_checkup_data.periodontal.changed) &&
    (this.auth.session_checkup_data.lampiran.skip || this.auth.session_checkup_data.lampiran.changed)){
      return true;
    }
    return false;
  }

  async goto(target) {
    this.auth.FROM_PREVIEW = true;

    let title = '';
    this.alertMode = 0;
    if (target == 'dentalrecord'){
      title = 'Odontogram';
      this.alertMode = 1;
    } else if (target == 'oralexam'){
      title = 'Oral Exam';
      this.alertMode = 2;
    } else if (target == 'periodontal'){
      title = 'Periodontal';
      this.alertMode = 3;
    } else if (target == 'lampiranfoto'){
      title = 'Lampiran Foto';
      this.alertMode = 4;
    }

    this.auth.FROM_PREVIEW = false;
    // let alert = await this.ac.create({
    //   header: 'Perbarui ' + title,
    //   message: 'Mohon pastikan kondisi rekam medik telah diperbarui',
    //   buttons: [
    //     {
    //       text: 'Lewati',
    //       handler: () => {
    //         if (this.alertMode == 1){
    //           this.auth.session_checkup_data.odontogram.skip = true;
    //         } else if (this.alertMode == 2){
    //           this.auth.session_checkup_data.oralexam.skip = true;
    //         } else if (this.alertMode == 3){
    //           this.auth.session_checkup_data.periodontal.skip = true;
    //         } else if (this.alertMode == 4){
    //           this.auth.session_checkup_data.lampiran.skip = true;
    //         }
    //       }
    //     },{
    //       text: 'Perbarui',
    //       handler: () => {
    //         this.ngZone.run(() => {
    //           // this.ts.activeTab = this.alertMode == 1 ? 1 : this.alertMode == 2 ? 2 : this.alertMode == 3 ? 3 : 6;
    //           this.router.navigateByUrl('/tabs/' + target);
    //           this.auth.auto_show_popup_perawatan = true;
    //           this.mc.dismiss();
    //         });
    //       }
    //     }
    //   ]
    // });
    // await alert.present();

    this.ngZone.run(() => {
      // this.ts.activeTab = this.alertMode == 1 ? 1 : this.alertMode == 2 ? 2 : this.alertMode == 3 ? 3 : 6;
      this.router.navigateByUrl('/tabs/' + target);
      this.auth.auto_show_popup_perawatan = true;
      this.mc.dismiss();
    });
  }

  async catatanDokter() {
    const isModalOpened = await this.mc.getTop();
    if (isModalOpened) {

    } else {
      let modal = await this.mc.create({
        component: CatatandokterPage,
      });
      await modal.present();
    }
  }

  lihatResumePasien(){
    this.api.apiGetResumeToken(this.auth.USER_TOKEN).subscribe(res => {
      var token = '';
      if (res != null && res.token != null){
        token = res.token;
      }
      var link = AppSettings.getAPIAddress() + '/resume/pasien/' + this.auth.ACTIVE_PATIENT + '?token=' + token;
      let x = this.iab.create(link, '_system', 'location=no');
    });
  }

  openSenyumkoo(){
    const xyz = new Md5();
    let link = '';
    var id = '';
    var secret = '';
    if (AppSettings.isProduction){
        link = 'https://klinikoo.id/senyumkoo/quick-access?';
    } else {
        link = 'http://localhost:8000/senyumkoo/quick-access?';
    }
    var username = this.auth.DOKTER_USERNAME;
    var xid = this.auth.DOKTER_ID;
    const token = xyz.appendStr(this.auth.USER_TOKEN + ':::' + username + ':::' + xid).end();
    const key = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse("BWw2BeLpuRPs7gFMK/CRMg=="));
    id = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse('quick-doc:::'+ key +':::' + token));
    secret = this.auth.USER_TOKEN.split('.')[0];
    link = link + 'id=' + id + '&secret=' + secret + '&xid=' + xid + '&sid=' + this.auth.ACTIVE_PATIENT;
    let x = this.iab.create(link, '_system', 'location=no');
  }
}
