import { Component, OnInit, NgZone } from '@angular/core';
import { ToastController, LoadingController, AlertController, NavController, ModalController } from '@ionic/angular';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { Socket } from 'ngx-socket-io';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';
import { CatatandokterPage } from 'src/app/pages/modals/catatandokter/catatandokter.page';
import { DaftarhargaPage } from 'src/app/pages/perawatan/daftarharga/daftarharga.page';
import { DaftaredukasiPage } from 'src/app/pages/perawatan/daftaredukasi/daftaredukasi.page';
import { PopupperawatanPage } from 'src/app/pages/modals/popupperawatan/popupperawatan.page';
import { PopupttdPage } from 'src/app/pages/modals/popupttd/popupttd.page';
import { PopupnotesperawatanPage } from 'src/app/pages/modals/popupnotesperawatan/popupnotesperawatan.page';
import { AppSettings } from "src/app/settings/settings";

@Component({
  selector: 'app-perawatan',
  templateUrl: './perawatan.page.html',
  styleUrls: ['./perawatan.page.scss']
})
export class PerawatanPage implements OnInit {

  dataPasien = {
    id_pasien: "",
    nama: "",
    tgl_lahir: "",
    jenis_kelamin: "-",
    terakhir_periksa: "-",
    foto: "assets/mask.png",
    umur: "",
  }

  isTTD = false;
  isAgree = false;
  isShowTerm = false;
  isLoadingFoto = true;
  isLoadingFotoLocal = false;
  isLoadingPasien: boolean = true;

  totalCatatanDokter = 0;
  totalRekomendasi = 0;
  rekomendasiPasien: any = null;

  captions: string[];
  rencanaTindakan: any = [
    {
      id: 1,
      text: 'Rencana Tindakan 1',
      harga: 350000,
    }, {
      id: 2,
      text: 'Rencana Tindakan 2',
      harga: 350000,
    }
  ];

  isCheckedin: boolean = false;
  timeCurrent: Date = new Date();
  timeStart: Date = new Date();
  timeEnd: Date = new Date();
  timer: any = null;

  inputDone = [];

  saveAllDone = false;

  constructor(
    public auth: AuthService,
    private toastController: ToastController,
    private ac: AlertController,
    private nc: NavController,
    private mc: ModalController,
    private api: ApiService,
    private zone: NgZone,
    private lc: LoadingController,
    private file: File,
    private socket: Socket,
  ) {
  }

  ngOnInit() {
    this.checkToken();
    this.setupHarga();
  }

  ionViewWillEnter() {
    this.socket.on('on-chat', () => {
      this.getTotalCatatanDokter();
    });

    this.socket.on('on-rekomendasi', () => {
      this.getRekomendasiPasien();
    })
  }

  ionViewWillLeave(){
    clearInterval(this.timer);
  }

  checkToken() {
    setTimeout(() => {
      if (this.auth.checkingToken) {
        this.checkToken();
        this.auth.firstPage = "/perawatan";
      } else {
        this.auth.isLoggedIn().subscribe(res => {
          if (res) {
            this.dataPasien.id_pasien = this.auth.ACTIVE_PATIENT;
            this.getDataPasien();
            this.getTotalCatatanDokter();
            this.getJadwal();
            this.setTimer();
          } else {
            this.zone.run(() => {
              this.nc.navigateBack('/home');
            })
          }
        })
      }
    }, 100);
  }

  getJadwal(){
    this.api.apiCekPasienCheckin(this.auth.DOKTER_KLINIK, this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res => {
      if (res.status) {
        this.isCheckedin = true;

        this.api.apiJadwal12(this.auth.DOKTER_KLINIK, this.auth.USER_TOKEN).subscribe(res => {
          let endOn = new Date();
          endOn.setHours(0, 0, 0, 0);
          // today.setMinutes((item.kode - 1) * 15);
          // today.setMinutes((item.kode) * 15);

          for (let item of res.data) {
            if (item.status == 1){
              // console.log(Math.floor((item.kode * 15) / 60));
              // console.log(Math.floor(((item.kode * 15) % 60)));
              this.timeEnd.setHours(endOn.getHours() + Math.floor((item.kode * 15) / 60), endOn.getMinutes() + ((item.kode * 15) % 60));
              // this.timeEnd.setMinutes((item.kode) * 15);
            }
          }
          // this.timeEnd = endOn;
        })

      } else {
        this.isCheckedin = false;
      }
    });
  }

  setTimer(){
    this.timeStart = new Date(this.auth.session_perawatan_data.timeStart);

    this.timer = setInterval(() => {
      this.timeCurrent.setSeconds(this.timeCurrent.getSeconds() + 1);
    }, 1000)
  }

  getWaktuBerjalan(){
    if (this.timeStart != null){
      let j = '00', m = '00', d = '00';
      let nd = Math.floor((this.timeCurrent.getTime() - this.timeStart.getTime()) / 1000) % 60;
      let nm = Math.floor(((this.timeCurrent.getTime() - this.timeStart.getTime()) / 1000) / 60) % 60;
      let nj = Math.floor(((((this.timeCurrent.getTime() - this.timeStart.getTime()) / 1000) / 60) / 60) % 60);

      j = (nj <= 9) ? '0' + nj : nj + '';
      m = (nm <= 9) ? '0' + nm : nm + '';
      d = (nd <= 9) ? '0' + nd : nd + '';
      return j + ':' + m + ':' + d;
    } else {
      return '00:00:00';
    }
  }

  getWaktuSisa(){
    let j = '00', m = '00', d = '00';
    let timeLeft = this.timeEnd.getTime() - this.timeCurrent.getTime();
    if (this.auth.waktuPemeriksaan != null && this.auth.waktuPemeriksaan > 0){
      timeLeft = this.auth.waktuPemeriksaan;
    }

    if (timeLeft > 0){
      // let nd = Math.floor((this.timeEnd.getTime() - this.timeCurrent.getTime()) / 1000) % 60;
      // let nm = Math.floor(((this.timeEnd.getTime() - this.timeCurrent.getTime()) / 1000) / 60) % 60;
      // let nj = Math.floor(((((this.timeEnd.getTime() - this.timeCurrent.getTime()) / 1000) / 60) / 60) % 60);

      let nd = Math.floor(timeLeft) % 60;
      let nm = Math.floor((timeLeft) / 60) % 60;
      let nj = Math.floor((((timeLeft) / 60) / 60) % 60);

      j = (nj <= 9) ? '0' + nj : nj + '';
      m = (nm <= 9) ? '0' + nm : nm + '';
      d = (nd <= 9) ? '0' + nd : nd + '';
    }
    return j + ':' + m + ':' + d;
  }

  getDataPasien() {
    this.isLoadingPasien = true;
    this.api.apiGetDetailPasien(this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res => {

      this.dataPasien.nama = res.pasien.nama;
      this.dataPasien.tgl_lahir = this.auth.getTanggal(res.pasien.tanggallahir);
      this.dataPasien.umur = res.pasien.umur.toString();

      this.dataPasien.jenis_kelamin = this.auth.getGender(res.pasien.jeniskelamin);

      if (res.pasien.foto != null) {
        this.dataPasien.foto = res.pasien.foto + "?" + this.auth.CURRENT_SESSION_RANDOM;
      }

      this.dataPasien.terakhir_periksa = (res.pasien.t_periksa) ? this.auth.getTanggalv2(res.pasien.t_periksa) : '-';

      this.isLoadingPasien = false;
    })
  }

  getTotalCatatanDokter() {
    this.api.apiCatatanNotReaded(this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res => {
      this.totalCatatanDokter = res.jumlah;
    });
  }

  getRekomendasiPasien(){
    this.api.apiRekomendasiPasien(this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res => {
      if (res != null && res.rekomendasi != null){
        this.rekomendasiPasien = res;
        this.totalRekomendasi = res.rekomendasi.length;
      }
    });
  }

  async openRekomendasi() {
    let list = '';
    if (this.rekomendasiPasien != null) {
      if (this.rekomendasiPasien.rekomendasi != null) {
        if (this.rekomendasiPasien.rekomendasi.length > 0) {
          for (let item of this.rekomendasiPasien.rekomendasi) {
            list += '<p> - ' + item.keterangan + '</p>';
          }
        } else {
          list = '<h4 class="ion-text-center">Tidak ada rekomendasi</h4>'
        }
      }
    }

    const alert = await this.ac.create({
      header: 'Rekomendasi',
      message: list,
      cssClass: 'big-alert',
    })
    await alert.present();
  }

  displayHarga(harga){
    return Number(harga).toLocaleString('id');
  }

  setupHarga(){
    this.rencanaTindakan = [];
    for (let item of this.auth.session_perawatan_data.tindakan){
      this.rencanaTindakan.push({
        id: item.idtindakan,
        text: item.nama,
        harga: item.harga,
      });
    }
  }

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 2000,
    });
    toast.present();
  }

  async finishPerawatan(){
    const loading = await this.lc.create({
      message: 'Memproses data...',
    });
    await loading.present();

    let perawatan_data = {
      idpasien: 0,
      idperawatan: 0,
      ttd: null,
      anamnesa: '',
      klinis: [],
      diagnosa: [],
    };

    let that = this;
    let session_data = this.auth.session_perawatan_data;

    if (session_data.ttd != null && session_data.ttd.path != null){
      try {
        this.file.resolveLocalFilesystemUrl(session_data.ttd.filePath).then(entry => {
          (<FileEntry>entry).file(file => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const imgBlob = new Blob([reader.result], {
                type: file.type,
              });

              this.api.apiPerawatanSaveAll(this.auth.ACTIVE_PATIENT_KLINIK, this.auth.ACTIVE_PATIENT_PENJADWALAN, this.auth.session_perawatan_data, imgBlob, file.name, this.auth.USER_TOKEN).subscribe(async res => {
                if (res.code == '200'){
                  this.auth.session_autodongram = res.autolist;
                  this.auth.session_perawatan_data.idperawatan = res.idperawatan;
                  this.saveAllDone = true;
                } else {
                  let alert = await that.ac.create({
                    header: 'Pemberitahuan',
                    message: 'Gagal menyimpan perawatan',
                  });
                  that.checkFinishing(true);
                  await alert.present();
                }
              });
            }
            reader.readAsArrayBuffer(file);
          });
        });
      } catch (ex){
        let alert = await that.ac.create({
          header: 'Pemberitahuan',
          message: 'Terjadi kesalahan, silahkan coba lagi',
        });
        that.auth.session_perawatan_data.ttd = null;
        that.checkFinishing(true);
        await alert.present();
      }
    } else {
      session_data.ttd = "";
      this.api.apiPerawatanSaveAll(this.auth.ACTIVE_PATIENT_KLINIK, this.auth.ACTIVE_PATIENT_PENJADWALAN, this.auth.session_perawatan_data, null, null, this.auth.USER_TOKEN).subscribe(async res => {
        if (res.code == '200'){
          this.auth.session_autodongram = res.autolist;
          this.auth.session_perawatan_data.idperawatan = res.idperawatan;
          this.saveAllDone = true;
        } else {
          let alert = await that.ac.create({
            header: 'Pemberitahuan',
            message: 'Gagal menyimpan perawatan',
          });
          that.checkFinishing(true);
          await alert.present();
        }
      });
    }
    this.checkFinishing();
  }

  async checkFinishing(error = false){
    if (error == true){
      this.lc.dismiss();
    } else if (error == false){
      setTimeout(async ()=> {
        if (this.saveAllDone){
          this.auth.FINISH_PERAWATAN = true;

          this.auth.session_checkout_data = {
            anamnesa: 'Perawatan sudah dilakukan',
            notes: '',
            idpasien: parseInt(this.auth.ACTIVE_PATIENT),
            idperawatan: this.auth.session_perawatan_data.idperawatan,
            lampiran: {
              changed: this.auth.session_checkup_data.lampiran.changed,
              skip: this.auth.session_checkup_data.lampiran.skip,
            },
            catatan: {
              changed: this.auth.session_checkup_data.catatan.changed,
              skip: this.auth.session_checkup_data.catatan.skip,
            },
            catatandokter: {
              changed: this.auth.session_checkup_data.catatandokter.changed,
              skip: this.auth.session_checkup_data.catatandokter.skip,
            },
            odontogram: {
              changed: false,
              skip: false,
            },
            oralexam: {
              changed: false,
              skip: false,
            },
            periodontal: {
              changed: false,
              skip: false,
            },
            penjadwalan: {
              changed: this.auth.session_checkup_data.penjadwalan.changed,
              skip: this.auth.session_checkup_data.penjadwalan.skip,
            },
          }

          let pd = JSON.parse(JSON.stringify(this.auth.session_perawatan_data));
          this.auth.tmp_perawatan_data = JSON.parse(JSON.stringify(pd));

          this.auth.resetSessionCheckup();
          this.auth.resetSessionPerawatan();
          this.auth.quickPatientInfo.idpasien = parseInt(this.auth.ACTIVE_PATIENT);
          this.auth.quickPatientInfo.message = 'Perawatan telah selesai dilaksanakan';
          this.lc.dismiss();

          if (this.auth.session_autodongram.length > 0){
            this.popupPerawatan('konfirmasi-perubahan');
          } else {
            const isModalOpened = await this.mc.getTop();
            if (isModalOpened) {

            } else {
              setTimeout(() => {
                this.nc.navigateForward('/pasien');
              }, 500);

              const modal = await this.mc.create({
                component: PopupnotesperawatanPage,
                backdropDismiss: false,
                keyboardClose: false,
                componentProps: {
                  mode: 'new',
                  perawatan_data: pd,
                }
              });
              return await modal.present();
            }
            // this.nc.navigateForward('/pasien');
          }
        } else {
          const isLoadingOpen = await this.lc.getTop();
          if (isLoadingOpen) {
            this.checkFinishing();
          } else {

          }
        }
      }, 100);
    }
  }

  getHours(time: Date): string {
    if (time) {
      let s = time.getHours() + ':' + time.getMinutes();
      let splitted = s.split(':');
      let h: string = ((parseInt(splitted[0])) < 10) ? '0' + (parseInt(splitted[0])).toString() : (parseInt(splitted[0])).toString();
      let m: string = (parseInt(splitted[1]) < 10) ? '0' + parseInt(splitted[1]).toString() : parseInt(splitted[1]).toString();
      return h + ':' + m;
    }
    return '00:00';
  }

  async popupPerawatan(action, id = 0) {
    const isModalOpened = await this.mc.getTop();
    if (isModalOpened) {

    } else {
      const modal = await this.mc.create({
        component: PopupperawatanPage,
        componentProps: {
          id: id,
          action: action,
          root: this,
        }
      });
      return await modal.present();
    }
  }

  async popupTTD() {
    this.isTTD = true;

    const isModalOpened = await this.mc.getTop();
    if (isModalOpened) {

    } else {
      const modal = await this.mc.create({
        component: PopupttdPage,
        componentProps: {
          root: this,
        }
      });
      return await modal.present();
    }
  }

  async deleteItem(action, id) {
    const alert = await this.ac.create({
      header: 'Konfirmasi Hapus',
      message: 'Apakah Anda yakin menghapus data ini?',
      buttons: [
        {
          text: 'Tidak',
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
          }
        }, {
          text: 'Ya',
          handler: () => {
            this.ac.dismiss();
          }
        }
      ]
    });
    await alert.present();
  }

  toggleTerm() {
    if (this.isShowTerm == true) {
      this.isShowTerm = false;
    } else {
      this.isShowTerm = true;
    }
  }

  async catatanDokter() {
    let modal = await this.mc.create({
      component: CatatandokterPage,
    });
    await modal.present();
  }

  async goBack() {
    const alert = await this.ac.create({
      header : 'Pemberitahuan',
      message : 'Tindakan ini akan menghentikan perawatan. Lanjutkan?',
      buttons: [
        {
          text: 'Tidak',
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
          }
        }, {
          text: 'Ya',
          handler: () => {
            this.auth.quickPatientInfo.idpasien = this.auth.session_perawatan_data.idpasien;
            this.auth.quickPatientInfo.message = 'Perawatan telah dibatalkan';
            this.auth.resetSessionPerawatan();
            //this.auth.resetSessionCheckup();
            this.zone.run(() => this.nc.navigateBack('/pasien')).then();
          }
        }
      ]
    });
    await alert.present();
  }

  async changeData() {
    const alert = await this.ac.create({
      header: 'Konfirmasi',
      message: 'Apakah Anda yakin ingin mengubah data perawatan?',
      buttons: [
        {
          text: 'Tidak',
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
          }
        }, {
          text: 'Ya',
          handler: () => {
            if (this.auth.session_perawatan_data.ttd != null){
              this.auth.session_perawatan_data.ttd = null;
            }
            this.zone.run(() => this.nc.navigateBack('/perawatan-input')).then();
          }
        }
      ]
    });
    await alert.present();
  }

  async finish() {
    const alert = await this.ac.create({
      header: 'Konfirmasi Perubahan',
      message: 'Apakah Anda mengubah data pemeriksaan secara otomatis?',
      buttons: [
        {
          text: 'Tidak, ubah secara manual',
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
          }
        }, {
          text: 'Ya, ubah secara otomatis',
          handler: () => {
            this.ac.dismiss();
          }
        }
      ]
    });
    await alert.present();
  }

  async showDaftarHarga(){
    const isModalOpened = await this.mc.getTop();
    if (isModalOpened) {

    } else {
      const modal = await this.mc.create({
        component: DaftarhargaPage,
        cssClass: 'daftarHarga',
      });
      return await modal.present();
    }
  }

  async showDaftarEdukasi(){
    const isModalOpened = await this.mc.getTop();
    if (isModalOpened) {

    } else {
      const modal = await this.mc.create({
        component: DaftaredukasiPage,
        cssClass: 'daftarHarga',
      });
      return await modal.present();
    }
  }
}
