import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { IonContent, IonSlides, ModalController, NavParams, LoadingController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PeriodontalPage } from 'src/app/pages/perawatan/periodontal/periodontal.page';
import { mobilityResponse, plaqueResponse, calculusResponse, bleedingResponse } from 'src/app/services/api/api-response';

@Component({
  selector: 'app-popupperiodontal',
  templateUrl: './popupperiodontal.page.html',
  styleUrls: ['./popupperiodontal.page.scss'],
})
export class PopupperiodontalPage implements OnInit {
  @ViewChild('slider') slider: IonSlides;
  @ViewChild(IonContent) content: IonContent;

  sticky = false;
  showFormBuccal = 0;
  showFormLingual = 0;
  isHistoryShown : boolean = false;
  activeTab = 1;
  selectedContent = '1';
  sliderConfig = {
    slidesPerView: 1,
    spaceBetween: 20,
    centeredSlides: true
  };

  //form value
  buccal: {
    recess: any[],
    pocket: any[],
    bleeding: number,
    mobility: number,
    plaque: number,
    calculus: number,
    tanggal: string,
    waktu: string,
  }

  lingual: {
    recess: any[],
    pocket: any[],
    bleeding: number,
    mobility: number,
    plaque: number,
    calculus: number,
    tanggal: string,
    waktu: string,
  }

  realBuccal: {
    recess: any[],
    pocket: any[],
    bleeding: number,
    mobility: number,
    plaque: number,
    calculus: number,
    tanggal: string,
    waktu: string,
  }

  realLingual: {
    recess: any[],
    pocket: any[],
    bleeding: number,
    mobility: number,
    plaque: number,
    calculus: number,
    tanggal: string,
    waktu: string,
  }

  moveBy = 0.4;
  baseY = 53;
  isUpsideDown = true;

  noGigi: number = 0;
  root: PeriodontalPage = null;
  namaGigiTxt = '';

  opsi = {
    dewasa: [
      {
        key: [1, 2, 3, 4, 5, 6, 7, 8],
        value: ['Incisive Central', 'Incisive Central', 'Caninus',
        'Premolar Pertama', 'Premolar Kedua', 'Molar Pertama',
        'Molar Kedua', 'Molar Ketiga'],
      }, {
        key: [1, 2, 3, 4],
        value: ['Maksila Kanan', 'Maksila Kiri', 'Mandibula Kiri', 'Mandibula Kanan'],
      }
    ],
    susu: [
      {
        key: [1, 2, 3, 4, 5],
        value: ['Incisive Central', 'Incisive Central', 'Caninus',
        'Molar Pertama', 'Molar Kedua'],
      }, {
        key: [5, 6, 7, 8],
        value: ['Maksila Kanan', 'Maksila Kiri', 'Mandibula Kiri', 'Mandibula Kanan'],
      }
    ]
  }

  historyItems: any[] = [];
  isHistoryActive: boolean = false;
  listMobility: mobilityResponse = null;
  listPlaque: plaqueResponse = null;
  listCalculus: calculusResponse = null;
  listBleeding: bleedingResponse = null;

  allSaved = {
    buccal: false,
    lingual: false,
  }

  constructor(
    public auth: AuthService,
    private mc: ModalController,
    private lc: LoadingController,
    private api: ApiService,
    private params: NavParams,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.realBuccal = {
      recess: [0,0,0],
      pocket: [0,0,0],
      bleeding: 1,
      mobility: 1,
      plaque: 1,
      calculus: 1,
      tanggal: null,
      waktu: null,
    };
    this.realLingual = {
      recess: [0,0,0],
      pocket: [0,0,0],
      bleeding: 1,
      mobility: 1,
      plaque: 1,
      calculus: 1,
      tanggal: null,
      waktu: null,
    };
    if (this.params.data.root != null){
      this.root = this.params.data.root;
      this.noGigi = this.params.data.noGigi;
      if (this.params.data.tipe == 1){
        this.selectedContent = '1';
      } else if (this.params.data.tipe == 2){
        this.selectedContent = '2';
      }
    }
    this.namaGigiTxt = this.namaGigi(this.noGigi);
    let floor = Math.floor(this.noGigi / 10);
    if (floor == 1 || floor == 2 || floor == 5 || floor == 6){
      this.isUpsideDown = true;
    } else {
      this.isUpsideDown = false;
    }
    //this.showTooth();

    this.getDataPeriodontal(1, true);
    this.getDataPeriodontal(2, true);
    this.getDataHistory();
    this.getListPlaque();
    this.getListCalculus();
    this.getListBleeding();
    this.getListMobility();

    this.renderAllHistory();
  }

  getDataPeriodontal(idpermukaan, sync){
    this.api.apiPeriodontalSingle(this.auth.ACTIVE_PATIENT, idpermukaan, this.noGigi, this.auth.USER_TOKEN).subscribe(res => {
      if (res.data != null){
        let data = res.data;
        if (idpermukaan.toString() == '1'){
          this.realBuccal = {
            recess: [data.recess_1, data.recess_2, data.recess_3],
            pocket: [data.pocket_1, data.pocket_2, data.pocket_3],
            bleeding: data.idbleeding,
            mobility: data.idmobility,
            plaque: data.idplaque,
            calculus: data.idcalculus,
            tanggal: data.tanggal,
            waktu: data.waktu,
          };
        } else if (idpermukaan.toString() == '2'){
          this.realLingual = {
            recess: [data.recess_1, data.recess_2, data.recess_3],
            pocket: [data.pocket_1, data.pocket_2, data.pocket_3],
            bleeding: data.idbleeding,
            mobility: data.idmobility,
            plaque: data.idplaque,
            calculus: data.idcalculus,
            tanggal: data.tanggal,
            waktu: data.waktu,
          };
        }
      } else {
        if (idpermukaan.toString() == '1'){
          this.realBuccal = {
            recess: [0,0,0],
            pocket: [0,0,0],
            bleeding: 1,
            mobility: 1,
            plaque: 1,
            calculus: 1,
            tanggal: null,
            waktu: null,
          };
        } else if (idpermukaan.toString() == '2'){
          this.realLingual = {
            recess: [0,0,0],
            pocket: [0,0,0],
            bleeding: 1,
            mobility: 1,
            plaque: 1,
            calculus: 1,
            tanggal: null,
            waktu: null,
          };
        }
      }
      if (sync){
        this.syncReal();
        this.reRender(false);
      }
    }, error => {
      if (idpermukaan.toString() == '1'){
        this.realBuccal = {
          recess: [0,0,0],
          pocket: [0,0,0],
          bleeding: 1,
          mobility: 1,
          plaque: 1,
          calculus: 1,
          tanggal: null,
          waktu: null,
        };
      } else if (idpermukaan.toString() == '2'){
        this.realLingual = {
          recess: [0,0,0],
          pocket: [0,0,0],
          bleeding: 1,
          mobility: 1,
          plaque: 1,
          calculus: 1,
          tanggal: null,
          waktu: null,
        };
      }
      if (sync){
        this.syncReal();
        this.reRender(false);
      }
    });
  }

  getDataHistory(){
    for (let i = 1; i <= 2; i++){
      this.api.apiPeriodontalHistory(this.auth.ACTIVE_PATIENT, i, this.noGigi, this.auth.USER_TOKEN).subscribe(res => {
        for (let j = 0; j < res.data.length; j++){
          let item = res.data[j];
          this.historyItems.push({
            id: j + 1,
            tipe: i,
            recess: [item.recess_1, item.recess_2, item.recess_3],
            pocket: [item.pocket_1, item.pocket_2, item.pocket_3],
            bleeding: item.idbleeding,
            mobility: item.idmobility,
            plaque: item.idplaque,
            calculus: item.idcalculus,
            tanggal: item.tanggal,
          });
        }
      });
    }
  }

  getListCalculus(){
    this.api.apiListCalculus(this.auth.USER_TOKEN).subscribe(res => {
      this.listCalculus = res;
    });
  }

  getListPlaque(){
    this.api.apiListPlaque(this.auth.USER_TOKEN).subscribe(res => {
      this.listPlaque = res;
    });
  }

  getListBleeding(){
    this.api.apiListBleeding(this.auth.USER_TOKEN).subscribe(res => {
      this.listBleeding = res;
    });
  }

  getListMobility(){
    this.api.apiListMobility(this.auth.USER_TOKEN).subscribe(res => {
      this.listMobility = res;
    });
  }

  getCalculusText(no: number){
    let result = '';
    if (this.listCalculus != null){
      let found = this.listCalculus.data.find(item => item.idcalculus == no);
      if (found){
        return found.keterangan;
      } else {
        return 'Tidak Ada';
      }
    }
    return result;
  }

  getPlaqueText(no: number){
    let result = '';
    if (this.listPlaque != null){
      let found = this.listPlaque.data.find(item => item.idplaque == no);
      if (found){
        return found.keterangan;
      } else {
        return 'Tidak Ada';
      }
    }
    return result;
  }

  getBleedingText(no: number){
    let result = '';
    if (this.listBleeding != null){
      let found = this.listBleeding.data.find(item => item.idbleeding == no);
      if (found){
        return found.keterangan;
      }
    }
    return result;
  }

  getMobilityText(no: number){
    let result = '';
    if (this.listMobility != null){
      let found = this.listMobility.data.find(item => item.idmobility == no);
      if (found){
        return found.keterangan;
      }
    }
    return result;
  }

  closeIfSaved(){
    setTimeout(()=> {
      if (this.allSaved.buccal == true && this.allSaved.lingual == true){
        this.root.getDataPeriodontal();
        this.lc.dismiss();
        this.mc.dismiss();
      } else {
        this.closeIfSaved();
      }
    }, 100)
  }

  async batchsavePeriodontal(){
    if (this.showFormBuccal && this.showFormLingual){
      const loading = await this.lc.create({
        message: "Menyimpan data...",
      })
      await loading.present();
      this.savePeriodontal();
      setTimeout(()=> {
        let val = (this.selectedContent == '1') ? 2 : 1;
        this.switchContent(val);
        this.savePeriodontal();
        this.closeIfSaved();
      }, 1000);
    }
    this.toggleFormBuccal();//or lingual doesnt matter
  }

  savePeriodontal(){
    if (this.selectedContent == '1'){
      if (this.showFormBuccal){
        console.log(this.buccal);
        this.api.apiPeriodontalSave(this.buccal, this.auth.ACTIVE_PATIENT, this.noGigi, this.selectedContent, this.auth.USER_TOKEN).subscribe(res => {
          if (res.response == 'Success'){
            this.auth.showToast(res.message);
          }
          this.allSaved.buccal = true;
          this.auth.session_checkup_data.periodontal.changed = true;
        });
      }
      this.toggleFormBuccal();
    } else if (this.selectedContent == '2'){
      if (this.showFormLingual){
        this.api.apiPeriodontalSave(this.lingual, this.auth.ACTIVE_PATIENT, this.noGigi, this.selectedContent, this.auth.USER_TOKEN).subscribe(res => {
          if (res.response == 'Success'){
            this.auth.showToast(res.message);
          }
          this.allSaved.lingual = true;
          this.auth.session_checkup_data.periodontal.changed = true;
        });
      }
      this.toggleFormLingual();
    }
  }

  showTooth(){
    let toSet = 'buccal';
    if (this.selectedContent == '1'){
      toSet = 'buccal';
    } else if (this.selectedContent == '2'){
      toSet = 'lingual';
    }
    let gigiPreview = document.querySelector('.gigi-preview');
    if (gigiPreview != null){
      let elements = gigiPreview.querySelectorAll('.' + toSet);
      for (let i = 0; i < elements.length; i++){
        let item = elements[i];
        item.classList.remove('show');
      }

      let gigi = gigiPreview.querySelector('.gigi' + this.noGigi);
      if (gigi){
        gigi.classList.add('show');
      }
    } else {
      setTimeout(()=>{
        //this.showTooth();
      }, 100);
    }
  }

  reRender(sync: boolean){
    if (sync){
      this.syncReal();
    }
    this.ngZone.run(()=> {
      if (this.selectedContent == '1'){
        //buccal
        let buccalRecess: any[] = [];
        buccalRecess[0] = document.getElementById('buccal_recess_0');
        buccalRecess[1] = document.getElementById('buccal_recess_1');
        buccalRecess[2] = document.getElementById('buccal_recess_2');

        let buccalPocket: any[] = [];
        buccalPocket[0] = document.getElementById('buccal_pocket_0');
        buccalPocket[1] = document.getElementById('buccal_pocket_1');
        buccalPocket[2] = document.getElementById('buccal_pocket_2');

        let i = 0;
        for (let item of buccalRecess){
          if (item != null){
            this.buccal.recess[i] = item.value;
            i++;
          }
        }
        i = 0;
        for (let item of buccalPocket){
          if (item != null){
            this.buccal.pocket[i] = item.value;
            i++;
          }
        }

        let main = document.getElementById('buccal');
        if (main != null){
          let recess = main.querySelector('.recess');
          let pocket = main.querySelector('.pocket');

          let recessPoints: any[] = [];
          recessPoints[0] = recess.querySelector('.point1');
          recessPoints[1] = recess.querySelector('.point2');
          recessPoints[2] = recess.querySelector('.point3');

          let recessLines: any[] = [];
          recessLines[0] = recess.querySelector('.line12');
          recessLines[1] = recess.querySelector('.line23');

          let pocketPoints: any[] = [];
          pocketPoints[0] = pocket.querySelector('.point1');
          pocketPoints[1] = pocket.querySelector('.point2');
          pocketPoints[2] = pocket.querySelector('.point3');

          let pocketLines: any[] = [];
          pocketLines[0] = pocket.querySelector('.line12');
          pocketLines[1] = pocket.querySelector('.line23');

          let j = 0;
          for(let item of recessPoints){
            if (this.isUpsideDown){
              item.cy.baseVal.value = 53 - (this.buccal.recess[j] * this.moveBy);
            } else {
              item.cy.baseVal.value = 53 + (this.buccal.recess[j] * this.moveBy);
            }
            j++;
          }

          j = 0;
          for(let item of recessLines){
            if (this.isUpsideDown){
              item.y1.baseVal.value = 53 - (this.buccal.recess[j] * this.moveBy);
              item.y2.baseVal.value = 53 - (this.buccal.recess[j + 1] * this.moveBy);
            } else {
              item.y1.baseVal.value = 53 + (this.buccal.recess[j] * this.moveBy);
              item.y2.baseVal.value = 53 + (this.buccal.recess[j + 1] * this.moveBy);
            }
            j++;
          }

          j = 0;
          for(let item of pocketPoints){
            if (this.isUpsideDown){
              item.cy.baseVal.value = 53 - ((this.buccal.pocket[j] + this.buccal.recess[j]) * this.moveBy);
            } else {
              item.cy.baseVal.value = 53 + ((this.buccal.pocket[j] + this.buccal.recess[j]) * this.moveBy);
            }
            j++;
          }

          j = 0;
          for(let item of pocketLines){
            if (this.isUpsideDown){
              item.y1.baseVal.value = 53 - ((this.buccal.pocket[j] + this.buccal.recess[j]) * this.moveBy);
              item.y2.baseVal.value = 53 - ((this.buccal.pocket[j + 1] + this.buccal.recess[j + 1]) * this.moveBy);
            } else {
              item.y1.baseVal.value = 53 + ((this.buccal.pocket[j] + this.buccal.recess[j]) * this.moveBy);
              item.y2.baseVal.value = 53 + ((this.buccal.pocket[j + 1] + this.buccal.recess[j + 1]) * this.moveBy);
            }
            j++;
          }
        } else {
          setTimeout(()=> {
            this.reRender(sync);
          }, 100);
        }
      } else if (this.selectedContent == '2'){
        //lingual
        let lingualRecess: any[] = [];
        lingualRecess[0] = document.getElementById('lingual_recess_0');
        lingualRecess[1] = document.getElementById('lingual_recess_1');
        lingualRecess[2] = document.getElementById('lingual_recess_2');

        let lingualPocket: any[] = [];
        lingualPocket[0] = document.getElementById('lingual_pocket_0');
        lingualPocket[1] = document.getElementById('lingual_pocket_1');
        lingualPocket[2] = document.getElementById('lingual_pocket_2');

        let i = 0;
        for (let item of lingualRecess){
          if (item != null){
            this.lingual.recess[i] = item.value;
            i++;
          }
        }
        i = 0;
        for (let item of lingualPocket){
          if (item != null){
            this.lingual.pocket[i] = item.value;
            i++;
          }
        }

        let main = document.getElementById('lingual');

        if (main != null){
          let recess = main.querySelector('.recess');
          let pocket = main.querySelector('.pocket');

          let recessPoints: any[] = [];
          recessPoints[0] = recess.querySelector('.point1');
          recessPoints[1] = recess.querySelector('.point2');
          recessPoints[2] = recess.querySelector('.point3');

          let recessLines: any[] = [];
          recessLines[0] = recess.querySelector('.line12');
          recessLines[1] = recess.querySelector('.line23');

          let pocketPoints: any[] = [];
          pocketPoints[0] = pocket.querySelector('.point1');
          pocketPoints[1] = pocket.querySelector('.point2');
          pocketPoints[2] = pocket.querySelector('.point3');

          let pocketLines: any[] = [];
          pocketLines[0] = pocket.querySelector('.line12');
          pocketLines[1] = pocket.querySelector('.line23');

          let j = 0;
          for(let item of recessPoints){
            if (this.isUpsideDown){
              item.cy.baseVal.value = 53 - (this.lingual.recess[j] * this.moveBy);
            } else {
              item.cy.baseVal.value = 53 + (this.lingual.recess[j] * this.moveBy);
            }
            j++;
          }

          j = 0;
          for(let item of recessLines){
            if (this.isUpsideDown){
              item.y1.baseVal.value = 53 - (this.lingual.recess[j] * this.moveBy);
              item.y2.baseVal.value = 53 - (this.lingual.recess[j + 1] * this.moveBy);
            } else {
              item.y1.baseVal.value = 53 + (this.lingual.recess[j] * this.moveBy);
              item.y2.baseVal.value = 53 + (this.lingual.recess[j + 1] * this.moveBy);
            }
            j++;
          }

          j = 0;
          for(let item of pocketPoints){
            if (this.isUpsideDown){
              item.cy.baseVal.value = 53 - ((this.lingual.pocket[j] + this.lingual.recess[j]) * this.moveBy);
            } else {
              item.cy.baseVal.value = 53 + ((this.lingual.pocket[j] + this.lingual.recess[j]) * this.moveBy);
            }
            j++;
          }

          j = 0;
          for(let item of pocketLines){
            if (this.isUpsideDown){
              item.y1.baseVal.value = 53 - ((this.lingual.pocket[j] + this.lingual.recess[j]) * this.moveBy);
              item.y2.baseVal.value = 53 - ((this.lingual.pocket[j + 1] + this.lingual.recess[j + 1]) * this.moveBy);
            } else {
              item.y1.baseVal.value = 53 + ((this.lingual.pocket[j] + this.lingual.recess[j]) * this.moveBy);
              item.y2.baseVal.value = 53 + ((this.lingual.pocket[j + 1] + this.lingual.recess[j + 1]) * this.moveBy);
            }
            j++;
          }
        } else {
          setTimeout(()=> {
            this.reRender(sync);
          }, 100);
        }
      }
    });
  }

  renderAllHistory(){
    for (let item of this.historyItems){
      this.renderHistory(item);
    }
  }

  renderHistory(historyItem: any){
    this.ngZone.run(()=> {
      if (historyItem != null){
        let main = document.getElementById('history_item_' + historyItem.id);
        if (main != null){

          let recess = main.querySelector('.recess');
          let pocket = main.querySelector('.pocket');

          let recessPoints: any[] = [];
          recessPoints[0] = recess.querySelector('.point1');
          recessPoints[1] = recess.querySelector('.point2');
          recessPoints[2] = recess.querySelector('.point3');

          let recessLines: any[] = [];
          recessLines[0] = recess.querySelector('.line12');
          recessLines[1] = recess.querySelector('.line23');

          let pocketPoints: any[] = [];
          pocketPoints[0] = pocket.querySelector('.point1');
          pocketPoints[1] = pocket.querySelector('.point2');
          pocketPoints[2] = pocket.querySelector('.point3');

          let pocketLines: any[] = [];
          pocketLines[0] = pocket.querySelector('.line12');
          pocketLines[1] = pocket.querySelector('.line23');

          let j = 0;
          for(let item of recessPoints){
            if (this.isUpsideDown){
              item.cy.baseVal.value = 53 - (historyItem.recess[j] * this.moveBy);
            } else {
              item.cy.baseVal.value = 53 + (historyItem.recess[j] * this.moveBy);
            }
            j++;
          }

          j = 0;
          for(let item of recessLines){
            if (this.isUpsideDown){
              item.y1.baseVal.value = 53 - (historyItem.recess[j] * this.moveBy);
              item.y2.baseVal.value = 53 - (historyItem.recess[j + 1] * this.moveBy);
            } else {
              item.y1.baseVal.value = 53 + (historyItem.recess[j] * this.moveBy);
              item.y2.baseVal.value = 53 + (historyItem.recess[j + 1] * this.moveBy);
            }
            j++;
          }

          j = 0;
          for(let item of pocketPoints){
            if (this.isUpsideDown){
              item.cy.baseVal.value = 53 - ((historyItem.pocket[j] + historyItem.recess[j]) * this.moveBy);
            } else {
              item.cy.baseVal.value = 53 + ((historyItem.pocket[j] + historyItem.recess[j]) * this.moveBy);
            }
            j++;
          }

          j = 0;
          for(let item of pocketLines){
            if (this.isUpsideDown){
              item.y1.baseVal.value = 53 - ((historyItem.pocket[j] + historyItem.recess[j]) * this.moveBy);
              item.y2.baseVal.value = 53 - ((historyItem.pocket[j + 1] + historyItem.recess[j + 1]) * this.moveBy);
            } else {
              item.y1.baseVal.value = 53 + ((historyItem.pocket[j] + historyItem.recess[j]) * this.moveBy);
              item.y2.baseVal.value = 53 + ((historyItem.pocket[j + 1] + historyItem.recess[j + 1]) * this.moveBy);
            }
            j++;
          }
        } else {
          setTimeout(()=> {
            this.renderHistory(historyItem)
          }, 100);
        }
      }
    });
  }

  openHistory(historyItem: any){
    this.isHistoryActive = true;
    if (historyItem.tipe == 1){
      this.buccal.recess = historyItem.recess;
      this.buccal.pocket = historyItem.pocket;
      this.buccal.bleeding = historyItem.bleeding;
      this.buccal.mobility = historyItem.mobility;
      this.buccal.plaque = historyItem.plaque;
      this.buccal.calculus = historyItem.calculus;
    } else if (historyItem.tipe == 2){
      this.lingual.recess = historyItem.recess;
      this.lingual.pocket = historyItem.pocket;
      this.lingual.bleeding = historyItem.bleeding;
      this.lingual.mobility = historyItem.mobility;
      this.lingual.plaque = historyItem.plaque;
      this.lingual.calculus = historyItem.calculus;
    }
    this.ngZone.run(()=> {
      if (this.selectedContent == '1'){
        //buccal
        let main = document.getElementById('buccal');
        let recess = main.querySelector('.recess');
        let pocket = main.querySelector('.pocket');

        let recessPoints: any[] = [];
        recessPoints[0] = recess.querySelector('.point1');
        recessPoints[1] = recess.querySelector('.point2');
        recessPoints[2] = recess.querySelector('.point3');

        let recessLines: any[] = [];
        recessLines[0] = recess.querySelector('.line12');
        recessLines[1] = recess.querySelector('.line23');

        let pocketPoints: any[] = [];
        pocketPoints[0] = pocket.querySelector('.point1');
        pocketPoints[1] = pocket.querySelector('.point2');
        pocketPoints[2] = pocket.querySelector('.point3');

        let pocketLines: any[] = [];
        pocketLines[0] = pocket.querySelector('.line12');
        pocketLines[1] = pocket.querySelector('.line23');

        let j = 0;
        for(let item of recessPoints){
          if (this.isUpsideDown){
            item.cy.baseVal.value = 53 - (this.buccal.recess[j] * this.moveBy);
          } else {
            item.cy.baseVal.value = 53 + (this.buccal.recess[j] * this.moveBy);
          }
          j++;
        }

        j = 0;
        for(let item of recessLines){
          if (this.isUpsideDown){
            item.y1.baseVal.value = 53 - (this.buccal.recess[j] * this.moveBy);
            item.y2.baseVal.value = 53 - (this.buccal.recess[j + 1] * this.moveBy);
          } else {
            item.y1.baseVal.value = 53 + (this.buccal.recess[j] * this.moveBy);
            item.y2.baseVal.value = 53 + (this.buccal.recess[j + 1] * this.moveBy);
          }
          j++;
        }

        j = 0;
        for(let item of pocketPoints){
          if (this.isUpsideDown){
            item.cy.baseVal.value = 53 - ((this.buccal.pocket[j] + this.buccal.recess[j]) * this.moveBy);
          } else {
            item.cy.baseVal.value = 53 + ((this.buccal.pocket[j] + this.buccal.recess[j]) * this.moveBy);
          }
          j++;
        }

        j = 0;
        for(let item of pocketLines){
          if (this.isUpsideDown){
            item.y1.baseVal.value = 53 - ((this.buccal.pocket[j] + this.buccal.recess[j]) * this.moveBy);
            item.y2.baseVal.value = 53 - ((this.buccal.pocket[j + 1] + this.buccal.recess[j + 1]) * this.moveBy);
          } else {
            item.y1.baseVal.value = 53 + ((this.buccal.pocket[j] + this.buccal.recess[j]) * this.moveBy);
            item.y2.baseVal.value = 53 + ((this.buccal.pocket[j + 1] + this.buccal.recess[j + 1]) * this.moveBy);
          }
          j++;
        }

      } else if (this.selectedContent == '2'){
        //lingual

        let main = document.getElementById('lingual');
        let recess = main.querySelector('.recess');
        let pocket = main.querySelector('.pocket');

        let recessPoints: any[] = [];
        recessPoints[0] = recess.querySelector('.point1');
        recessPoints[1] = recess.querySelector('.point2');
        recessPoints[2] = recess.querySelector('.point3');

        let recessLines: any[] = [];
        recessLines[0] = recess.querySelector('.line12');
        recessLines[1] = recess.querySelector('.line23');

        let pocketPoints: any[] = [];
        pocketPoints[0] = pocket.querySelector('.point1');
        pocketPoints[1] = pocket.querySelector('.point2');
        pocketPoints[2] = pocket.querySelector('.point3');

        let pocketLines: any[] = [];
        pocketLines[0] = pocket.querySelector('.line12');
        pocketLines[1] = pocket.querySelector('.line23');

        let j = 0;
        for(let item of recessPoints){
          if (this.isUpsideDown){
            item.cy.baseVal.value = 53 - (this.lingual.recess[j] * this.moveBy);
          } else {
            item.cy.baseVal.value = 53 + (this.lingual.recess[j] * this.moveBy);
          }
          j++;
        }

        j = 0;
        for(let item of recessLines){
          if (this.isUpsideDown){
            item.y1.baseVal.value = 53 - (this.lingual.recess[j] * this.moveBy);
            item.y2.baseVal.value = 53 - (this.lingual.recess[j + 1] * this.moveBy);
          } else {
            item.y1.baseVal.value = 53 + (this.lingual.recess[j] * this.moveBy);
            item.y2.baseVal.value = 53 + (this.lingual.recess[j + 1] * this.moveBy);
          }
          j++;
        }

        j = 0;
        for(let item of pocketPoints){
          if (this.isUpsideDown){
            item.cy.baseVal.value = 53 - ((this.lingual.pocket[j] + this.lingual.recess[j]) * this.moveBy);
          } else {
            item.cy.baseVal.value = 53 + ((this.lingual.pocket[j] + this.lingual.recess[j]) * this.moveBy);
          }
          j++;
        }

        j = 0;
        for(let item of pocketLines){
          if (this.isUpsideDown){
            item.y1.baseVal.value = 53 - ((this.lingual.pocket[j] + this.lingual.recess[j]) * this.moveBy);
            item.y2.baseVal.value = 53 - ((this.lingual.pocket[j + 1] + this.lingual.recess[j + 1]) * this.moveBy);
          } else {
            item.y1.baseVal.value = 53 + ((this.lingual.pocket[j] + this.lingual.recess[j]) * this.moveBy);
            item.y2.baseVal.value = 53 + ((this.lingual.pocket[j + 1] + this.lingual.recess[j + 1]) * this.moveBy);
          }
          j++;
        }
      }
    });
  }

  syncReal(){
    if (this.showFormBuccal || this.showFormLingual){
      if (this.buccal == null){
        this.buccal = JSON.parse(JSON.stringify(this.realBuccal));
      }
      if (this.lingual == null){
        this.lingual = JSON.parse(JSON.stringify(this.realLingual));
      }
    } else {
      if (this.selectedContent == '1'){
        this.buccal = JSON.parse(JSON.stringify(this.realBuccal));
      } else {
        this.lingual = JSON.parse(JSON.stringify(this.realLingual));
      }
    }
  }

  closeHistory(){
    this.isHistoryActive = false;
    this.syncReal();
    this.reRender(true);
    this.renderAllHistory();
  }

  switchContent(item){
    this.activeTab = item;
    this.selectedContent = item + '';

    setTimeout(()=> {
      this.reRender(false);
      this.renderAllHistory();
    }, 100);

    if (this.isHistoryActive){
      this.isHistoryActive = false;
      //this.syncReal();
    }
  }

  segmentChanged(e) {
    if (e.detail.value == '1') {
      //this.slider.slideTo(0);
      this.activeTab = 1;
    } else {
      //this.slider.slideTo(1);
      this.activeTab = 2;
    }
    this.syncReal();

    setTimeout(()=> {
      this.reRender(false);
      this.renderAllHistory();
    }, 100);

    if (this.isHistoryActive){
      this.isHistoryActive = false;
      this.syncReal();
    }
  }

  toggleFormBuccal() {
    if (this.showFormBuccal == 1) {
      this.showFormBuccal = 0;
      this.showFormLingual = 0;
    } else {
      this.showFormBuccal = 1;
      this.showFormLingual = 1;
    }
    setTimeout(()=> {
      this.reRender(true);
    }, 100);
    this.syncReal();
  }

  toggleFormLingual() {
    if (this.showFormLingual == 1) {
      this.showFormLingual = 0;
      this.showFormBuccal = 0;
    } else {
      this.showFormLingual = 1;
      this.showFormBuccal = 1;
    }
    setTimeout(()=> {
      this.reRender(true);
    }, 100);
    this.syncReal();
  }

  cancelSave(){
    this.toggleFormBuccal();//or lingual doesnt matter
  }

  logScrolling(e) {
    const delta = e.detail.deltaY;
    if (delta > 20) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  namaGigi(x: number){
    let result = '';
    if (x < 50){
      result = 'Gigi Dewasa ';
      result += this.opsi.dewasa[0].value[x % 10 - 1] + ' ' + this.opsi.dewasa[1].value[Math.floor(x / 10) - 1];
    } else {
      result = 'Gigi Susu ';
      result += this.opsi.susu[0].value[x % 10 - 1] + ' ';
      let active = 0;
      let i = 0;
      for (let item of this.opsi.susu[1].key){
        if (Math.floor(x / 10) == item){
          active = i;
        }
        i++;
      }
      result += this.opsi.susu[1].value[active];
    }
    return result;
  }

  historyCount(tipe){
    let count = 0;
    if (this.historyItems.length > 0){
      for (let item of this.historyItems){
        if (item.tipe == tipe){
          count++;
        }
      }
    }
    return count;
  }

  toggleHistory(){
    this.isHistoryShown = !this.isHistoryShown;
    if (this.isHistoryShown){
      this.renderAllHistory();
    }
  }
}
