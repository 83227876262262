// A class that store a lot of common functions and authentication related variables & functions
// Used in most pages with public modifier

import { Injectable, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { AlertController, ToastController, LoadingController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';

import { AppSettings } from 'src/app/settings/settings';
import { ApiService } from 'src/app/services/api/api.service';

interface PemeriksaanKlinis {
  id: number,
  idstatus: number,
  idgigi: number[],
  text: string,
  iscustom: boolean,
}

interface Diagnosa {
  idpk: number,
  id: number,
  text: string,
  satusehat: string,
  pcare: string,
  iscustom: boolean,
}

interface Prognosa {
  idpk: number,
  idd: number,
  id: string,
  nama: string,
  keterangan: string,
}

interface Perawatan {
  idpk: number,
  id: number,
  text: string,
  iscustom: boolean,
}

interface PerawatanICD {
  idpk: number,
  id: number,
  code: string,
  desc: string,
  iscustom: boolean,
}

interface Tindakan {
  idp: number,
  idpk: number,
  idkelompok: number,
  idtindakan: number,
  nama: string,
  harga: number,
  bagihasil: number,
  iscustom: boolean,
  kode: string,
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  AUTH_SERVER_ADDRESS: string = "http://apidokter.cheerful.id";
  USER_TOKEN: string = "";
  API_KEY: string = "12345678";
  ACTIVE_PATIENT: string = "1";
  ACTIVE_PATIENT_PENJADWALAN: string = "0";
  ACTIVE_PATIENT_KLINIK: string = "0";
  ACTIVE_CODE: string = "PASSCODE";
  CURRENT_SESSION_RANDOM: number = 0;
  DOKTER_ID: string = "";
  DOKTER_USERNAME: string = "";
  DOKTER_PASSWORD: string = "";
  DOKTER_PENEMPATAN: string = "";
  DOKTER_KLINIK: string = "0";
  DOKTER_NAMA: string = "";
  KLINIK_LINK: string = "";

  IS_DUMMY_MODE: boolean = false;
  FINISH_PERAWATAN: boolean = false;

  FROM_PREVIEW: boolean = false;

  ACTIVE_KONSUL_ROOM: string = "";
  ACTIVE_KONSUL_IDKLINIK: string = "";
  ACTIVE_KONSUL_IDPENJADWALAN: string = "";

  authSubject = new BehaviorSubject(false);
  user = {
    id_dokter: "",
    username: "",
    password: "",
  }

  months = ["Januari", "Februari", "Maret",
    "April", "Mei", "Juni", "Juli", "Agustus",
    "September", "Oktober", "November", "Desember"];

  shortMonths = ["Jan", "Feb", "Mar",
    "Apr", "Mei", "Jun", "Jul", "Agu",
    "Sep", "Okt", "Nov", "Des"];

  checkingToken = false;
  firstPage: string = "/dentist";
  activeTabs: number = 1;
  tabsGoto: number = 1;

  dummyUser = {
    username: 'training',
    password: 'training',
  }

  is_changed = {
    odontogram: false,
    oral_exam: false,
    lampiran: false,
    catatan: false,
    penjadwalan: false,
    periodontal: false,
    catatan_dokter: false,
    skip_odontogram: false,
    perawatan: '',
  }

  auto_show_popup_perawatan = false;
  auto_show_popup_checkout = false;
  show_popup_tutorial = true;
  in_checkout_process = false;

  session_checkup_data = {
    lampiran: {
      changed: false,
      skip: false,
    },
    catatan: {
      changed: false,
      skip: false,
    },
    catatandokter: {
      changed: false,
      skip: false,
    },
    odontogram: {
      changed: false,
      skip: false,
    },
    oralexam: {
      changed: false,
      skip: false,
    },
    periodontal: {
      changed: false,
      skip: false,
    },
    penjadwalan: {
      changed: false,
      skip: false,
    },
  }

  session_checkout_data = {
    anamnesa : '',
    notes : '',
    idpasien: 0,
    idperawatan: 0,
    lampiran: {
      changed: false,
      skip: false,
    },
    catatan: {
      changed: false,
      skip: false,
    },
    catatandokter: {
      changed: false,
      skip: false,
    },
    odontogram: {
      changed: false,
      skip: false,
    },
    oralexam: {
      changed: false,
      skip: false,
    },
    periodontal: {
      changed: false,
      skip: false,
    },
    penjadwalan: {
      changed: false,
      skip: false,
    },
  }

  session_perawatan_data: {
    idpasien: number,
    idperawatan: number,
    anamnesa: string,
    pemeriksaan_klinis: PemeriksaanKlinis[],
    diagnosa: Diagnosa[],
    prognosa: Prognosa[],
    perawatan: Perawatan[],
    icd: PerawatanICD[],
    tindakan: Tindakan[],
    ttd: any,
    timeStart: Date,
  } = {
    idpasien: 0,
    idperawatan: 0,
    anamnesa: '',
    pemeriksaan_klinis: [],
    diagnosa: [],
    prognosa: [],
    perawatan: [],
    icd: [],
    tindakan: [],
    ttd: null,
    timeStart: new Date(),
  }

  session_autodongram : any[] = null;

  quickPatientInfo = {
    idpasien: 0,
    message: '',
  }

  retried: boolean = false;
  isRetriesAvailable: boolean = true;

  timerPemeriksaan: any = null;
  timerOTimer: any = null;
  waktuPemeriksaan: any = null;

  tmp_perawatan_data: any = null;
  extra_perawatan_data: {
    notes: string,
    ttd: any,
  } = {
    notes: '',
    ttd: null,
  }

  allowModifyData: boolean = true;
  allowAnyway: boolean = true;

  isCompact: boolean = false; //Not a global app compact setting, its a state whether the dentist account is verified for compact version
  tempCheckinLink: string = '';

  fromSE: boolean = false;
  SEfrom: string = '';

  checkReportTimer: any = null;
  activeReportTotal: number = 0;
  checkChatTimer: any = null;
  activeChatTotal: number = 0;

  //lite update
  isAccountLite: boolean = true;

  //free update
  isAccountFree: boolean = true;

  isFromMulaiPerawatan: boolean = false;

  session_observasi: any = null;

  currentActClass: any[] = [];
  currentZoneActClass: any[] = [];

  tmpTTDUrl: string = '';
  tmpCatatanGambarBlob: any = null;
  tmpCatatanGambar: any = null;
  tmpTTDFinalBlob: any = null;
  tmpConsent = [];

  constructor(
    private storage: Storage,
    private alertCon: AlertController,
    private lc: LoadingController,
    private tc: ToastController,
    private api: ApiService,
    private router: Router,
    private nc: NavController,
    private zone: NgZone,
  ) {

  }

  setTimerByApi(){
    this.api.apiCekPasienCheckin(this.DOKTER_KLINIK, this.ACTIVE_PATIENT, this.USER_TOKEN).subscribe(res => {
      if (res.status) {
        this.api.apiJadwal12(this.DOKTER_KLINIK, this.USER_TOKEN).subscribe(res => {
          let endOn = new Date();
          endOn.setHours(0, 0, 0, 0);

          let tend = new Date();
          for (let item of res.data) {
            if (item.status == 1){
              tend.setHours(endOn.getHours() + Math.floor((item.kode * 15) / 60), endOn.getMinutes() + ((item.kode * 15) % 60));
            }
          }
          this.setTimer();
        });
      }
    });
  }

  setTimer(){
    // this.waktuPemeriksaan = {
    //   timeCurrent: new Date(),
    //   timeEnd: timeEnd,
    // }

    if (this.timerPemeriksaan != null){
      clearInterval(this.timerPemeriksaan);
    }

    if (this.timerOTimer != null){
      clearInterval(this.timerOTimer);
    }

    this.timerPemeriksaan = setInterval(() => {
      let syncApiInterval = 300; // seconds
      let modResult = this.waktuPemeriksaan % syncApiInterval;
      if (this.waktuPemeriksaan >= 0 && modResult == 0){
        let valid = (parseInt(this.ACTIVE_PATIENT_KLINIK) != 0 && parseInt(this.ACTIVE_PATIENT_PENJADWALAN) != 0);
        if (valid){
          this.api.apiWaktuPerawatan(this.ACTIVE_PATIENT_KLINIK, this.ACTIVE_PATIENT_PENJADWALAN, this.USER_TOKEN).subscribe(res => {
            this.waktuPemeriksaan = res.data;
          })
        }
      }
      this.waktuPemeriksaan--;
    }, 20000);

    this.timerOTimer = setInterval(() => {
      this.setTimerByApi();
    }, 60000);
  }

  resetTimer(){
    this.waktuPemeriksaan = null;
    if (this.timerPemeriksaan != null){
      clearInterval(this.timerPemeriksaan);
      clearInterval(this.timerOTimer);
    }
  }

  lastPemeriksaanKlinis(){
    let i = 0;
    for (let item of this.session_perawatan_data.pemeriksaan_klinis){
      if (i + 1 == this.session_perawatan_data.pemeriksaan_klinis.length){
        return item.id;
      }
      i++;
    }
    return 0;
  }

  resetExtraPerawatanData(){
    this.extra_perawatan_data = {
      ttd: null,
      notes: '',
    }
  }

  resetSessionCheckup(){
    this.session_checkup_data = {
      lampiran: {
        changed: false,
        skip: false,
      },
      catatan: {
        changed: false,
        skip: false,
      },
      catatandokter: {
        changed: false,
        skip: false,
      },
      odontogram: {
        changed: false,
        skip: false,
      },
      oralexam: {
        changed: false,
        skip: false,
      },
      periodontal: {
        changed: false,
        skip: false,
      },
      penjadwalan: {
        changed: false,
        skip: false,
      },
    }
  }

  resetSessionCheckout(){
    this.session_checkout_data = {
      anamnesa: '',
      notes: '',
      idpasien: 0,
      idperawatan: 0,
      lampiran: {
        changed: false,
        skip: false,
      },
      catatan: {
        changed: false,
        skip: false,
      },
      catatandokter: {
        changed: false,
        skip: false,
      },
      odontogram: {
        changed: false,
        skip: false,
      },
      oralexam: {
        changed: false,
        skip: false,
      },
      periodontal: {
        changed: false,
        skip: false,
      },
      penjadwalan: {
        changed: false,
        skip: false,
      },
    }
  }


  resetSessionPerawatan(){
    if (this.session_perawatan_data.ttd != null){
      this.storage.remove('KLINIKOO_TTD');
      this.storage.remove('KLINIKOO_TTD_');
    }

    this.session_perawatan_data = {
      idpasien: 0,
      idperawatan: 0,
      anamnesa: '',
      pemeriksaan_klinis: [],
      diagnosa: [],
      prognosa: [],
      perawatan: [],
      icd: [],
      tindakan: [],
      ttd: null,
      timeStart: new Date(),
    }

    this.tmpTTDUrl = '';
    this.tmpTTDFinalBlob = null;
    this.tmpConsent = [];
  }

  resetQuickInfo(){
    this.quickPatientInfo = {
      idpasien: 0,
      message: '',
    }
  }

  removeFromSessionPerawatan(tipe, id){
    if (tipe == 7){
      this.deleteObservasi(id);
    } else if (tipe == 1){
      let i = 0, index = 0;
      for (let item of this.session_perawatan_data.pemeriksaan_klinis){
        if (item.id == id){
          index = i;
        }
        i++;
      }
      this.session_perawatan_data.pemeriksaan_klinis.splice(index, 1);
      for (let item of this.session_perawatan_data.diagnosa){
        if (item.idpk == id){
          setTimeout(() => {
            this.removeFromSessionPerawatan(2, item.id);
          }, 100);
        }
      }
      for (let item of this.session_perawatan_data.perawatan){
        if (item.idpk == id){
          setTimeout(() => {
            this.removeFromSessionPerawatan(3, item.id);
          }, 100);
        }
      }
      for (let item of this.session_perawatan_data.icd){
        if (item.idpk == id){
          setTimeout(() => {
            this.removeFromSessionPerawatan(5, item.id);
          }, 100);
        }
      }
    } else if (tipe == 2){
      let i = 0, index = 0;
      for (let item of this.session_perawatan_data.diagnosa){
        if (item.id == id){
          index = i;
        }
        i++;
      }
      this.session_perawatan_data.diagnosa.splice(index, 1);

      for (let item of this.session_perawatan_data.prognosa){
        if (item.idd == id){
          setTimeout(() => {
            this.removeFromSessionPerawatan(6, [item.idd, item.id]);
          }, 100);
        }
      }

    } else if (tipe == 3){
      let i = 0, index = 0;
      for (let item of this.session_perawatan_data.perawatan){
        if (item.id == id){
          index = i;
        }
        i++;
      }
      this.session_perawatan_data.perawatan.splice(index, 1);
      for (let item of this.session_perawatan_data.tindakan){
        if (item.idp == id){
          setTimeout(() => {
            this.removeFromSessionPerawatan(4, [item.idkelompok, item.idtindakan]);
          }, 100);
        }
      }
    } else if (tipe == 5){
      let i = 0, index = 0;
      for (let item of this.session_perawatan_data.icd){
        if (item.id == id){
          index = i;
        }
        i++;
      }
      this.session_perawatan_data.icd.splice(index, 1);
    } else if (tipe == 4 && id.length == 2){
      let i = 0, index = 0;
      for (let item of this.session_perawatan_data.tindakan){
        if (item.idkelompok == id[0] && item.idtindakan == id[1]){
          index = i;
        }
        i++;
      }
      this.session_perawatan_data.tindakan.splice(index, 1);
    } else if (tipe == 6 && id.length == 2){
      let i = 0, index = 0;
      for (let item of this.session_perawatan_data.prognosa){
        if (item.idd == id[0] && item.id == id[1]){
          index = i;
        }
        i++;
      }
      this.session_perawatan_data.prognosa.splice(index, 1);
    }

    let idklinik = this.ACTIVE_PATIENT_KLINIK;
    let idpenjadwalan = this.ACTIVE_PATIENT_PENJADWALAN;
    let data = this.session_perawatan_data;
    let is_posted = '0';
    let post_error_encounter = '0';
    this.api.apiPerawatanSaveTemp(idklinik, idpenjadwalan, data, null, null, is_posted, post_error_encounter, this.USER_TOKEN).subscribe(res => {

    });
  }

  generateRandom() {
    this.CURRENT_SESSION_RANDOM = Math.floor(Math.random() * 100 + 1);
  }

  getTanggal(str: string) {
    if (str == null) {
      return '-'
    } else {
      if (this.isAppleDevice()) {
        str = str.split('-').join('/');
      }
      let strs = str.split(' ');
      if (strs.length > 1) {
        str = strs[0];
      }
      let tgl = new Date(str);
      let ctgl = new Date();
      let t_tgl = tgl.getDate() + ' ' + this.months[tgl.getMonth()] + ' ' + tgl.getFullYear();
      let t_ctgl = ctgl.getDate() + ' ' + this.months[ctgl.getMonth()] + ' ' + ctgl.getFullYear();
      if (t_ctgl === t_tgl) {
        return 'Hari ini'
      } else {
        if (tgl.getFullYear() == ctgl.getFullYear()) {
          return tgl.getDate() + ' ' + this.months[tgl.getMonth()] + ' ' + tgl.getFullYear();
        } else {
          return t_tgl;
        }
      }
    }
  }

  getTanggalv2(str) {
    if (str == null){
      return '-';
    }
    if (typeof str == 'string' && this.isAppleDevice()) {
      str = str.split('-').join('/');
    }
    let tgl = new Date(str);
    let t_tgl = tgl.getDate() + ' ' + this.months[tgl.getMonth()] + ' ' + tgl.getFullYear();
    return t_tgl;
  }

  getTanggalSeparated(str) {
    if (typeof str == 'string' && this.isAppleDevice()) {
      str = str.split('-').join('/');
    }
    let tgl = new Date(str);
    let t_tgl = tgl.getDate() + ' ' + this.months[tgl.getMonth()] + ' ' + tgl.getFullYear();
    return [tgl.getDate(), this.shortMonths[tgl.getMonth()], tgl.getFullYear()];
  }

  getTanggalFromDate(date) {
    if (date == null) {
      return '-'
    } else {
      let ctgl = new Date();
      let t_tgl = date.getDate() + ' ' + this.months[date.getMonth()] + ' ' + date.getFullYear();
      let t_ctgl = ctgl.getDate() + ' ' + this.months[ctgl.getMonth()] + ' ' + ctgl.getFullYear();
      if (t_ctgl === t_tgl) {
        return 'Hari ini'
      } else {
        if (date.getFullYear() == ctgl.getFullYear()) {
          return date.getDate() + ' ' + this.months[date.getMonth()];
        } else {
          return t_tgl;
        }
      }
    }
  }

  getTanggalFromDatev2(date) {
    if (date == null) {
      return '-'
    } else {
      let t_tgl = date.getDate() + ' ' + this.months[date.getMonth()] + ' ' + date.getFullYear();
      return t_tgl;
    }
  }

  getWaktu(str: string) {
    if (str != null) {
      let onlyTime = false;
      if (str.split(' ').length == 1) {
        onlyTime = true;
      }
      if (!onlyTime) {
        let waktu = new Date(str);
        let am_pm = 'AM';
        if (waktu.getHours() / 12 >= 1) {
          am_pm = 'PM'
        }
        return waktu.getHours() + ':' + waktu.getMinutes() + ' ' + am_pm;
      } else {
        let splitted = str.split(':');
        let h: string = splitted[0];
        let m: string = splitted[1];
        let s: string = splitted[2];
        let am_pm = 'AM';
        if (parseInt(h) / 12 >= 1) {
          am_pm = 'PM'
        }
        return h + ':' + m + ' ' + am_pm;
      }
    } else {
      return '-';
    }
  }

  async showAlert(header: string, message: string) {
    const alert = await this.alertCon.create({
      header: header,
      message: message,
      buttons: ["OK"]
    });
    await alert.present();
  }

  async showToast(message: string) {
    message = message.charAt(0).toUpperCase() + message.slice(1);
    const toast = await this.tc.create({
      message: message,
      duration: 3000,
    })
    await toast.present();
  }

  async showToastTimed(message: string, duration) {
    message = message.charAt(0).toUpperCase() + message.slice(1);
    const toast = await this.tc.create({
      message: message,
      duration: duration,
    })
    await toast.present();
  }

  async login(user: any) {
    const loading = await this.lc.create({
      message: "Memproses data...",
    });
    await loading.present();
    this.api.apiLogin(user).subscribe(async res => {
      if (res.error == null || !res.error) {
        await this.storage.set("TOKEN", res.access.token);
        this.USER_TOKEN = res.access.token;
        this.DOKTER_USERNAME = user.username;
        this.DOKTER_PASSWORD = user.password;

        if (AppSettings.isAppCompact()){
          if (res.urlcheckin != null){
            console.log('checkin');
            this.isCompact = true;
            this.tempCheckinLink = res.urlcheckin;
          }
        }

        this.authSubject.next(true);
        if (res.lite != null){
          this.isAccountLite = (res.lite == 1) ? true : false;
          this.isAccountFree = (res.lite == 2) ? true : false;
        }

        this.zone.run(() => {
          this.router.navigateByUrl('/dentist');
        })
        loading.dismiss();
      } else {
        loading.dismiss();
        this.isAccountLite = false;
        this.isAccountFree = false;

        if (AppSettings.isAppCompact()){
          if (res.error == 'Akun Anda tidak memenuhi persyaratan'){
            this.showToast(res.error);
            this.isCompact = false;
          } else {
            this.showToast(res.error);
            this.isCompact = true;
          }
        }
      }
    }, err => {
      loading.dismiss();
      this.showToast("Username/password salah!");
    });
  }

  async tokenLogin() {

    // this.checkingToken = true;
    // const loading = await this.lc.create({
    //   message : "Checking Session...",
    // })
    // await loading.present();

    await this.storage.get("TOKEN").then(data => {

      this.router.navigateByUrl('/dentist');

      if (data != null) {
        this.api.apiGetDoctor(data).subscribe(async res => {
          if (res.profile[0].iddokter > 0) {
            this.USER_TOKEN = data;
            this.authSubject.next(true);

            if (res.user[0] != null){
              this.DOKTER_USERNAME = res.user[0].username;
              this.DOKTER_ID = res.user[0].iddokter;
            }

            if (res.user != null && res.user.length > 0 && res.user[0].islite != null){
              let temp = res.user[0].islite;
              this.isAccountLite = (temp == 1) ? true : false;
              this.isAccountFree = (temp == 2) ? true : false;
            }

            this.router.navigateByUrl(this.firstPage);
          } else {
            await this.storage.remove("TOKEN");
            this.USER_TOKEN = "";
            this.isCompact = false;
            this.tempCheckinLink = '';
            this.authSubject.next(false);
            this.isAccountLite = false;
            this.isAccountFree = false;
          }
          // this.checkingToken = false;
          setTimeout(() => {
            // loading.dismiss();
          }, 1000);
        }, err => {
          if (this.isRetriesAvailable) {
            // loading.dismiss();
            this.isRetriesAvailable = false;
            this.tokenLogin();
          } else {
            async () => {
              await this.storage.remove("TOKEN");
              this.USER_TOKEN = "";
            }
          }
          // this.checkingToken = false;
          this.zone.run(() => {
            this.router.navigateByUrl('/home');
          })
          // loading.dismiss();
        })
      } else {
        async () => {
          await this.storage.remove("TOKEN");
          this.USER_TOKEN = "";
        }
        // this.checkingToken = false;
        this.zone.run(() => {
          this.router.navigateByUrl('/home');
        })
        // setTimeout(()=> {
        //   loading.dismiss();
        // }, 1000);
      }
    }).catch(err => {
      async () => {
        await this.storage.remove("TOKEN");
        this.USER_TOKEN = "";
      }
      // this.checkingToken = false;
      this.zone.run(() => {
        this.router.navigateByUrl('/home');
      })
      // setTimeout(()=> {
      //   loading.dismiss();
      // }, 1000);
    })
  }

  async logout() {
    if (!this.IS_DUMMY_MODE) {
      const token = await this.storage.get('TOKEN');
      this.api.apiLogout(token).subscribe(async res => {
        await this.storage.remove("TOKEN");
        this.USER_TOKEN = "";
        this.authSubject.next(false);
        this.isAccountLite = false;
        this.isAccountFree = false;
        this.zone.run(() => {
          this.nc.navigateBack("/login");
        })
      })
    } else {
      await this.storage.remove("TOKEN");
      this.USER_TOKEN = "";
      this.IS_DUMMY_MODE = false;
      this.authSubject.next(false);
      this.isAccountLite = false;
      this.isAccountFree = false;
      this.zone.run(() => {
        this.nc.navigateBack("/login");
      })
    }
  }

  isLoggedIn() {
    return this.authSubject.asObservable();
  }

  resetSesiPasien() {
    this.resetSessionCheckup();
    this.resetSessionPerawatan();

    this.auto_show_popup_checkout = false;
    this.auto_show_popup_perawatan = false;
  }

  isAppleDevice() {
    if ((navigator.userAgent.match(/(iPhone|iPod|iPad)/) != null)) {
      return true;
    }
    return false;
  }

  sessionCheckupAnyTrue(){
    let x = this.session_checkup_data;
    if (x.catatan.changed){
      return true;
    } else if (x.catatan.skip){
      return true;
    } else if (x.catatandokter.changed){
      return true;
    } else if (x.catatandokter.skip){
      return true;
    } else if (x.lampiran.changed){
      return true;
    } else if (x.lampiran.skip){
      return true;
    } else if (x.odontogram.changed){
      return true;
    } else if (x.odontogram.skip){
      return true;
    } else if (x.oralexam.changed){
      return true;
    } else if (x.oralexam.skip){
      return true;
    } else if (x.penjadwalan.changed){
      return true;
    } else if (x.penjadwalan.skip){
      return true;
    } else if (x.periodontal.changed){
      return true;
    } else if (x.periodontal.skip){
      return true;
    }
  }

  //inclusivity
  getGender(code: string = '-'){
    code = code.toUpperCase();
    if(code == 'L'){
      return 'Laki-laki';
    } else if (code == 'P'){
      return 'Perempuan';
    } else if (code == 'X'){
      return 'Lainnya';
    } else if (code == '-'){
      return 'Tidak Disebutkan';
    }
  }

  async getPerawatanTemp(){
    if (this.ACTIVE_PATIENT_KLINIK != '0' && this.ACTIVE_PATIENT_PENJADWALAN != '0'){
      this.api.apiPerawatanGetTemp(this.ACTIVE_PATIENT_KLINIK, this.ACTIVE_PATIENT_PENJADWALAN, this.USER_TOKEN).subscribe(res => {
        if (res != null){
          this.session_perawatan_data = JSON.parse(res.data);
          this.tmpConsent = [];
          this.tmpTTDUrl = '';
          if (res.ttd != null){
            this.tmpTTDUrl = res.ttd + "?" + this.CURRENT_SESSION_RANDOM;
            if (res.ttd_consent != null && res.ttd_consent.length > 0 && res.ttd_at != null){
              var consent = res.ttd_consent.split(":&:");
              for (let item of consent){
                item = parseInt(item);
                if (this.tmpConsent.indexOf(item) !== -1 || this.tmpConsent.indexOf('' + item) !== -1){

                } else {
                  this.tmpConsent.push(item);
                }
              }
            }
          }
          return true;
        } else {
          return false;
        }
      });
    }
    return false;
  }

  async getObservasi(){
    if (this.ACTIVE_PATIENT_KLINIK != '0' && this.ACTIVE_PATIENT_PENJADWALAN != '0'){
      this.api.apiSavedObservasi(this.ACTIVE_PATIENT_KLINIK, this.ACTIVE_PATIENT_PENJADWALAN, this.USER_TOKEN).subscribe(res => {
        this.session_observasi = res.data;
        return res;
      });
    } else {
      return null;
    }
  }

  async postObservasi(idobservation, value, keterangan){
    if (this.ACTIVE_PATIENT_KLINIK != '0' && this.ACTIVE_PATIENT_PENJADWALAN != '0'){
      let data = {
        idklinik: this.ACTIVE_PATIENT_KLINIK,
        idpenjadwalan: this.ACTIVE_PATIENT_PENJADWALAN,
        idobservation: idobservation,
        value: value,
        keterangan: keterangan,
      }
      this.api.apiPostObservasi(data, this.USER_TOKEN).subscribe(res => {
        this.getObservasi();
        this.showToast(res.message);
      });
    } else {
      return false;
    }
  }

  async deleteObservasi(idobservation){
    if (this.ACTIVE_PATIENT_KLINIK != '0' && this.ACTIVE_PATIENT_PENJADWALAN != '0'){
      let data = {
        idklinik: this.ACTIVE_PATIENT_KLINIK,
        idpenjadwalan: this.ACTIVE_PATIENT_PENJADWALAN,
        idobservation: idobservation,
      }
      this.api.apiDeleteObservasi(data, this.USER_TOKEN).subscribe(res => {
        this.getObservasi();
        this.showToast(res.message);
      });
    } else {
      return false;
    }
  }

  getOdontogramStatus(){
    this.api.apiGetOdontogramStatus(this.USER_TOKEN).subscribe(res => {
      this.currentActClass = [];
      this.currentZoneActClass = [];
      for (let i = 0; i < res.data.length; i++){
        let item = res.data[i];
        if (item.idstatus != 0){
          this.currentActClass.push({
            no: item.idstatus,
            text: item.status,
            class: item.class,
            inText: (item.intext != null) ? item.intext : '',
            top: (item.top == 1) ? true : false,
            isMulti: (item.ismulti) ? true : false,
            customStyle: item.customstyle,
            customPath: item.custompath,
          });
          if (item.zoneclass != null && item.zoneclass.length > 0){
              this.currentZoneActClass.push({
                no: item.idstatus,
                class: item.zoneclass,
                color: item.zonecolor,
                customStyle: item.customzonestyle,
              });
          }
        }
      }
    });
  }
}
