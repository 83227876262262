import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-konsul-cancel',
  templateUrl: './konsul-cancel.page.html',
  styleUrls: ['./konsul-cancel.page.scss'],
})
export class KonsulCancelPage implements OnInit {

  idklinik = null;
  idpenjadwalan = null;
  alasan: string = "";

  constructor(
    public auth: AuthService,
    private api: ApiService,
    private mc: ModalController,
    private params: NavParams,
  ) { }

  ngOnInit() {
    if (this.params.data.idklinik != null){
      this.idklinik = this.params.data.idklinik;
    }
    if (this.params.data.idpenjadwalan != null){
      this.idpenjadwalan = this.params.data.idpenjadwalan;
    }
  }

  submit(){
    this.api.apiPostBatalKonsultasi(this.idklinik, this.idpenjadwalan, this.alasan, this.auth.USER_TOKEN).subscribe(res => {
      if (res.message != null){
        this.auth.showToast(res.message);
      }
      this.mc.dismiss();
    });
  }

  allowSubmit(){
    if (this.alasan.length > 0){
      return true;
    }
    return false;
  }

}
