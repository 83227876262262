// Managing navigation between tabs in the app, including the slide in-out animation

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  active = '';

  activeTab = 0;
  gotoTab = 0;
  activeAnimation = '';
  gotoAnimation = '';

  constructor() { }

  goto(index: number){
    if (this.activeTab != 0){
      this.gotoTab = index;
      if (this.activeTab == this.gotoTab){

      } else {
        if (index < this.activeTab){
          this.gotoAnimation = 'above';
        } else {
          this.gotoAnimation = 'below';
        }
      }
    } else {
      this.activeTab = index;
    }
  }

  beActive(){
    this.activeTab = this.gotoTab;
    this.gotoTab = 0;
    this.gotoAnimation = '';
    this.activeAnimation = 'middle';
  }

  animation(index: number){
    if (index == this.gotoTab){
      if (index < this.activeTab){
        return 'above';
      } else if (index > this.activeTab){
        return 'below';
      }
    } else if (index == this.activeTab){
      if (this.gotoTab != 0){
        if (index < this.gotoTab){
          return 'above';
        } else if (index > this.gotoTab){
          return 'below';
        }
      }
      return 'middle';
    } else {
      if (this.gotoTab == 0 && this.activeTab == 0){
        return 'middle';
      } else {
        if (index < this.gotoTab){
          return 'above';
        } else if (index > this.gotoTab){
          return 'below';
        }
      }
    }
    return '-';
  }

}
