import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { daftarHargaResponse } from 'src/app/services/api/api-response';

@Component({
  selector: 'app-daftarvoucher',
  templateUrl: './daftarvoucher.page.html',
  styleUrls: ['./daftarvoucher.page.scss'],
})
export class DaftarvoucherPage implements OnInit {

  daftarHarga: daftarHargaResponse = null;
  daftarVoucher: any = null;

  activeTab: number = 1;
  selectedContent = '1';
  sliderConfig = {
    slidesPerView: 1,
    spaceBetween: 20,
    centeredSlides: true
  };
  @ViewChild('slider') slider: IonSlides;

  constructor(
    public auth: AuthService,
    public mc: ModalController,
    private api: ApiService,
    private iab: InAppBrowser,
  ) { }

  ngOnInit() {
    //this.auth.auto_show_popup_checkout = true;
  }

  ionViewWillEnter(){
    // this.api.apiGetDaftarHarga(this.auth.USER_TOKEN).subscribe(res => {
    //   if (res.data != null){
    //     this.daftarHarga = res;
    //   }
    // });

    this.api.apiListVoucher(this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res => {
      if (res.data != null){
        this.daftarVoucher = res;
      }
    })
  }

  displayHarga(harga){
    return "Rp" + Number(harga).toLocaleString('id') + ",00";
  }

  openLink(target: string){
    if (target != null){
      // this.fo.open(target, 'application/pdf').then(() => {
      //
      // }). catch(e => console.log('Error opening file', e));
      let x = this.iab.create(target, '_system', 'location=no');
    } else {
      this.auth.showToast("Tidak ada link website");
    }
  }

  segmentChanged(e) {
    if (e.detail.value == '1') {
      this.slider.slideTo(0);
      this.activeTab = 1;
    } else {
      this.slider.slideTo(1);
      this.activeTab = 2;
    }
  }

  sliderChanged(e) {
    this.slider.getActiveIndex().then((v) => {
      if (v == 0) {
        this.selectedContent = '1';
        this.activeTab = 1;
      } else {
        this.selectedContent = '2';
        this.activeTab = 2;
      }
    });
  }

}
