import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dentist',
    pathMatch: 'full'
  },
  {
    path: 'tabs',
    loadChildren: 'src/app/pages/tabs/tabs.module#TabsPageModule',
    data: {
      name: 'tabs',
    }
  },
  {
    path: 'home',
    loadChildren: 'src/app/pages/pra-perawatan/home/home.module#HomePageModule',
    data: {
      name: 'home',
    }
  },
  {
    path: 'qrscan',
    loadChildren: 'src/app/pages/pra-perawatan/qrscan/qrscan.module#QrscanPageModule',
    data: {
      name: 'qrscan',
    }
  },
  {
    path: 'datapasien',
    loadChildren: 'src/app/pages/pra-perawatan/datapasien/datapasien.module#DatapasienPageModule',
    data: {
      name: 'datapasien',
    }
  },
  {
    path: 'login',
    loadChildren: 'src/app/pages/pra-perawatan/login/login.module#LoginPageModule',
    data: {
      name: 'login',
    }
  },
  {
    path: 'dentist',
    loadChildren: 'src/app/pages/pra-perawatan/dentist/dentist.module#DentistPageModule',
    data: {
      name: 'dentist',
    }
  },
  {
    path: 'pasien',
    loadChildren: 'src/app/pages/perawatan/pasien/pasien.module#PasienPageModule',
    data: {
      name: 'pasien',
    }
  },
  {
    path: 'fotoprofilpasien',
    loadChildren: 'src/app/pages/perawatan/fotoprofilpasien/fotoprofilpasien.module#FotoprofilpasienPageModule',
    data: {
      name: 'fotoprofilpasien',
    }
  },
  {
    path: 'searchpasien',
    loadChildren: 'src/app/pages/pra-perawatan/searchpasien/searchpasien.module#SearchpasienPageModule',
    data: {
      name: 'searchpasien',
    }
  },
  {
    path: 'settingdokter',
    loadChildren: 'src/app/pages/pra-perawatan/settingdokter/settingdokter.module#SettingdokterPageModule',
    data: {
      name: 'settingdokter',
    }
  },
  {
    path: 'biodatadokter',
    loadChildren: 'src/app/pages/pra-perawatan/biodatadokter/biodatadokter.module#BiodatadokterPageModule',
    data: {
      name: 'biodatadokter',
    }
  },
  {
    path: 'rekapaktivitas',
    loadChildren: 'src/app/pages/pra-perawatan/rekapaktivitas/rekapaktivitas.module#RekapaktivitasPageModule',
    data: {
      name: 'rekapaktivitas',
    }
  },
  {
    path: 'bagihasil',
    loadChildren: 'src/app/pages/pra-perawatan/bagihasil/bagihasil.module#BagihasilPageModule',
    data: {
      name: 'bagihasil',
    }
  },
  {
    path: 'kontakmanajemen',
    loadChildren: 'src/app/pages/pra-perawatan/kontakmanajemen/kontakmanajemen.module#KontakmanajemenPageModule',
    data: {
      name: 'kontakmanajemen',
    }
  },
  {
    path: 'reviewjadwal',
    loadChildren: 'src/app/pages/pra-perawatan/reviewjadwal/reviewjadwal.module#ReviewjadwalPageModule',
    data: {
      name: 'reviewjadwal',
    }
  },
  {
    path: 'perawatan',
    loadChildren: 'src/app/pages/perawatan/perawatan/perawatan.module#PerawatanPageModule',
    data: {
      name: 'perawatan',
    }
  },
  {
    path: 'perawatan-confirm',
    loadChildren: 'src/app/pages/perawatan/perawatan-confirm/perawatan-confirm.module#PerawatanConfirmPageModule',
    data: {
      name: 'perawatan-confirm',
    }
  },
  {
    path: 'perawatan-input',
    loadChildren: 'src/app/pages/perawatan/perawatan-input/perawatan-input.module#PerawatanInputPageModule',
    data: {
      name: 'perawatan-input',
    }
  },
  {
    path: 'perawatan-confirm-pelayanan',
    loadChildren: 'src/app/pages/perawatan/perawatan-confirm-pelayanan/perawatan-confirm-pelayanan.module#PerawatanConfirmPelayananPageModule',
    data: {
      name: 'perawatan-confirm-pelayanan',
    }
  },
  {
    path: 'konsul-online',
    loadChildren: 'src/app/pages/perawatan/konsul-online/konsul-online.module#KonsulOnlinePageModule',
    data: {
      name: 'konsul-online',
    }
  },
  {
    path: 'dashboard-se',
    loadChildren: 'src/app/pages/pra-perawatan/dashboard-se/dashboard-se.module#DashboardSePageModule',
    data: {
      name: 'dashboard-se',
    }
  },
  {
    path: 'report',
    loadChildren: 'src/app/pages/pra-perawatan/report/report.module#ReportPageModule',
    data: {
      name: 'report',
    }
  },
  {
    path: 'roadmap',
    loadChildren: 'src/app/pages/pra-perawatan/roadmap/roadmap.module#RoadmapPageModule',
    data: {
      name: 'roadmap',
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
