import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { AlertController, IonContent, IonSlides, ModalController, NavController, NavParams, LoadingController, IonInfiniteScroll } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-popuptindakan',
  templateUrl: './popuptindakan.page.html',
  styleUrls: ['./popuptindakan.page.scss'],
})
export class PopuptindakanPage implements OnInit {

  tindakans: any = []
  tmpTindakans: any = JSON.parse(JSON.stringify(this.tindakans));

  activeTindakan = [];
  activePerawatanT = 0;

  searchTindakan = '';
  isSearchingTindakan = false;

  activePage: number = 1;
  tmpList: any = null;
  isLoadingList: boolean = false;

  disabledOptions: any = [];
  firstCall: boolean = true;
  searchTimeout: any = null;

  root: any = null;
  mode: number = 1;

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  constructor(
    public auth: AuthService,
    private nav: NavController,
    private params: NavParams,
    private mc: ModalController,
    private ac: AlertController,
    private api: ApiService,
    private zone: NgZone,
    private lc: LoadingController,
  ) { }

  ngOnInit() {
    if (this.params.data.root){
      this.root = this.params.data.root;
    }
    if (this.params.data.mode){
      this.mode = this.params.data.mode;
    }
    this.setTindakan(true);
  }

  loadData(event) {
    this.tmpList = null;
    setTimeout(() => {
      if (this.searchTindakan.length > 0){
        this.doSearchTindakan();
      } else {
        this.setTindakan();
      }
      this.checkList(event);
    }, 100)
  }

  checkList(event: any){
    if (!this.isLoadingList && this.tmpList != null){
      if (this.tmpList.data.length < 10){
        event.target.disabled = true;
      } else {
        this.activePage++;
      }
      event.target.complete();
    } else {
      setTimeout(() => {
        this.checkList(event);
      }, 100);
    }
  }

  setTindakan(reset: boolean = false){
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.tindakans = [];
    }
    this.isLoadingList = true;

    this.api.apiDashboardSETindakan(this.activePage, this.searchTindakan, this.auth.USER_TOKEN).subscribe(res => {
      this.tmpList = res;
      this.tmpTindakans = [];

      if (res != null && res.data != null){
        for (let item of res.data){
          if (item.suggested != null){
            this.tindakans.push({
              id: item.idtindakan,
              idkelompok: item.idkelompok,
              text: item.nama,
              harga: item.harga,
              bagihasil: item.bagihasil,
              suggested: item.suggested,
              kode: item.kode,
            });
          } else {
            this.tindakans.push({
              id: item.idtindakan,
              idkelompok: item.idkelompok,
              text: item.nama,
              harga: item.harga,
              bagihasil: item.bagihasil,
              suggested: 0,
              kode: item.kode,
            });
          }
        }
        this.tmpTindakans = JSON.parse(JSON.stringify(this.tindakans));
        // this.doSearchDiagnosa();
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      }
    });
  }

  saveTindakan(){
    for (let item of this.activeTindakan){
      for (let subitem of this.tindakans){
        if (subitem.id == item){
          if (this.root != null){
            this.root.listTindakan = {};
            this.root.listTindakan.data = this.tindakans;
            if (this.mode == 1){
              this.root.intiTindakan = item;
            } else if (this.mode == 2){
              this.root.seTindakan = item;
            }
            this.root.autofillFromTindakan(this.mode);
            this.mc.dismiss();
          }
        }
      }
    }
  }

  doSearchTindakan(reset: boolean = false) {
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.tindakans = [];
    }
    this.isLoadingList = true;

    this.isSearchingTindakan = true;

    if (this.searchTimeout != null){
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.api.apiDashboardSETindakan(this.activePage, this.searchTindakan, this.auth.USER_TOKEN).subscribe(res => {
        this.tmpList = res;
        if (res != null && res.data != null){
          for (let item of res.data){
            if (item.suggested != null){
              this.tindakans.push({
                id: item.idtindakan,
                idkelompok: item.idkelompok,
                text: item.nama,
                harga: item.harga,
                bagihasil: item.bagihasil,
                suggested: item.suggested,
                kode: item.kode,
              });
            } else {
              this.tindakans.push({
                id: item.idtindakan,
                idkelompok: item.idkelompok,
                text: item.nama,
                harga: item.harga,
                bagihasil: item.bagihasil,
                suggested: 0,
                kode: item.kode,
              });
            }
          }
          this.tmpTindakans = JSON.parse(JSON.stringify(this.tindakans));
        }

        let activeFound = false;
        let tmp = [];
        for (let item of this.tindakans) {
          if (item.text.toUpperCase().includes(this.searchTindakan.toUpperCase())) {
            // this.tmpTindakans.push({ id: item.id, text: item.text });
            if (this.isActiveTindakan(item.id)) {
              activeFound = true;
              tmp.push(item.id);
            }
          }
        }
        if (!activeFound) {
          this.activeTindakan = [];
        } else {
          this.activeTindakan = JSON.parse(JSON.stringify(tmp));
        }
        this.isSearchingTindakan = false;
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      });
    }, 1000);
  }

  isActiveTindakan(id) {
    for (let item of this.activeTindakan) {
      if (item == id) {
        return true;
      }
    }
    return false;
  }

  setActiveTindakan(id) {
    if (!this.isActiveTindakan(id)) {
      this.activeTindakan = [id];
    } else {
      let index = this.activeTindakan.indexOf(id);
      if (index > -1) {
        this.activeTindakan.splice(index, 1);
      }
    }
  }

  isSearching(){
    if (this.isSearchingTindakan){
      return true;
    }
    return false;
  }

  allowToSaveTindakan(){
    if (this.activeTindakan.length != 0){
      return true;
    } else if (this.activePerawatanT != 0){
      return true;
    }
    return false;
  }

  getPemKlinis(id, status, setItem) {
    for (let item of setItem) {
      if (item.id == id && item.status == status) {
        return item.text;
      }
    }
    return 0;
  }

  getPerawatanT(id, setItem) {
    for (let item of setItem) {
      if (item.id == id) {
        return item.text;
      }
    }
    return 0;
  }

  toggleTopOptions() {
    // this.showTopOptions = !this.showTopOptions;
  }

  getHours(time: Date): string{
    if (time){
      let s = time.getHours() + ':' + time.getMinutes();
      let splitted = s.split(':');
      let h: string = ((parseInt(splitted[0])) < 10) ? '0' + (parseInt(splitted[0])).toString() : (parseInt(splitted[0])).toString();
      let m: string = (parseInt(splitted[1]) < 10) ? '0' + parseInt(splitted[1]).toString() : parseInt(splitted[1]).toString();
      return h + ':' + m;
    }
    return '00:00';
  }

  displayHarga(harga) {
    return Number(harga).toLocaleString('id');
  }

  invalidKlinis(no){
    let invalids = this.disabledOptions;
    let found = false;
    for (let i = 0; i < invalids.length; i++){
      if (invalids[i] == no){
        found = true;
      }
    }
    return found;
  }
}
