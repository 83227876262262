import { Component, OnInit, NgZone } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/settings/settings';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-popupinfoperawatan',
  templateUrl: './popupinfoperawatan.page.html',
  styleUrls: ['./popupinfoperawatan.page.scss'],
})
export class PopupinfoperawatanPage implements OnInit {

  idklinik = null;
  idpenjadwalan = null;
  idpasien = null;
  current = null;

  previewData = null;
  listInti = null;
  listSE = null;

  constructor(
    public auth: AuthService,
    private api: ApiService,
    private mc: ModalController,
    private params: NavParams,
    private zone: NgZone,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.auth.ACTIVE_PATIENT_KLINIK != null && this.auth.ACTIVE_PATIENT_PENJADWALAN != null){
      this.idklinik = this.auth.ACTIVE_PATIENT_KLINIK;
      this.idpenjadwalan = this.auth.ACTIVE_PATIENT_PENJADWALAN;
    }

    if (this.auth.ACTIVE_PATIENT != null){
      this.idpasien = this.auth.ACTIVE_PATIENT;
    }

    this.getAll();
  }

  getAll(){
    this.previewData = null;
    this.api.apiPreviewJadwal(this.idklinik, this.idpenjadwalan, this.auth.USER_TOKEN).subscribe(res => {
      this.previewData = res;
    });

    this.listInti = null;
    this.listSE = null;
    this.api.apiDashboardSEIntiPenjadwalan(this.idklinik, this.idpenjadwalan, '', '', this.auth.USER_TOKEN).subscribe(res => {
      if (res != null && res.data != null){
        this.listInti = res.data;
      }
    });

    this.api.apiDashboardSESEPenjadwalan(this.idklinik, this.idpenjadwalan, '', '', this.auth.USER_TOKEN).subscribe(res => {
      if (res != null && res.data != null){
        this.listSE = res.data;
      }
    });
  }

  isSEUtama(item){
    if (item != null){
      if (item.harga != "Rp0" && item.jumlah > 0){
        return true;
      }
    }
    return false;
  }

  getSECount(){
    if (this.listSE != null){
      return this.listSE.length;
    }
    return 0;
  }



}
