import { Component, OnInit, ViewChild  } from '@angular/core';
import { Storage } from '@ionic/storage';
import { File, IWriteOptions } from '@ionic-native/file/ngx';
import { Platform, NavParams, ModalController } from '@ionic/angular';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { fromEvent } from 'rxjs';
import { map, tap, switchMap, takeUntil, finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PerawatanConfirmPage } from 'src/app/pages/perawatan/perawatan-confirm/perawatan-confirm.page';
import { PerawatanConfirmPelayananPage } from 'src/app/pages/perawatan/perawatan-confirm-pelayanan/perawatan-confirm-pelayanan.page';
import { ApiService } from "src/app/services/api/api.service";
import { AppSettings } from "src/app/settings/settings";

const STORAGE_KEY = "KLINIKOO_TTD";
const STORAGE_KEY_ = "KLINIKOO_TTD_";

interface point{
  x: number,
  y: number,
}

interface path{
  initialPoint : point,
  nextPoint: point[],
  endPoint: point,
  color: string,
}

@Component({
  selector: 'app-popupttd',
  templateUrl: './popupttd.page.html',
  styleUrls: ['./popupttd.page.scss'],
})
export class PopupttdPage implements OnInit {

  colorPallete = [
    '#000000',
  ];
  storedImages = [];
  canvasElement: any;
  activeColor = '#000000';
  activeX = 0;
  activeY = 0;
  strokeIndex = 0;
  trackPath : path[];
  tmpPath : path;

  keyToUse = '';

  root: PerawatanConfirmPage = null;
  root2: PerawatanConfirmPelayananPage = null;

  imgBlob = null;

  @ViewChild('ttdCanvas') canvas: any;

  constructor(
    private file: File,
    private storage: Storage,
    private plt: Platform,
    private webview: WebView,
    private params : NavParams,
    private sanitizer: DomSanitizer,
    private mc: ModalController,
    private api: ApiService,
    public auth: AuthService,
  ) { }

  ngOnInit() {
    this.trackPath = [];
    this.tmpPath = {
      initialPoint : {x : 0, y: 0},
      nextPoint: [],
      endPoint: {x : 0, y: 0},
      color: "#000000",
    };
    if (this.params.data.root){
      this.root = this.params.data.root;
      this.keyToUse = STORAGE_KEY;
    } else if (this.params.data.root2){
      this.root2 = this.params.data.root2;
      this.keyToUse = STORAGE_KEY_;
    }

    this.storage.remove(this.keyToUse);

    if (this.auth.session_perawatan_data.ttd != null){
      this.root.ttd = this.auth.session_perawatan_data.ttd;
    }
  }

  ionViewDidEnter(){
    this.canvasElement = this.canvas.nativeElement;
    this.canvasElement.width = this.canvas.nativeElement.width;
    this.canvasElement.height = this.canvas.nativeElement.height;

    if (this.canvasElement != null){
      const mouseDownStream = fromEvent(this.canvas.nativeElement, 'mousedown');
      const mouseMoveStream = fromEvent(this.canvas.nativeElement, 'mousemove');
      const mouseUpStream = fromEvent(window, 'mouseup');

      let context = this.canvasElement.getContext('2d');
      mouseDownStream.pipe(
        tap((event: MouseEvent) => {
          context.beginPath();
          context.strokeStyle = this.activeColor;
          context.lineWidth = 3;
          context.lineJoin = 'round';
          context.moveTo(event.offsetX, event.offsetY);

          this.activeX = event.offsetX;
          this.activeY = event.offsetY;

          this.tmpPath.initialPoint.x = this.activeX;
          this.tmpPath.initialPoint.y = this.activeY;
          this.tmpPath.color = this.activeColor;
        }),
        switchMap(() => mouseMoveStream.pipe(
          tap((event: MouseEvent) => {
            context.lineTo(event.offsetX, event.offsetY);
            context.stroke();
            this.activeX = event.offsetX;
            this.activeY = event.offsetY;

            this.tmpPath.nextPoint.push({
              x : this.activeX,
              y : this.activeY,
            });
          }),
          takeUntil(mouseUpStream),
          finalize(() => {
            context.closePath();
            this.createStack(null);
          })
        ))
      ).subscribe();
    }
  }

  startDrawing(ev){
    var canvasPosition = (this.canvasElement != null) ? this.canvasElement.getBoundingClientRect() : {x: 0, y: 0};
    if (ev.touches != null){
      this.activeX = ev.touches[0].pageX - canvasPosition.x;
      this.activeY = ev.touches[0].pageY - canvasPosition.y;
    } else {
      this.activeX = ev.offsetX;
      this.activeY = ev.offsetY;
    }
    this.tmpPath.initialPoint.x = this.activeX;
    this.tmpPath.initialPoint.y = this.activeY;
    this.tmpPath.color = this.activeColor;
  }

  move(ev){
    var canvasPosition = (this.canvasElement != null) ? this.canvasElement.getBoundingClientRect() : {x: 0, y: 0};
    let context = this.canvasElement.getContext('2d');
    let currentX = 0;
    let currentY = 0;
    if (ev.touches != null){
      currentX = ev.touches[0].pageX - canvasPosition.x;
      currentY = ev.touches[0].pageY - canvasPosition.y;
    } else {
      currentX = ev.offsetX;
      currentY = ev.offsetY;
    }
    context.lineJoin = 'round';
    context.strokeStyle = this.activeColor;
    context.lineWidth = 3;

    context.beginPath();
    context.moveTo(this.activeX, this.activeY);
    context.lineTo(currentX, currentY);
    context.closePath();
    context.stroke();
    this.activeX = currentX;
    this.activeY = currentY;

    this.tmpPath.nextPoint.push({
      x : this.activeX,
      y : this.activeY,
    });
  }

  createStack(ev){
    let currentX = this.tmpPath.nextPoint[this.tmpPath.nextPoint.length - 1].x;
    let currentY = this.tmpPath.nextPoint[this.tmpPath.nextPoint.length - 1].y;
    this.tmpPath.endPoint.x = currentX;
    this.tmpPath.endPoint.y = currentY;
    this.strokeIndex += 1;
    this.trackPath.push(this.tmpPath);
    this.tmpPath = {
      initialPoint : {x : 0, y: 0},
      nextPoint: [],
      endPoint: {x : 0, y: 0},
      color: "#000000",
    };
    //let context = this.canvasElement.getContext('2d');
  }

  drawStroke(path: path){
    if (this.activeColor != path.color){
      this.activeColor = path.color;
    }

    let last : point = {
      x : path.initialPoint.x,
      y : path.initialPoint.y,
    }

    let context = this.canvasElement.getContext('2d');
    context.lineJoin = 'round';
    context.strokeStyle = this.activeColor;
    context.lineWidth = 3;

    for (let i = 0; i < path.nextPoint.length; i++){
      let item = path.nextPoint[i];
      context.beginPath();
      if (i == path.nextPoint.length - 1){
        context.moveTo(last.x, last.y);
        context.lineTo(path.endPoint.x, path.endPoint.y);
      } else {
        context.moveTo(last.x, last.y);
        context.lineTo(item.x, item.y);
        last.x = item.x;
        last.y = item.y;
      }
      context.stroke();
      context.closePath();
    }
  }

  canvasUndo(){
    //console.log(this.trackPath.length);
    let context = this.canvasElement.getContext('2d');
    context.clearRect(0,0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.trackPath.splice(this.trackPath.length - 1, 1);
    for (let item of this.trackPath){
      this.drawStroke(item);
    }
  }

  canvasClear(){
    //var canvasPosition = this.canvasElement.getBoundingClientRect();
    let context = this.canvasElement.getContext('2d');
    context.clearRect(0,0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.trackPath = [];
  }

  canvasImage(){
    // try{
    //   var dataUrl = this.canvasElement.toDataURL();
    //   let context = this.canvasElement.getContext('2d');
    //   context.clearRect(0,0, context.canvas.width, context.canvas.height);
    //   let name = new Date().getTime() + '.png';
    //   let path = this.file.dataDirectory;
    //   let options : IWriteOptions = { replace: true, }
    //
    //   var data = dataUrl.split(',')[1];
    //   let blob = this.b64toBlob(data, 'image/png');
    //
    //   this.file.writeFile(path, name, blob, options).then(res => {
    //     this.storeImage(name);
    //     this.mc.dismiss();
    //   }, err => {
    //     console.log('error: ', err);
    //     this.mc.dismiss();
    //   })
    // } catch(err){
    //   this.mc.dismiss();
    // }

    var dataUrl = this.canvasElement.toDataURL();
    let context = this.canvasElement.getContext('2d');
    context.clearRect(0,0, context.canvas.width, context.canvas.height);

    var data = dataUrl.split(',')[1];
    let blob = this.b64toBlob(data, 'image/png');

    this.storeImage(blob);
    this.mc.dismiss();
  }

  b64toBlob(b64Data, contentType){
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize){
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++){
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  storeImage(imgBlob){
    // let filePath = this.file.dataDirectory + imageName;
    // let resPath = this.pathForImage(filePath);
    let saveObj = {
      consent: { path: this.trackPath },
      final: null,
    };

    if (this.root != null){
      // this.root.ttd = saveObj;
    } else if (this.root2 != null){
      // this.root2.ttd = saveObj;
    }
    this.storedImages.push(saveObj);
    this.auth.session_perawatan_data.ttd = saveObj;
    this.imgBlob = imgBlob;
    this.doTempSave();

    this.storage.set(this.keyToUse, this.storedImages);
  }

  pathForImage(img){
    if (img == null){
      return '';
    } else {
      if (this.plt.is('cordova')){
        const url = this.webview.convertFileSrc(img);
        return this.sanitizer.bypassSecurityTrustUrl(url);
      } else {
        let converted = this.webview.convertFileSrc(img);
        return converted;
      }
    }
  }

  removeImageAtIndex(index){
    let removed = this.storedImages.splice(index, 1);
    this.file.removeFile(this.file.dataDirectory, removed[0].img).then(res => {
    }, err => {
      console.log('remove err; ' ,err);
    });
    this.storage.set(this.keyToUse, this.storedImages);
  }

  doTempSave(){
    let idklinik = this.auth.ACTIVE_PATIENT_KLINIK;
    let idpenjadwalan = this.auth.ACTIVE_PATIENT_PENJADWALAN;
    let data = this.auth.session_perawatan_data;
    let is_posted = '0';
    let post_error_encounter = '0';

    this.api.apiPerawatanSaveTemp(idklinik, idpenjadwalan, data, this.imgBlob, this.auth.tmpConsent, is_posted, post_error_encounter, this.auth.USER_TOKEN).subscribe(res => {
      this.auth.generateRandom();
      this.auth.getPerawatanTemp();
    });
  }
}
