import { Component } from '@angular/core';

import { Platform, AlertController, ModalController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Network } from '@ionic-native/network/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PopupperawatanPage } from 'src/app/pages/modals/popupperawatan/popupperawatan.page';
import { versiV1Response } from 'src/app/services/api/api-response';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})

export class AppComponent {

  public connection: boolean = navigator.onLine;
  connection_status = 0;
  connection_status_before = 0;
  statuses = [
    {
      text: 'Tidak ada jaringan',
      class: 'no-internet',
    }, {
      text: 'Telah terhubung jaringan',
      class: 'yes-internet',
    }, {
      text: '',
      class: 'hide'
    }
  ]
  onDevice: boolean;
  dc: any;
  cc: any;

  versiV1: versiV1Response = null;
  cVersion: string = '1.0.0';

  constructor(
    public auth: AuthService,
    private api: ApiService,
    private platform: Platform,
    private statusBar: StatusBar,
    private network: Network,
    private ac: AlertController,
    private app_ver: AppVersion,
    private mc: ModalController,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.onDevice = this.platform.is('cordova');
      if (!this.onDevice){
        if (this.platform.is('cordova')){
          this.statusBar.styleLightContent();
          //this.splashScreen.hide();
          this.getLatestVer();
        }

        if (navigator.onLine){
          this.connection_status = 1;
          this.connection_status_before = 0;
        } else {
          this.connection_status = 0;
          this.connection_status_before = 1;
        }
        this.checkConnectivity();

        window.addEventListener('online', ()=> {
          this.connection_status = 1;
          this.connection_status_before = 0;
          this.checkConnectivity();
        })

        window.addEventListener('offline', ()=> {
          this.connection_status = 0;
          this.connection_status_before = 1;
          this.checkConnectivity();
        })
      } else {
        this.connection_status = 0;
        if (this.network.type !== 'none'){
          this.connection_status = 1;
          this.connection_status_before = 0;
        }
        this.dc = this.network.onDisconnect().subscribe(()=> {
          this.connection_status = 0;
          this.connection_status_before = 1;
          this.checkConnectivity();
        })
        this.cc = this.network.onConnect().subscribe(()=> {
          this.connection_status = 1;
          this.connection_status_before = 0;
          this.checkConnectivity();
        })
        this.checkConnectivity();
      }
    });
  }

  checkConnectivity(){
    let element = document.getElementById("connection-bar");
    for (let item of this.statuses){
      element.classList.remove(item.class);
    }
    if (this.connection_status != this.connection_status_before){
      element.classList.add(this.statuses[this.connection_status].class);
      element.innerHTML = this.statuses[this.connection_status].text;
    }
    if (this.connection_status == 1){
      setTimeout(() => {
        this.connection_status = 2;
        this.checkConnectivity();
      }, 2000);
    }
  }

  getLatestVer(){
    this.app_ver.getVersionNumber().then(res => {
      this.cVersion = res;
      this.api.apiVersionCheckV1(res).subscribe(xres => {
        this.versiV1 = xres;
        this.versionCheck();
      }, err => {
        this.auth.showToast('Currently running development version.');
      })
    })
  }

  code2Num(code: string){
    let codes = code.split('.');
    let num = 0;
    if (codes.length == 3){
      num += parseInt(codes[0]);
      num += parseInt(codes[1]) * 100;
      num += parseInt(codes[2]) * 10000;
    }
    return num;
  }

  async versionCheck(){
    let c_app_vernum = this.code2Num(this.cVersion);
    let a_app_vernum = this.code2Num(this.versiV1.version);
    if (c_app_vernum < a_app_vernum){
      if (this.versiV1.status === '2'){
        const alert = await this.ac.create({
          header: 'Pemberitahuan',
          message: 'Silahkan lakukan update untuk tetap menggunakan aplikasi!',
          buttons: [
            {
              text: 'OK',
              handler: ()=> {
                navigator['app'].exitApp();
              }
            }
          ]
        })
        await alert.present();
      } else if (this.versiV1.status === '1'){
        const alert = await this.ac.create({
          header: 'Pemberitahuan',
          message: 'Update baru tersedia. Sangat menyarankan untuk mengupdate aplikasi.',
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
            }
          ]
        })
        await alert.present();
      }
    }
  }

  getWaktuSisa(){
    let h = 0;
    let m = 0;
    let s = 0;

    if (this.auth.waktuPemeriksaan > 0){
      let second = this.auth.waktuPemeriksaan;
      h = Math.floor(second / 3600);
      m = Math.floor((second % 3600) / 60);
      s = Math.floor((second % 3600) % 60);
    }

    if (s > 0 && m == 0){
      m = 1;
    }

    let ph = (h < 10) ? '0' + h : h;
    let pm = (m < 10) ? '0' + m : m;
    let ps = (s < 10) ? '0' + s : s;

    // return ph + ':'  + pm + ':' + ps;

    return ph + ':'  + pm;
  }

  getClassWaktu(){
    if (this.auth.waktuPemeriksaan > 0){
      let nm = Math.floor(((this.auth.waktuPemeriksaan % 3600) / 60)) + (Math.floor(this.auth.waktuPemeriksaan / 3600) * 60);
      if (nm >= 10){
        return 'hijau';
      } else if (nm >= 5){
        return 'kuning';
      }
      return 'merah';
    }
    return 'gelap';
  }

  async openPerawatan(action, id = 0) {
    const isModalOpened = await this.mc.getTop();
    if (isModalOpened) {

    } else {
      const modal = await this.mc.create({
        component: PopupperawatanPage,
        cssClass: 'extendpenjadwalan',
        componentProps: {
          id: id,
          action: action,
          root: this,
        }
      });
      return await modal.present();
    }
  }
}
