import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import Pusher from 'pusher-js';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-catatandokter',
  templateUrl: './catatandokter.page.html',
  styleUrls: ['./catatandokter.page.scss'],
})
export class CatatandokterPage implements OnInit {
  @ViewChild('content') content: any;
  dataCatatan = [];

  displayCatatan : any[] = null;
  isLoadingCatatan = true;
  idDetailShow = 0;
  isShowForm = false;
  fCatatan = "";

  isFirst: boolean = true;

  activeChannel: any = null;
  currentHeight = 0;

  constructor(
    public auth: AuthService,
    private api: ApiService,
    private mc: ModalController,
    private ac: AlertController,
    private zone: NgZone,
  ) { }

  ngOnInit() {
    this.setPusher();
    this.getDataCatatan();
  }

  loadCatatan(){
    setTimeout(()=>{
      if (this.isLoadingCatatan){
        this.loadCatatan();
      } else {
        if (this.isFirst){
          this.isFirst = false;
          this.displayCatatan = [''];
        }
        this.zone.run(() => {
          this.displayCatatan = JSON.parse(JSON.stringify(this.dataCatatan));
          this.scrollToBottom();
        });
      }
    }, 300);
  }

  getDataCatatan(){
    this.api.apiGetCatatanDokter(this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res => {
      this.dataCatatan = [];
      for (let catatan of res.data){
        this.dataCatatan.push({
          id : catatan.idchat.toString(),
          dokter : catatan.namadokter,
          text : catatan.pesan,
          tgl : this.auth.getTanggal(catatan.tanggal),
          waktu : catatan.waktu.substr(0, 5),
          gambar: catatan.gambar,
          ishapus: catatan.ishapus,
          iddokterpengirim: catatan.iddokterpengirim,
          idpetugaspengirim: catatan.idpetugaspengirim,
          status: catatan.status,
        });
      }
      this.isLoadingCatatan = false;

      this.loadCatatan();
    })
  }

  setPusher(){
    var pusher = new Pusher('f214c3bd2b3250eec924', {
      cluster: 'ap1',
      forceTLS: true
    });

    var channel = pusher.subscribe('pasien-channel');
    let that = this;
    channel.bind('pasien-event', function(data) {
      that.getDataCatatan();
    });

    this.activeChannel = channel;
  }

  async hapusCatatan(idCatatan){
    const alert = await this.ac.create({
      header : "Konfirmasi",
      message : "Yakin ingin menghapus catatan ini?",
      buttons : [{
        text : "Ya",
        handler : ()=>{
          //Hapus
          this.api.apiDeleteCatatanDokterV2(this.auth.ACTIVE_PATIENT, idCatatan, this.auth.USER_TOKEN).subscribe(res => {
            if (res.success){
              this.auth.session_checkup_data.catatandokter.changed = true;
              this.auth.showToast('Berhasil menghapus catatan');
              this.getDataCatatan();
              this.loadCatatan();
            } else {
              this.auth.showToast('Gagal menghapus catatan');
            }
          })
        }
      },{
        text : "Tidak",
        role : 'cancel',
      }]
    });
    await alert.present();
  }

  scrollToBottom(){
    var el = document.getElementById('catatan-timeline');

    if (el != null){
      if (this.currentHeight <= el.clientHeight){
        this.currentHeight = el.clientHeight;
        setTimeout(() => {
          this.content.scrollToPoint(0, el.scrollHeight);
        }, 100);
      } else {
        setTimeout(() => {
          this.scrollToBottom();
        }, 500);
      }
    } else {
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
    }
  }

  toggleForm() {
    if (this.isShowForm) {
      this.isShowForm = false;
    } else {
      this.isShowForm = true;
    }
  }

  toggleDetail(id :number){
    if (this.idDetailShow == id){
      this.idDetailShow = 0;
    } else {
      this.idDetailShow = id;
    }
  }

  async submit(form){
    let formData = new FormData();

    formData.append('idpasien', this.auth.ACTIVE_PATIENT);
    // formData.append('foto', this.auth.ACTIVE_PATIENT);
    formData.append('pesan', this.fCatatan);

    this.api.apiPostCatatanDokterV2(formData, this.auth.USER_TOKEN).subscribe(res=>{
      if (res.success){
        this.auth.session_checkup_data.catatandokter.changed = true;
        this.fCatatan = "";
        this.getDataCatatan();
      }
      this.isLoadingCatatan = true;
    })
  }

  close(){
    if (this.activeChannel != null){
      this.activeChannel.disconnect();
    }
    this.mc.dismiss();
  }

}
