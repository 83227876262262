import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { CalendarComponent } from 'ionic2-calendar/calendar';
import { DayViewComponent } from 'ionic2-calendar/dayview';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';
import { PenjadwalanPage } from 'src/app/pages/perawatan/penjadwalan/penjadwalan.page';
import { fullDayResponse, kliniksResponse } from 'src/app/services/api/api-response';

@Component({
  selector: 'app-popuppenjadwalan',
  templateUrl: './popuppenjadwalan.page.html',
  styleUrls: ['./popuppenjadwalan.page.scss'],
})
export class PopuppenjadwalanPage implements OnInit {

  mode: number = 1;

  minDate = new Date().toISOString();
  eventSource = [];
  viewTitle;

  event = {
    title: '',
    desc: '',
    startTime: '',
    endTime: '',
    allDay: false
  };

  calendar = {
    mode: 'month',
    currentDate: new Date(),
    locale: 'id',
  };
  autoSelect = true;

  jadwals = [];

  todaySchedules: fullDayResponse = null;
  new_klinik: string = '3';

  idklinik: string = null;
  idpenjadwalan: string = null;

  kliniks: kliniksResponse = null;
  jams = [{ text: "", value: 0 }]

  textualValue = {
    nama: '',
    namaklinik: '',
    jam: '',
    jamselesai: '',
    tanggal: '',
  }

  eventRequested: boolean = false;
  requestedDate: string = new Date().toISOString();

  markDisabled = (date: Date) => {
    return date < new Date(this.minDate);
  }
  selectedDate = new Date().toISOString();
  root: PenjadwalanPage = null;

  @ViewChild(CalendarComponent) myCal: CalendarComponent;
  @ViewChild(DayViewComponent) calDay: DayViewComponent;

  gettingEvent: boolean = false;
  dateChosen: boolean = false;

  constructor(
    public auth: AuthService,
    private params: NavParams,
    private mc: ModalController,
    private ac: AlertController,
    private api: ApiService,
    private zone: NgZone,
  ) { }

  ngOnInit() {
    this.setMinDate();
    this.getJadwalKosong();
    this.getListKlinik();
    if (this.params.data.root != null){
      this.root = this.params.data.root;
    }
    if (this.params.data.mode != null){
      this.mode = this.params.data.mode;
    } else {
      this.mode = 1;
    }

    if (this.params.data.idklinik != null){
      this.idklinik = this.params.data.idklinik;
    }
    if (this.params.data.idpenjadwalan != null){
      this.idpenjadwalan = this.params.data.idpenjadwalan;
    }
    if (this.params.data.tanggalpenjadwalan != null){
      this.selectedDate = this.params.data.tanggalpenjadwalan;
      this.calendar.currentDate = new Date(this.selectedDate);
    }

    //this.getDatedJadwal(this.minDate);
    if (this.auth.DOKTER_KLINIK != null && parseInt(this.auth.DOKTER_KLINIK) != 0){
      if (this.idklinik != null){
        this.new_klinik = this.idklinik + '';
      } else {
        this.new_klinik = this.auth.DOKTER_KLINIK;
      }
      console.log(this.new_klinik);
    } else {
      this.new_klinik = '0';
    }
  }

  getDatedJadwal(tgl){
    let dt = new Date(tgl);
    let tmp = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
    this.todaySchedules = null;
    this.eventSource = [];
    this.api.apiFullDayJadwal(this.new_klinik, tmp, this.auth.USER_TOKEN).subscribe(res => {
      this.todaySchedules = res;
      for (let item of res.data){
        let start = new Date(item.tanggalpenjadwalan);
        let end = new Date(item.tanggalpenjadwalan);
        let startTimeText = this.kode2Hours(item.waktumulaipenjadwalan - 1);
        let endTimeText = this.kode2Hours(item.waktuselesaipenjadwalan);

        start.setHours(0,0,0,0);
        end.setHours(0,0,0,0);
        let starter = {
          hour: parseInt(startTimeText.split(':')[0]),
          minute: parseInt(startTimeText.split(':')[1]),
        }
        let ender = {
          hour: parseInt(endTimeText.split(':')[0]),
          minute: parseInt(endTimeText.split(':')[1]),
        }
        start.setHours(starter.hour, starter.minute, 0, 0);
        end.setHours(ender.hour, ender.minute, 0, 0);

        this.eventSource.push({
          title: item.pasien.nama,
          desc: '',
          startTime: start,
          endTime: end,
          allDay: false
        });
      }
      this.myCal.loadEvents();
    })
  }

  getListKlinik(){
    this.api.apiGetKlinikList(this.auth.USER_TOKEN).subscribe(res => {
      this.zone.run(()=> {
        this.kliniks = res;
      })
    })
  }

  getJadwalKosong(){
    this.jadwals = [];
    this.gettingEvent = true;
    let dt = new Date(this.selectedDate);
    let x = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
    if (this.new_klinik != '0'){
      this.api.apiGetJadwalKosongV2(this.new_klinik, x, this.auth.USER_TOKEN).subscribe(res => {
        for (let item of res.data){
          let status = (item.status == 3) ? 'active' : (item.status == 2) ? 'unavailable' : 'available';
          this.jadwals.push({
            id: item.kodepenjadwalan,
            time: item.keterangan.split('-')[0],
            status: status,
          })
        }
        this.gettingEvent = false;
      }, err => {
        this.generateJam();
      })
    }
  }

  generateJam(){
    this.jams = [];
    const dayInMinutes = 24 * 60;
    for (let i = 1; i < dayInMinutes / 15; i++){
      let minutes = (i * 15) % 60;
      let hours = Math.floor((i * 15) / 60);
      if (minutes % 30 == 0){
        this.jams.push({value: i + 1, text: this.getWaktu(hours + ':' + minutes)});
      }
    }
  }

  async submit() {
    const alert = await this.ac.create({
      header: 'Konfirmasi Buat Jadwal',
      message: 'Apakah Anda yakin?',
      buttons: [
        {
          text: 'Batalkan',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Ya',
          handler: () => {
            this.mc.dismiss();
          }
        }
      ]
    });

    await alert.present();
  }

  // Change current month/week/day
  next() {
    const swiper = document.querySelector('.swiper-container')['swiper'];
    swiper.slideNext();
  }

  back() {
    const swiper = document.querySelector('.swiper-container')['swiper'];
    swiper.slidePrev();
  }

  // Change between month/week/day
  changeMode(mode) {
    this.calendar.mode = mode;
    this.myCal.loadEvents();
  }

  // Selected date reange and hence title changed
  onViewTitleChanged(title) {
    if (this.calendar.mode == 'day'){
      this.viewTitle = this.auth.getTanggalv2(this.selectedDate);
    } else {
      this.viewTitle = title;
    }
  }

  onCurrentDateChanged(event) {

  }

  // Calendar event was clicked
  async onEventSelected(event) {
    let tgl = event.startTime;
    let tgl2 = event.endTime;
    let t_tgl = tgl.getDate() + ' ' + this.auth.months[tgl.getMonth()] + ' ' + tgl.getFullYear();
    let mulai = tgl.getHours() + ':' + tgl.getMinutes();
    let selesai = tgl2.getHours() + ':' + tgl2.getMinutes();

    const alert = await this.ac.create({
      header: event.title,
      subHeader: event.desc,
      message: 'Klinik: Mobile <br> Tanggal: '+ t_tgl +' <br> Waktu: '+ mulai +' - '+ selesai +'',
      buttons: ['OK']
    });
    alert.present();
  }

  onSelectTime(jadwal) {
    const selectedJadwal = this.jadwals.find(data => data.id == jadwal.id);
    if (selectedJadwal.status == 'available') {
      this.jadwals.find(data => data.id == jadwal.id).status = 'fill';
    } else if (selectedJadwal.status == 'fill') {
      this.jadwals.find(data => data.id == jadwal.id).status = 'available';
    } else {
      this.auth.showToast('Waktu ini tidak tersedia.');
    }
  }

  // Time slot was clicked
  onTimeSelected(ev) {
    const selected = new Date(ev.selectedTime);
    if (!this.eventRequested){
      this.eventRequested = true;
      if (this.mode == 1){
        this.requestedDate = ev.selectedTime;
        this.selectedDate = ev.selectedTime;
      } else if (this.mode == 2){
        this.calendar.currentDate = new Date(this.selectedDate);
        this.requestedDate = this.selectedDate;
      }
      this.getDatedJadwal(ev.selectedTime);
      this.getJadwalKosong();
    } else if (ev.selectedTime != this.requestedDate){
      this.requestedDate = ev.selectedTime;
      this.selectedDate = ev.selectedTime;
      this.getDatedJadwal(ev.selectedTime);
      this.getJadwalKosong();
    }
    this.dateChosen = true;
  }

  kode2Hours(kode: number){
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    today.setMinutes((kode) * 15);
    return this.getHours(today);
  }

  getHours(time: Date): string{
    if (time){
      let s = time.getHours() + ':' + time.getMinutes();
      let splitted = s.split(':');
      let h: string = ((parseInt(splitted[0])) < 10) ? '0' + (parseInt(splitted[0])).toString() : (parseInt(splitted[0])).toString();
      let m: string = (parseInt(splitted[1]) < 10) ? '0' + parseInt(splitted[1]).toString() : parseInt(splitted[1]).toString();
      return h + ':' + m;
    }
    return '00:00';
  }

  getWaktu(str: string){
    let splitted = str.split(':');
    let h: string = ((parseInt(splitted[0])) < 10) ? '0' + (parseInt(splitted[0])).toString() : (parseInt(splitted[0])).toString();
    let m: string = (parseInt(splitted[1]) < 10) ? '0' + parseInt(splitted[1]).toString() : parseInt(splitted[1]).toString();
    return h + ':' + m;
  }

  capitalize(str: string){
    let splitted = str.split(' ');
    let res = [];
    for (let item of splitted){
      item = item.charAt(0).toUpperCase() + item.substring(1, item.length);
      res.push(item);
    }
    return res.join(' ');
  }

  setMinDate() {
    let today = new Date();
    if (today.getHours() >= 15) {
      this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, today.getHours()).toISOString();
      if (this.mode == 1){
        this.selectedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2, today.getHours()).toISOString();
      }
    } else {
      this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours()).toISOString();
      if (this.mode == 1){
        this.selectedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, today.getHours()).toISOString();
      }
    }
    this.calendar.currentDate = new Date(this.selectedDate);
  }

  generateTextual(mulai, selesai){

    for (let item of this.kliniks.data){
      if (item.idklinik == parseInt(this.new_klinik)){
        this.textualValue.namaklinik = this.capitalize(item.namaklinik);
      }
    }
    this.textualValue.jam = this.kode2Hours(mulai);
    this.textualValue.jamselesai = this.kode2Hours(selesai);
    this.textualValue.tanggal = this.auth.getTanggalv2(this.selectedDate);
  }

  batal(){
    if (this.calendar.mode == 'day'){
      this.calendar.mode = 'month';
    } else {
      this.mc.dismiss();
    }
  }

  async nextF(){
    if (this.calendar.mode == 'month'){
      let today = new Date();
      let dt = new Date(this.selectedDate);
      let c_dt = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, today.getHours());
      let diff = Math.floor((Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()) - Date.UTC(c_dt.getFullYear(), c_dt.getMonth(), c_dt.getDate()) ) /(1000 * 60 * 60 * 24));
      if (diff < 0){
        let alert = await this.ac.create({
          header: 'Pemberitahuan',
          message: 'Pilih tanggal yang valid!',
          buttons: ['OK'],
        });
        await alert.present();
      } else {
        this.calendar.mode = 'day';
      }
    } else {
      let start = 0;
      let end = 0;
      let jumpFound: boolean = false;

      for (let item of this.jadwals){
        if (item.status == 'fill'){
          if (start == 0){
            start = item.id;
            end = item.id;
          } else if (start != 0 && start < item.id){
            end = item.id;
          }
        }
      }
      for (let item of this.jadwals){
        if (item.id > start && item.id < end){
          if (item.status == 'unavailable' || item.status == 'available'){
            jumpFound = true;
          }
        }
      }

      let dtx = new Date(this.selectedDate);
      let tmp = dtx.getFullYear() + '-' + (dtx.getMonth() + 1) + '-' + dtx.getDate();

      if (start == 0 && end == 0){
        const alert = await this.ac.create({
          header: 'Pemberitahuan',
          message: 'Pilih minimal 1 waktu!',
          buttons: ['OK'],
        });
        await alert.present();
      }
      else if (!jumpFound){
        if (this.mode == 1){
          this.generateTextual(start, end);
          const alert = await this.ac.create({
            header: 'Konfirmasi Data',
            message: '' +
            '<small><strong>Klinik</strong></small><p class="ion-no-margin-top">'+ this.textualValue.namaklinik +'</p> ' +
            '<small><strong>Tanggal</strong></small><p class="ion-no-margin-top">'+ this.textualValue.tanggal +'</p> ' +
            '<small><strong>Waktu</strong></small><p class="ion-no-margin-top">'+ this.textualValue.jam + '-' + this.textualValue.jamselesai + '</p> ',
            buttons: [
              {
                text: 'Tidak',
                role: 'cancel',
                cssClass: 'secondary',
              }, {
                text: 'Ya',
                handler: () => {
                  this.api.apiCreateJadwal(this.new_klinik, this.auth.ACTIVE_PATIENT, tmp, start, end, this.auth.USER_TOKEN).subscribe(res => {
                    this.auth.showToast(res.message);
                    this.auth.session_checkup_data.penjadwalan.changed = true;
                    if (res.success){
                      this.root.getBerikutnya();
                      this.mc.dismiss();
                    } else {

                    }
                  })
                }
              }
            ]
          });
          await alert.present();
        } else if (this.mode == 2){
          this.generateTextual(start, end);
          const alert = await this.ac.create({
            header: 'Konfirmasi Perpindahan',
            message: '' +
              '<strong>Klinik</strong><p class="ion-no-margin-top">' + this.textualValue.namaklinik + '</p> ' +
              '<strong>Tanggal</strong><p class="ion-no-margin-top">' + this.textualValue.tanggal + '</p> ' +
              '<strong>Pukul</strong><p class="ion-no-margin-top">' + this.textualValue.jam + '-' + this.textualValue.jamselesai +'</p> ',
            buttons: [
              {
                text: 'Batal',
                role: 'cancel',
                cssClass: 'secondary',
              }, {
                text: 'Ok',
                handler: () => {
                  this.api.apiPindahJadwal(this.idklinik, this.idpenjadwalan, tmp,
                    start, end, this.new_klinik, this.auth.USER_TOKEN).subscribe(res => {
                      this.auth.showToast(res.message);
                      this.root.getBerikutnya();
                      this.mc.dismiss();
                    })
                }
              }
            ]
          });

          await alert.present();
        }

      } else {
        const alert = await this.ac.create({
          header: 'Peringatan',
          message: 'Tidak bisa membuat janji karena ada loncatan',
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
            }
          ]
        })
        await alert.present();
      }
    }
  }

  resetWaktu(){
    this.getJadwalKosong();
    this.getDatedJadwal(this.selectedDate);
  }

  checkDate(){
    if (this.auth.allowModifyData || this.auth.fromSE){
      if (this.dateChosen){
        if (this.jadwals.length == 0 && !this.gettingEvent){
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getTitle(){
    if (this.mode == 1){
      return 'Buat Jadwal Baru';
    } else if (this.mode == 2){
      return 'Pindahkan Jadwal';
    }
  }
}
