import { Component, OnInit, ViewChild, NgZone, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from "@angular/router";
import { AlertController, IonContent, IonSlides, ModalController, NavController, NavParams, LoadingController, IonInfiniteScroll } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupnotesperawatanPage } from 'src/app/pages/modals/popupnotesperawatan/popupnotesperawatan.page';
import { PerawatanPage } from 'src/app/pages/perawatan/perawatan/perawatan.page';
import { DaftarhargaPage } from "../../perawatan/daftarharga/daftarharga.page";
import { DaftarasuransiPage } from "../../perawatan/daftarasuransi/daftarasuransi.page";
import { DaftarvoucherPage } from "../../perawatan/daftarvoucher/daftarvoucher.page";
import { DaftaredukasiPage } from "../../perawatan/daftaredukasi/daftaredukasi.page";
import { PopupinfoperawatanPage } from "src/app/pages/modals/popupinfoperawatan/popupinfoperawatan.page";

@Component({
  selector: 'app-popupperawatan',
  templateUrl: './popupperawatan.page.html',
  styleUrls: ['./popupperawatan.page.scss'],
})
export class PopupperawatanPage implements OnInit {

  actionForm = 'pemeriksaan';
  pemeriksaanPeriodontal = 'buccal';
  selectedContent = 0;
  sliderConfig = {
  };

  extendJadwal = [];
  jadwals = [
    {
      id: 1,
      start: '07.00',
      end: '07.15',
      status: 'active',
      idstatus: 1,
    },
    {
      id: 2,
      start: '07.15',
      end: '07.30',
      status: 'active',
      idstatus: 1,
    },
    {
      id: 3,
      start: '07.30',
      end: '07.45',
      status: 'available',
      idstatus: 3,
    },
    {
      id: 4,
      start: '07.45',
      end: '08.00',
      status: 'available',
      idstatus: 3,
    },
    {
      id: 5,
      start: '08.00',
      end: '08.15',
      status: 'available',
      idstatus: 3,
    },
  ];

  kondisis = [
    {
      id: 1,
      text: 'Karies',
    }, {
      id: 2,
      text: 'Tambal Sementara',
    }
  ];

  perawatansPK: any = []
  perawatansT: any = []
  diagnosasT: any = []

  diagnosas: any = []
  tmpDiagnosas: any = JSON.parse(JSON.stringify(this.diagnosas));

  prognosas: any = []
  tmpPrognosas: any = JSON.parse(JSON.stringify(this.prognosas));

  perawatans: any = []
  tmpPerawatans: any = JSON.parse(JSON.stringify(this.perawatans));

  perawatanICDs: any = []
  tmpPerawatanICDs: any = JSON.parse(JSON.stringify(this.perawatanICDs));

  tindakans: any = []
  tmpTindakans: any = JSON.parse(JSON.stringify(this.tindakans));

  activeKondisi = 0;
  activeObservasi = [];
  activeDiagnosa = [];
  activePrognosa = [];
  activePerawatan = [];
  activePerawatanICD = [];
  activePerawatanPK = [];
  activePerawatanPKStatus = 0;
  activeTindakan = [];
  activePerawatanT: {id: any, idpk: any}[] = [];
  activeDiagnosaT: {id: any, idpk: any}[] = [];

  showKlinisLainnya = false;
  klinisLainnya = '';

  searchDiagnosa = '';
  isSearchingDiagnosa = false;
  showDiagnosaLainnya = false;
  diagnosaLainnya = '';

  searchPrognosa = '';
  isSearchingPrognosa = false;
  showPrognosaLainnya = false;
  prognosaLainnya = '';

  searchPerawatan = '';
  isSearchingPerawatan = false;
  showPerawatanLainnya = false;
  perawatanLainnya = '';

  searchPerawatanICD = '';
  isSearchingPerawatanICD = false;
  showPerawatanICDLainnya = false;
  perawatanICDLainnya = '';

  searchTindakan = '';
  isSearchingTindakan = false;
  showTindakanLainnya = false;
  tindakanLainnya = '';
  hargaTindakanLainnya = '';
  hargaTindakanLainnyaTxt = '';

  searchObservasi = '';
  isSearchingObservasi = false;
  observasis: any = []
  tmpObservasis: any = JSON.parse(JSON.stringify(this.observasis));

  showTopOptions: boolean = true;

  dataGigi = [];
  displayDataGigi = [];
  isLoadingGigi: boolean = true;

  actClass: any[] = [];
  zoneActClass: any[] = [];

  multiId: number[] = [];
  multiZone: number[][] = [];
  allSusuDark = false;
  opsi = {
    dewasa: [
      {
        key: [1, 2, 3, 4, 5, 6, 7, 8],
        value: ['Incisive Central', 'Incisive Central', 'Caninus',
        'Premolar Pertama', 'Premolar Kedua', 'Molar Pertama',
        'Molar Kedua', 'Molar Ketiga'],
      }, {
        key: [1, 2, 3, 4],
        value: ['Maksila Kanan', 'Maksila Kiri', 'Mandibula Kiri', 'Mandibula Kanan'],
      }
    ],
    susu: [
      {
        key: [1, 2, 3, 4, 5],
        value: ['Incisive Central', 'Incisive Central', 'Caninus',
        'Molar Pertama', 'Molar Kedua'],
      }, {
        key: [5, 6, 7, 8],
        value: ['Maksila Kanan', 'Maksila Kiri', 'Mandibula Kiri', 'Mandibula Kanan'],
      }
    ]
  }

  idEdukasi = 0;
  edukasi: any = null;

  changes: {
    id: number,
    auto: boolean,
  }[] = []

  autoDone = [];

  gigiActive: any = null;
  gigiActClass = []

  root: any = null;

  klinisOralLainnya: string = '';
  klinisPeriLainnya: string = '';

  searchTimeout: any = null;

  firstCall: boolean = true;
  activePage: number = 1;
  tmpList: any = null;
  isLoadingList: boolean = false;

  disabledOptions: any = [];

  @ViewChild(IonSlides) slider: IonSlides;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  constructor(
    public auth: AuthService,
    private nav: NavController,
    private params: NavParams,
    private mc: ModalController,
    private ac: AlertController,
    private api: ApiService,
    private zone: NgZone,
    private lc: LoadingController,
    private router: Router,
    private iab: InAppBrowser,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    this.actionForm = this.params.data.action;
    if (this.params.data.root){
      this.root = this.params.data.root;
    }
    this.loadActClass();

    if (this.actionForm == 'observasi'){
      this.setObservasi(true);
    } else if (this.actionForm == 'pemeriksaan'){
      this.refresh();
    } else if (this.actionForm == 'diagnosa'){
      this.setDiagnosa(true);
      this.setPerawatanPK();
    } else if (this.actionForm == 'prognosa'){
      this.setPrognosa(true);
      this.setPerawatanPK();
      this.setDiagnosaT();
    } else if (this.actionForm == 'rencana'){
      this.setPerawatan(true);
      this.setPerawatanPK();
    } else if (this.actionForm == 'icd'){
      this.setPerawatanICD(true);
      this.setPerawatanPK();
    } else if (this.actionForm == 'tindakan'){
      this.setTindakan(true);
      this.setPerawatanPK();
      this.setPerawatanT();
    } else if (this.actionForm == 'edukasi'){
      this.idEdukasi = this.params.data.id;
      this.setEdukasi();
    } else if (this.actionForm == 'konfirmasi-perubahan'){
      this.setChanges();
    } else if (this.actionForm == 'penjadwalan'){
      this.getJadwals();
    }
  }

  loadData(event) {
    this.tmpList = null;
    setTimeout(() => {
      if (this.actionForm == 'observasi'){
        if (this.searchObservasi.length > 0){
          this.doSearchObservasi();
        } else {
          this.setObservasi();
        }
      } else if (this.actionForm == 'diagnosa'){
        if (this.searchDiagnosa.length > 0){
          this.doSearchDiagnosa();
        } else {
          this.setDiagnosa();
        }
      } else if (this.actionForm == 'prognosa'){
        if (this.searchPrognosa.length > 0){
          this.doSearchPrognosa();
        } else {
          this.setPrognosa();
        }
      } else if (this.actionForm == 'rencana'){
        if (this.searchPerawatan.length > 0){
          this.doSearchPerawatan();
        } else {
          this.setPerawatan();
        }
      } else if (this.actionForm == 'icd'){
        if (this.searchPerawatanICD.length > 0){
          this.doSearchPerawatanICD();
        } else {
          this.setPerawatanICD();
        }
      } else if (this.actionForm == 'tindakan'){
        if (this.searchTindakan.length > 0){
          this.doSearchTindakan();
        } else {
          this.setTindakan();
        }
      }
      this.checkList(event);
    }, 100)
  }

  checkList(event: any){
    if (!this.isLoadingList && this.tmpList != null){
      if (this.tmpList.data.length < 10){
        event.target.disabled = true;
      } else {
        this.activePage++;
      }
      event.target.complete();
    } else {
      setTimeout(() => {
        this.checkList(event);
      }, 100);
    }
  }

  getJadwals(){
    this.jadwals = [];
    this.api.apiJadwal12(this.auth.DOKTER_KLINIK, this.auth.USER_TOKEN).subscribe(res => {
      let i = 0;
      for (let item of res.data){
        if (i < 6){
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          today.setMinutes((item.kode - 1) * 15);
          let start = this.getHours(today);
          today.setHours(0, 0, 0, 0);
          today.setMinutes((item.kode) * 15);
          let end = this.getHours(today);
          this.jadwals.push({
            id: item.kode,
            start: start,
            end: end,
            status: (item.status == 1) ? 'active' : (item.status == 2) ? 'unavailable' : '',
            idstatus: item.status,
          });
        }
        i++;
      }
    })
  }

  resetExtend() {
    this.extendJadwal.forEach(value => {
      this.jadwals.find(data => data.id == value.id).idstatus = 3;
      this.jadwals.find(data => data.id == value.id).status = 'available';
    });
    this.extendJadwal = [];
  }

  async confirmExtend() {
    let start = 0;
    let end = 0;
    let jumpFound: boolean = false;
    let nonExtendable: boolean = false;

    for (let item of this.jadwals){
      if (item.idstatus == 1){
        if (start == 0){
          start = item.id;
          end = item.id;
        }
      }
      else if (item.idstatus == 4){
        if (start != 0 && start < item.id){
          end = item.id;
        }
      }
    }

    if (start == 0 && end == 0){
      nonExtendable = true;
    }


    for (let item of this.jadwals){
      if (item.id > start && item.id < end){
        if (item.idstatus == 2 || item.idstatus == 3){
          jumpFound = true;
        }
      }
    }

    if (nonExtendable){
      const alert = await this.ac.create({
        header: 'Peringatan',
        message: 'Tidak bisa memperpanjang karena sesi telah berakhir',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
          }
        ]
      })
      await alert.present();
    } else if (!jumpFound){
      let waktuTambahan = 15 * this.extendJadwal.length;
      const alert = await this.ac.create({
        header: 'Konfirmasi Perpanjangan',
        message: 'Anda akan memperpanjang sesi pasien selama ' + waktuTambahan + ' menit. Lanjutkan?',
        buttons: [
          {
            text: 'Tidak',
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: 'Ya',
            handler: () => {
              this.api.apiExtendJadwal(this.auth.DOKTER_KLINIK, end, this.auth.USER_TOKEN).subscribe(res => {
                this.auth.showToast(res.message);

                //this.auth.session_checkup_data.penjadwalan.changed = true;
                if (res.success){
                  this.getJadwals();
                  this.extendJadwal = [];
                } else {
                  this.getJadwals();
                }
                if (this.root != null && this.root instanceof PerawatanPage){
                  // console.log(typeof(this.root));
                  this.root.getJadwal();
                }

                this.api.apiWaktuPerawatan(this.auth.ACTIVE_PATIENT_KLINIK, this.auth.ACTIVE_PATIENT_PENJADWALAN, this.auth.USER_TOKEN).subscribe(res => {
                  this.auth.waktuPemeriksaan = res.data;
                })
              })
            }
          }
        ]
      });
      await alert.present();
    } else {
      const alert = await this.ac.create({
        header: 'Peringatan',
        message: 'Tidak bisa memperpanjang karena ada loncatan',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
          }
        ]
      })
      await alert.present();
    }
  }

  setChanges(){
    this.changes = [];
    for (let item of this.auth.session_autodongram){
      this.changes.push({
        id: item.idgigi,
        auto: false,
      })
    }
  }

  getChanges(id){
    for (let item of this.changes){
      if (item.id == id){
        // console.log(item);
        return item;
      }
    }
    return {id: 0, auto: false};
  }

  allowAuto(){
    for (let item of this.changes){
      if (item.auto){
        return true;
      }
    }
    return false;
  }

  getStatusText(idstatus){
    for (let item of this.actClass){
      if (item.no == idstatus){
        return item.text;
      }
    }
  }

  async doManual(){
    let pd = JSON.parse(JSON.stringify(this.auth.tmp_perawatan_data));

    const isModalOpened = await this.mc.getTop();
    if (isModalOpened) {
      setTimeout(async () => {
        const modal = await this.mc.create({
          component: PopupnotesperawatanPage,
          backdropDismiss: false,
          keyboardClose: false,
          componentProps: {
            mode: 'new',
            perawatan_data: pd,
          }
        });
        this.auth.session_autodongram = [];
        this.nav.navigateForward('/pasien');
        return await modal.present();
      }, 500)
      this.mc.dismiss();
    } else {
      const modal = await this.mc.create({
        component: PopupnotesperawatanPage,
        backdropDismiss: false,
        keyboardClose: false,
        componentProps: {
          mode: 'new',
          perawatan_data: pd,
        }
      });
      this.auth.session_autodongram = [];
      this.nav.navigateForward('/pasien');
      return await modal.present();
    }
  }

  async doAuto() {
    this.autoDone = [];
    let dt = new Date();
    let tgl = dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + dt.getDate();
    let waktu = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();

    let loading = await this.lc.create({
      message: 'Memproses data...',
    });
    await loading.present();

    let data_gigi = {
      idpasien: (this.auth.session_perawatan_data.idpasien != 0) ? this.auth.session_perawatan_data.idpasien : this.auth.ACTIVE_PATIENT,
      idgigi: [],
      idstatus: [],
    };

    for (let item of this.auth.session_autodongram){
      if (this.getChanges(item.idgigi).auto){
        data_gigi.idgigi.push(item.idgigi);
        data_gigi.idstatus.push(item.idstatusbaru);

        this.api.apiAutoSaveOdontogram(data_gigi, this.auth.USER_TOKEN).subscribe(async res => {
          this.auth.session_autodongram = [];
          this.lc.dismiss();

          let pd = JSON.parse(JSON.stringify(this.auth.tmp_perawatan_data));

          setTimeout(async () => {
            const isModalOpened = await this.mc.getTop();
            if (isModalOpened) {

            } else {
              const modal = await this.mc.create({
                component: PopupnotesperawatanPage,
                backdropDismiss: false,
                keyboardClose: false,
                componentProps: {
                  mode: 'new',
                  perawatan_data: pd,
                }
              });
              return await modal.present();
            }
          }, 500)

          this.mc.dismiss();
          //this.nav.navigateForward('/pasien');
        })
      }
    }
    this.checkAuto();
  }

  checkAuto(){
    setTimeout(() => {
      if (this.autoDone.length == this.auth.session_autodongram.length){
        this.auth.session_autodongram = [];
        this.lc.dismiss();
        this.mc.dismiss();
        this.nav.navigateForward('/pasien');
      } else {
        this.checkAuto();
      }
    }, 100);
  }

  getDataGigi() {
    this.api.apiGetPKOdontogramConfig(this.auth.USER_TOKEN).subscribe(res => {
      if (res.data){
        this.disabledOptions = res.data;
      } else {
        this.disabledOptions = [];
      }
    });

    this.isLoadingGigi = true;
    this.api.apiGetGigiAll(this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res => {
      this.dataGigi = [];
      if (res.error == null) {
        let odontogram = res.odontogram;
        for (let i = 1; i <= 8; i++) {
          var limit = (i < 5) ? 8 : 5;
          for (let j = 1; j <= limit; j++) {
            let noGigi = (i * 10) + j;
            let item = odontogram.gigi[noGigi.toString()];
            let isMulti = false;
            if (item != null && item != undefined) {
              if (item.multikondisi) {
                isMulti = true;
              }
              // this.api.apiGetGigi(this.auth.ACTIVE_PATIENT, noGigi, this.auth.USER_TOKEN).subscribe(res => {
              //   if (res.status.length > 1){
              //     isMulti = true;
              //   }
              //   this.syncGigiSingle(noGigi, isMulti);
              // });
              let status = item.idstatus;
              if (status != undefined && status != null) {
                if (this.isStatusZoned(status)) {
                  let zone = [];
                  for (let z of item.posisi) {
                    zone.push(z.permukaan[0].singkatan.toLowerCase())
                  }
                  this.dataGigi.push({
                    noGigi: noGigi,
                    status: status,
                    zones: zone,
                    isMulti: isMulti,
                    stillLoading: true,
                  });
                } else {
                  this.dataGigi.push({
                    noGigi: noGigi,
                    status: status,
                    isMulti: isMulti,
                    stillLoading: true,
                  });
                }
              }
            }
          }
        }
      } else {
        for (let i = 1; i <= 8; i++) {
          var limit = (i < 5) ? 8 : 5;
          for (let j = 1; j <= limit; j++) {
            let noGigi = (i * 10) + j;
            let status = 1;
            this.dataGigi.push({
              noGigi: noGigi,
              status: status,
              zones: [],
            });
          }
        }
      }
      this.isLoadingGigi = false;
    })
  }

  diagnosaShowSearch(){
    if (this.activePerawatanPK.length != 0){
      for (let item of this.auth.session_perawatan_data.pemeriksaan_klinis){
        for (let pkitem of this.activePerawatanPK){
          if (item.id == pkitem){
            if (this.diagnosaLainnya.length > 0 && this.showDiagnosaLainnya){
              return false;
            } else if (item.iscustom && this.searchDiagnosa.length > 0){
              return true;
            } else if (!item.iscustom){
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }
    return false;
  }

  prognosaShowSearch(){
    if (this.activePerawatanPK.length != 0){
      for (let item of this.diagnosasT){
        for (let pkitem of this.activeDiagnosaT){
          if (item.id == pkitem.id && item.idpk == pkitem.idpk){
            return true;
          }
        }
      }
    }
    return false;
  }

  perawatanShowSearch(){
    if (this.activePerawatanPK.length != 0){
      for (let item of this.auth.session_perawatan_data.pemeriksaan_klinis){
        for (let pkitem of this.activePerawatanPK){
          if (item.id == pkitem){
            if (this.perawatanLainnya.length > 0 && this.showPerawatanLainnya){
              return false;
            } else if (item.iscustom && this.searchPerawatan.length > 0){
              return true;
            } else if (!item.iscustom){
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }
    return false;
  }

  perawatanICDShowSearch(){
    if (this.activePerawatanPK.length != 0){
      for (let item of this.auth.session_perawatan_data.pemeriksaan_klinis){
        for (let pkitem of this.activePerawatanPK){
          if (item.id == pkitem){
            if (this.perawatanICDLainnya.length > 0 && this.showPerawatanICDLainnya){
              return false;
            } else if (item.iscustom && this.searchPerawatanICD.length > 0){
              return true;
            } else if (!item.iscustom){
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }
    return false;
  }

  tindakanShowSearch(){
    if (this.activePerawatanPK.length != 0){
      for (let item of this.perawatansT){
        for (let pkitem of this.activePerawatanT){
          if (item.id == pkitem.id && item.idpk == pkitem.idpk){
            if (this.tindakanLainnya.length > 0 && this.showTindakanLainnya){
              return false;
            } else if (item.iscustom && this.searchTindakan.length > 0){
              return true;
            } else if (!item.iscustom){
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }
    return false;
  }

  showInfoTindakan(){
    if (this.activePerawatanPK.length != 0){
      for (let item of this.perawatansT){
        for (let pkitem of this.activePerawatanT){
          if (item.id == pkitem.id && item.idpk == pkitem.idpk){
            if (this.showTindakanLainnya) {
              return false;
            } else if (this.searchTindakan.length > 0){
              return false;
            } else if (item.iscustom){
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  setDiagnosa(reset: boolean = false){
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.diagnosas = [];
    }
    this.isLoadingList = true;

    this.api.apiListDiagnosa(this.activePerawatanPKStatus.toString(), this.auth.USER_TOKEN, this.searchDiagnosa, this.activePage).subscribe(res => {
      this.tmpList = res;

      if (res != null && res.data != null){
        for (let item of res.data){
          if (item.suggested != null){
            this.diagnosas.push({
              id: item.iddiagnosa,
              text: item.keterangan,
              satusehat: item.icd10,
              pcare: item.kodepcare,
              suggested: item.suggested,
            });
          } else {
            this.diagnosas.push({
              id: item.iddiagnosa,
              text: item.keterangan,
              satusehat: item.icd10,
              pcare: item.kodepcare,
              suggested: 0,
            });
          }
        }
        // console.log(this.diagnosas);
        this.tmpDiagnosas = JSON.parse(JSON.stringify(this.diagnosas));
        // this.doSearchDiagnosa();
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      }
    });
  }

  setPrognosa(reset: boolean = false){
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.prognosas = [];
    }
    this.isLoadingList = true;

    this.api.apiListPrognosa(this.activePerawatanPKStatus.toString(), this.auth.USER_TOKEN, this.searchPrognosa, this.activePage).subscribe(res => {
      this.tmpList = res;

      if (res != null && res.data != null){
        for (let item of res.data){
          this.prognosas.push({
            idd: this.diagnosaPrognosa(),
            id: item.idprognosis,
            snomed_ct_id: item.snomed_ct_id,
            nama: item.nama,
            keterangan: '',
          });
        }
        this.tmpPrognosas = JSON.parse(JSON.stringify(this.prognosas));
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      }
    });
  }

  setPerawatan(reset: boolean = false){
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.perawatans = [];
    }
    this.isLoadingList = true;

    this.api.apiListPerawatan(this.activePerawatanPKStatus.toString(), this.auth.USER_TOKEN, '', this.activePage).subscribe(res => {
      this.tmpList = res;

      if (res != null && res.data != null){
        for (let item of res.data){
          if (item.suggested != null){
            this.perawatans.push({
              id: item.kode,
              text: item.keterangan,
              suggested: item.suggested,
            });
          } else {
            this.perawatans.push({
              id: item.kode,
              text: item.keterangan,
              suggested: 0,
            });
          }
        }
        this.tmpPerawatans = JSON.parse(JSON.stringify(this.perawatans));
        // this.doSearchDiagnosa();
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      }
    });
  }

  setPerawatanICD(reset: boolean = false){
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.perawatanICDs = [];
    }
    this.isLoadingList = true;

    let idrekomendasis = '';
    if (this.auth.session_perawatan_data.perawatan != null && this.auth.session_perawatan_data.perawatan.length > 0){
        let i = 1;
        for (let item of this.auth.session_perawatan_data.perawatan){
            if (i < this.auth.session_perawatan_data.perawatan.length){
                idrekomendasis += item.id + '::';
            } else {
                idrekomendasis += item.id;
            }
            i++;
        }
    }

    this.api.apiListPerawatanICD(idrekomendasis, this.auth.USER_TOKEN, '', this.activePage).subscribe(res => {
      this.tmpList = res;

      if (res != null && res.data != null){
        for (let item of res.data){
          this.perawatanICDs.push({
            code: item.code,
            desc: item.desc,
          });
        }
        this.tmpPerawatanICDs = JSON.parse(JSON.stringify(this.perawatanICDs));
        // this.doSearchDiagnosa();
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      }
    });
  }

  setTindakan(reset: boolean = false){
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.tindakans = [];
    }
    this.isLoadingList = true;

    let activePerawatanT = (this.activePerawatanT.length > 0) ? this.activePerawatanT[this.activePerawatanT.length-1] : {id: '0', idpk: '0'};
    this.api.apiListTindakan(this.activePerawatanPKStatus.toString(), activePerawatanT.id, this.auth.USER_TOKEN, '', this.activePage).subscribe(res => {
      this.tmpList = res;
      this.tmpTindakans = [];

      if (res != null && res.data != null){
        for (let item of res.data){
          if (item.suggested != null){
            this.tindakans.push({
              id: item.idtindakan,
              idkelompok: item.idkelompok,
              text: item.nama,
              harga: item.harga,
              bagihasil: item.bagihasil,
              suggested: item.suggested,
              kode: item.kode,
            });
          } else {
            this.tindakans.push({
              id: item.idtindakan,
              idkelompok: item.idkelompok,
              text: item.nama,
              harga: item.harga,
              bagihasil: item.bagihasil,
              suggested: 0,
              kode: item.kode,
            });
          }
        }
        this.tmpTindakans = JSON.parse(JSON.stringify(this.tindakans));
        // this.doSearchDiagnosa();
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      }
    });
  }

  setEdukasi(){
    this.edukasi = null;
    this.api.apiEdukasiPerawatan(this.idEdukasi.toString(), this.auth.USER_TOKEN).subscribe(res => {
      this.edukasi = res;
    });
  }

  setPerawatanPK(){
    this.perawatansPK = [];
    for (let item of this.auth.session_perawatan_data.pemeriksaan_klinis){
      this.perawatansPK.push({
        id: item.id,
        status: item.idstatus,
        text: item.text,
      });
    }
  }

  setPerawatanT(){
    this.perawatansT = [];
    for (let item of this.auth.session_perawatan_data.perawatan){
      this.perawatansT.push({
        idpk: item.idpk,
        id: item.id,
        text: item.text,
        iscustom: item.iscustom,
      });
    }
  }

  setDiagnosaT(){
    this.diagnosasT = [];
    for (let item of this.auth.session_perawatan_data.diagnosa){
      this.diagnosasT.push({
        idpk: item.idpk,
        id: item.id,
        text: item.text,
        iscustom: item.iscustom,
      });
    }
  }

  savePemeriksaan(){
    if (this.selectedContent == 0){
      let nama = '', i = 0;
      for (let item of this.multiId){
        nama = nama + item;
        if (i + 1 < this.multiId.length){
          nama = nama + ', ';
        }
        i++;
      }
      for (let item of this.actClass){
        if (item.no == this.activeKondisi){
          if (item.no == 24){
            nama = nama + ' - ' + this.klinisLainnya;
          } else {
            nama = nama + ' - ' + item.text;
          }
        }
      }
      this.auth.session_perawatan_data.pemeriksaan_klinis.push({
        id: this.auth.lastPemeriksaanKlinis() + 1,
        idstatus: this.activeKondisi,
        idgigi: this.multiId,
        text: nama,
        iscustom: false,
      });
      this.doTempSave();
    } else if (this.selectedContent == 1){
      this.auth.session_perawatan_data.pemeriksaan_klinis.push({
        id: this.auth.lastPemeriksaanKlinis() + 1,
        idstatus: 0,
        idgigi: [0],
        text: this.klinisOralLainnya,
        iscustom: true,
      });
      this.doTempSave();
    } else if (this.selectedContent == 2){
      this.auth.session_perawatan_data.pemeriksaan_klinis.push({
        id: this.auth.lastPemeriksaanKlinis() + 1,
        idstatus: 0,
        idgigi: [1],
        text: this.klinisPeriLainnya,
        iscustom: true,
      });
      this.doTempSave();
    }

    this.mc.dismiss();
  }

  saveDiagnosa(){
    for (let pkitem of this.activePerawatanPK){
      for (let item of this.activeDiagnosa){
        for (let subitem of this.diagnosas){
          if (subitem.id == item){
            this.auth.session_perawatan_data.diagnosa.push({
              idpk: pkitem,
              id: item,
              text: subitem.text,
              satusehat: subitem.satusehat,
              pcare: subitem.pcare,
              iscustom: false,
            });
            this.doTempSave();
          }
        }
      }
      if (this.diagnosaLainnya.length > 0){
        this.auth.session_perawatan_data.diagnosa.push({
          idpk: pkitem,
          id: this.newDiagnosaLainnya(),
          text: this.diagnosaLainnya,
          satusehat: null,
          pcare: null,
          iscustom: true,
        });
        this.doTempSave();
      }
    }
    this.mc.dismiss();
  }

  newDiagnosaLainnya(){
    let last = 0;
    for (let item of this.auth.session_perawatan_data.diagnosa){
      if (item.id < last){
        last = item.id;
      }
    }
    return last - 1;
  }

  allowToSaveDiagnosa(){
    if (this.activePerawatanPK.length != 0 && this.activeDiagnosa.length != 0){
      return true;
    } else if (this.activePerawatanPK.length != 0 && this.diagnosaLainnya.length > 0 && this.showDiagnosaLainnya){
      return true;
    }
    return false;
  }

  savePrognosa(){
    for (let pkitem of this.activeDiagnosaT){
      for (let item of this.activePrognosa){
        for (let subitem of this.tmpPrognosas){
          if (subitem.id == item){
            if (this.auth.session_perawatan_data.prognosa == null){
              this.auth.session_perawatan_data.prognosa = [];
            }

            let found = false;
            let i = 0;
            if (this.auth.session_perawatan_data.prognosa.length > 0){
              do {
                let xitem = this.auth.session_perawatan_data.prognosa[i];
                console.log(xitem);
                console.log(subitem);
                if (xitem.idd == subitem.idd){
                  this.auth.showAlert('Pemberitahuan', 'Sudah ada prognosa ditambahkan untuk Diagnosa yang sama');
                  found = true;
                }
                i++;
              }
              while (!found);
              if (!found){
                this.auth.session_perawatan_data.prognosa.push({
                  idpk: pkitem.idpk,
                  idd: subitem.idd,
                  id: subitem.id,
                  nama: subitem.nama,
                  keterangan: subitem.keterangan,
                });
                this.doTempSave();
              }
            } else {
              this.auth.session_perawatan_data.prognosa.push({
                idpk: pkitem.idpk,
                idd: subitem.idd,
                id: subitem.id,
                nama: subitem.nama,
                keterangan: subitem.keterangan,
              });
              this.doTempSave();
            }
          }
        }
      }
    }
    this.mc.dismiss();
  }

  allowToSavePrognosa(){
    if (this.activeDiagnosaT.length != 0 && this.activePrognosa.length != 0){
      return true;
    }
    return false;
  }

  savePerawatan(){
    for (let pkitem of this.activePerawatanPK){
      for (let item of this.activePerawatan){
        for (let subitem of this.perawatans){
          if (subitem.id == item){
            this.auth.session_perawatan_data.perawatan.push({
              idpk: pkitem,
              id: item,
              text: subitem.text,
              iscustom: false,
            });
            this.doTempSave();
          }
        }
      }
      if (this.perawatanLainnya.length > 0){
        this.auth.session_perawatan_data.perawatan.push({
          idpk: pkitem,
          id: this.newPerawatanLainnya(),
          text: this.perawatanLainnya,
          iscustom: true,
        });
        this.doTempSave();
      }
    }

    this.mc.dismiss();
  }

  newPerawatanLainnya(){
    let last = 0;
    for (let item of this.auth.session_perawatan_data.perawatan){
      if (item.id < last){
        last = item.id;
      }
    }
    return last - 1;
  }

  allowToSavePerawatan(){
    if (this.activePerawatanPK.length != 0 && this.activePerawatan.length != 0){
      return true;
    } else if (this.activePerawatanPK.length != 0 && this.perawatanLainnya.length > 0 && this.showPerawatanLainnya){
      return true;
    }
    return false;
  }

  savePerawatanICD(){
    let found = 0;
    for (let pkitem of this.activePerawatanPK){
      for (let item of this.activePerawatanICD){
        for (let subitem of this.perawatanICDs){
          if (subitem.code == item){
            this.auth.session_perawatan_data.icd.push({
              idpk: pkitem,
              id: found + 1,
              code: item,
              desc: subitem.desc,
              iscustom: false,
            });
            found++;
            this.doTempSave();
          }
        }
      }
      if (this.perawatanICDLainnya.length > 0){
        // this.auth.session_perawatan_data.perawatanICD.push({
        //   idpk: pkitem,
        //   id: this.newPerawatanLainnya(),
        //   text: this.perawatanLainnya,
        //   iscustom: true,
        // });
        this.doTempSave();
      }
    }

    this.mc.dismiss();
  }

  newPerawatanICDLainnya(){
    let last = 0;
    for (let item of this.auth.session_perawatan_data.icd){
      if (parseInt(item.code) < last){
        last = parseInt(item.code);
      }
    }
    return last - 1;
  }

  allowToSavePerawatanICD(){
    if (this.activePerawatanPK.length != 0 && this.activePerawatanICD.length != 0){
      return true;
    } else if (this.activePerawatanPK.length != 0 && this.perawatanICDLainnya.length > 0 && this.showPerawatanICDLainnya){
      return true;
    }
    return false;
  }

  saveTindakan(){
    for (let pkitem of this.activePerawatanT){
      for (let item of this.activeTindakan){
        for (let subitem of this.tindakans){
          if (subitem.id == item){
            this.auth.session_perawatan_data.tindakan.push({
              idp: pkitem.id,
              idpk: pkitem.idpk,
              idkelompok: subitem.idkelompok,
              idtindakan: subitem.id,
              harga: subitem.harga,
              bagihasil: subitem.bagihasil,
              nama: subitem.text,
              iscustom: false,
              kode: subitem.kode,
            });
            this.doTempSave();
          }
        }
      }

      if (this.tindakanLainnya.length > 0){
        this.auth.session_perawatan_data.tindakan.push({
          idp: pkitem.id,
          idpk: pkitem.idpk,
          idkelompok: this.newPerawatanLainnya(),
          idtindakan: this.newTindakanLainnya(),
          harga: parseInt(this.hargaTindakanLainnya),
          bagihasil: Math.floor(parseInt(this.hargaTindakanLainnya) * 0.1),
          nama: this.tindakanLainnya,
          iscustom: true,
          kode: '0',
        });
        this.doTempSave();
      }
    }
    this.mc.dismiss();
  }

  newTindakanLainnya(){
    let last = 0;
    for (let item of this.auth.session_perawatan_data.tindakan){
      if (item.idtindakan < last){
        last = item.idtindakan;
      }
    }
    return last - 1;
  }

  typeHargaLainnya(){
    let onlyNumbers = '';
    for (let item of this.hargaTindakanLainnyaTxt){
      if (item == ',' || item == '.'){

      } else {
        onlyNumbers += item;
      }
    }

    if (onlyNumbers.length > 0 && parseInt(onlyNumbers) > 0){
      this.hargaTindakanLainnya = Number(onlyNumbers) + '';
      this.hargaTindakanLainnyaTxt = Number(onlyNumbers).toLocaleString('id');
      // setTimeout(() => {
      // })
    }
  }

  allowToSaveTindakan(){
    if (this.activePerawatanT.length != 0 && this.activeTindakan.length != 0){
      return true;
    } else if (this.activePerawatanT.length != 0 && this.tindakanLainnya.length > 0 && this.hargaTindakanLainnya.length > 0 && this.showTindakanLainnya){
      return true;
    }
    return false;
  }

  setActiveKondisi(id) {
    if (id == this.activeKondisi){
      this.activeKondisi = 0;
    } else {
      this.activeKondisi = id;
    }
    if (this.activeKondisi != 24){
      this.showKlinisLainnya = false;
    }
  }

  pickOdontogram(id) {
    this.activeKondisi = 0;
    this.multiId = [];
    this.multiId.push(id);
    this.getGigiActive(id);
  }

  getGigiActive(nogigi){
    this.gigiActive = null;
    this.gigiActClass = [];
    this.api.apiGetGigi(this.auth.ACTIVE_PATIENT, nogigi, this.auth.USER_TOKEN).subscribe(res => {
      if (res != null && res.status != null && res.status.length > 0){
        for (let item of res.status){
          this.gigiActClass.push(this.getActClassByNo(item.idstatus));
        }
      } else {
          this.gigiActClass.push(this.getActClassByNo(1));
      }
    });
  }

  toggleKlinisLainnya() {
    this.showKlinisLainnya = !this.showKlinisLainnya;
    if (this.showKlinisLainnya == true){
      this.setActiveKondisi(24);
    } else {
      this.setActiveKondisi(24);
    }
  }

  getActClassByNo(no){
    for (let item of this.actClass){
      if (item.no == no){
        return item;
      }
    }
    return {
      no: 1,
      text: 'Gigi Sehat',
      class: 'sehat',
      inText: '',
      top: true,
    }
  }

  allowNextPemeriksaan(){
    if (this.selectedContent == 0){
      if (this.activeKondisi != 0 && this.multiId.length > 0){
        return true;
      }
    } else if (this.selectedContent == 1){
      if (this.klinisOralLainnya.length > 0){
        return true;
      }
    } else if (this.selectedContent == 2){
      if (this.klinisPeriLainnya.length > 0){
        return true;
      }
    }
    return false;
  }

  isZoneKondisi(){
    if (this.activeKondisi == 2 || this.activeKondisi == 3 || this.activeKondisi == 4 || this.activeKondisi == 5 || this.activeKondisi == 6 || this.activeKondisi == 7){
      return true;
    }
    return false;
  }

  namaGigi(x: number){
    let result = '';
    if (x < 50){
      result = 'Gigi Dewasa ';
      result += this.opsi.dewasa[0].value[x % 10 - 1] + ' ' + this.opsi.dewasa[1].value[Math.floor(x / 10) - 1];
    } else {
      result = 'Gigi Susu ';
      result += this.opsi.susu[0].value[x % 10 - 1] + ' ';
      let active = 0;
      let i = 0;
      for (let item of this.opsi.susu[1].key){
        if (Math.floor(x / 10) == item){
          active = i;
        }
        i++;
      }
      result += this.opsi.susu[1].value[active];
    }
    return result;
  }

  syncGigiSingle(noGigi: number, isMulti: boolean) {
    let isAvailable = false;
    let index = 0;
    for (let i = 0; i < this.dataGigi.length; i++) {
      if (this.dataGigi[i].noGigi == noGigi) {
        isAvailable = true;
        index = i;
      }
    }
    setTimeout(() => {
      if (isAvailable) {
        if (this.dataGigi[index].stillLoading) {
          this.dataGigi[index].stillLoading = false;
          this.dataGigi[index].isMulti = isMulti;
          this.isLoadingGigi = false;
          this.loadGigi();
        }
      } else {
        this.isLoadingGigi = true;
        this.syncGigiSingle(noGigi, isMulti);
      }
    }, 100);
  }

  gigiWhiteAll() {
    var tmp = JSON.parse(JSON.stringify(this.dataGigi));
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].status = 1;
      tmp[i].zone = [];
      tmp[i].isMulti = false;
    }
    this.zone.run(() => {
      this.displayDataGigi = JSON.parse(JSON.stringify(tmp));
      this.resetGigi();
    });
  }

  refresh() {
    this.isLoadingGigi = true;
    this.getDataGigi();
    this.loadGigi();
  }

  loadGigi() {
    setTimeout(() => {
      if (this.isLoadingGigi) {
        this.loadGigi();
      } else {
        this.zone.run(() => {
          this.displayDataGigi = JSON.parse(JSON.stringify(this.dataGigi));
          this.resetGigi();
        })
      }
    }, 100)
  }

  baseGigiSingle(element) {
    for (let k = 0; k < this.actClass.length; k++) {
      element.classList.remove("status-zoned");
      element.classList.remove("status-not-zoned");
      element.classList.remove(this.actClass[k].class);
      element.classList.remove("status-" + this.actClass[k].inText.toLowerCase());
      element.setAttribute('style', '');
      element.querySelector('.status-caption').setAttribute('style', '');
    }
    element.classList.remove('is-selected');
    let sides = element.querySelectorAll('.side');
    for (let k = 0; k < sides.length; k++) {
      for (let zoneClass of this.zoneActClass) {
        sides[k].classList.remove(zoneClass.class);
        sides[k].setAttribute('style', '');
      }
    }
    element.querySelector('.status-ipx').innerHTML = "";
    element.querySelector('.status-ue').innerHTML = "";
    element.querySelector('.status-text').innerHTML = "";
    element.querySelector('.status-ipx').classList.remove('black');
    element.querySelector('.status-ue').classList.remove('black');
    element.querySelector('.status-text').classList.remove('black');
    element.classList.remove('status-multi');

    if (element.querySelector('.status-custom-path') != null){
      element.querySelector('.status-custom-path').remove();
    }
  }

  baseGigi() {
    for (let i = 1; i <= 8; i++) {
      let limit = (i <= 4) ? 8 : 5;
      for (let j = 1; j <= limit; j++) {
        let element = document.getElementById("alt_gigi_gigi" + ((i * 10) + j));
        this.baseGigiSingle(element);
      }
    }
  }

  async backToStatus(noGigi) {
    let dataGigi = null;
    for (let i = 0; i < this.displayDataGigi.length; i++) {
      if (this.displayDataGigi[i].noGigi == noGigi) {
        dataGigi = this.displayDataGigi[i];
      }
    }
    let element = await this.waitForElm("alt_gigi_gigi" + noGigi) as HTMLElement;
    if (dataGigi != null) {
      if (dataGigi.isMulti) {
        element.classList.add('status-multi');
        element.classList.add('status-m');
        element.querySelector('.status-text').innerHTML = "M";
      } else {
        if (dataGigi.status < 1) {
          dataGigi.status = 1;
        }
        let cActClass = this.getActClass(dataGigi.status);
        let cZoneClass = this.getZoneActClass(dataGigi.status);

        if (this.isStatusZoned(dataGigi.status)) {
          element.classList.add('status-zoned');
        }

        if (cActClass.customStyle != null && cActClass.customStyle.length > 0){
          //not applying class, just style affecting status-caption
          element.querySelector('.status-caption').setAttribute('style', cActClass.customStyle);
        } else {
          element.classList.add(cActClass.class);
        }

        if (cActClass.customPath != null && cActClass.customPath.length > 0){
          //append custom path to element
          let xStyle = '';
          let xPath = '';
          let offsetX = 25;
          let offsetY = 25;
          let check = cActClass.customPath.split("^^^");
          if (check.length > 0){
            xPath = check[0];
            xStyle = check[1];
            offsetX = parseInt(check[2]);
            offsetY = parseInt(check[3]);
          }
          let targetCaption = element.querySelector('.status-caption');
          if (targetCaption != null){
            offsetX += parseInt(targetCaption.getAttribute('x'));
            offsetY += parseInt(targetCaption.getAttribute('y'));
          }

          let pathElem = '<path class="status status-custom-path" id="status_status-custom-path-'+ noGigi +'" d="M'+ offsetX +','+ offsetY +''+ xPath +'" style="'+ xStyle +'"></path>';
          element.innerHTML += pathElem;
        }

        element.classList.add('status-' + cActClass.inText.toLowerCase());

        if (cActClass.inText.length == 1){
          element.querySelector('.status-text').innerHTML = cActClass.inText;
        } else if (cActClass.inText.length == 2){
          element.querySelector('.status-ue').innerHTML = cActClass.inText;
        } else if (cActClass.inText.length == 3){
          element.querySelector('.status-ipx').innerHTML = cActClass.inText;
        }

        if (this.isStatusZoned(dataGigi.status)) {
          if (dataGigi.zones != null && !dataGigi.zones.includes("o")) {
            element.querySelector(".status-text").classList.add("black");
            element.querySelector(".status-ue").classList.add("black");
            element.querySelector(".status-ipx").classList.add("black");
          } else {
            element.querySelector(".status-text").classList.remove("black");
            element.querySelector(".status-ue").classList.remove("black");
            element.querySelector(".status-ipx").classList.remove("black");
          }
          for (let j = 0; j < dataGigi.zones.length; j++) {
            if ((noGigi > 20 && noGigi < 40) || (noGigi > 60 && noGigi < 80)) {
              if (dataGigi.zones[j] == 'm') {
                dataGigi.zones[j] = 'd';
              } else if (dataGigi.zones[j] == 'd') {
                dataGigi.zones[j] = 'm';
              }
            }
            if ((noGigi > 30 && noGigi < 50) || (noGigi > 70 && noGigi < 90)) {
              if (dataGigi.zones[j] == 'l') {
                dataGigi.zones[j] = 'v';
              } else if (dataGigi.zones[j] == 'v') {
                dataGigi.zones[j] = 'l';
              }
            }
            if (element.querySelector(".side-" + dataGigi.zones[j]) != null) {
              if (cZoneClass.customStyle != null && cZoneClass.customStyle.length > 0){
                element.querySelector(".side-" + dataGigi.zones[j]).setAttribute('style', cZoneClass.customStyle);
              } else {
                element.querySelector(".side-" + dataGigi.zones[j]).classList.add(cZoneClass.class);
              }
            }
          }
        }
      }
    }
  }

  setStatusGigi(element, act) {
    if (act < 1) {
      act = 1;
    }
    let cActClass = this.getActClass(act);
    let cZoneClass = this.getZoneActClass(act);

    if (cActClass.customStyle != null && cActClass.customStyle.length > 0){
      //not applying class, just style affecting status-caption
      element.querySelector('.status-caption').setAttribute('style', cActClass.customStyle);
    } else {
      element.classList.add(cActClass.class);
    }

    if (cActClass.customPath != null && cActClass.customPath.length > 0){
      //append custom path to element
      let xStyle = '';
      let xPath = '';
      let offsetX = 25;
      let offsetY = 25;
      let check = cActClass.customPath.split("^^^");
      if (check.length > 0){
        xPath = check[0];
        xStyle = check[1];
        offsetX = parseInt(check[2]);
        offsetY = parseInt(check[3]);
      }
      let targetCaption = element.querySelector('.status-caption');
      if (targetCaption != null){
        offsetX += parseInt(targetCaption.getAttribute('x'));
        offsetY += parseInt(targetCaption.getAttribute('y'));
      }

      let pathElem = '<path class="status status-custom-path" d="M'+ offsetX +','+ offsetY +''+ xPath +'" style="'+ xStyle +'"></path>';
      element.innerHTML += pathElem;
    }

    element.classList.add('status-' + cActClass.inText.toLowerCase());

    if (cActClass.inText.length == 1){
      element.querySelector('.status-text').innerHTML = cActClass.inText;
    } else if (cActClass.inText.length == 2){
      element.querySelector('.status-ue').innerHTML = cActClass.inText;
    } else if (cActClass.inText.length == 3){
      element.querySelector('.status-ipx').innerHTML = cActClass.inText;
    }
  }

  resetGigi() {
    this.baseGigi();
    this.allSusuDark = true;
    let gigiSusu = false;

    for (let i = 0; i < this.displayDataGigi.length; i++) {
      if (this.displayDataGigi[i].noGigi > 50) {
        gigiSusu = true;
      }
    }

    for (let i = 0; i < this.displayDataGigi.length; i++) {
      let noGigi = this.displayDataGigi[i].noGigi;
      this.backToStatus(noGigi);

      if (noGigi > 50 && this.displayDataGigi[i].isMulti) {
        this.allSusuDark = false;
      }

      if (noGigi > 50 && this.displayDataGigi[i].status != 11 && gigiSusu) {
        this.allSusuDark = false;
      }
    }
    if (!gigiSusu) {
      this.allSusuDark = false;
    }
  }

  resetGigiSingle(noGigi: number) {
    let id = 0;
    for (let i = 0; i < this.displayDataGigi.length; i++) {
      if (this.displayDataGigi[i].noGigi == noGigi) {
        id = i;
      }
    }
    let element = document.getElementById("alt_gigi_gigi" + noGigi);
    this.baseGigiSingle(element);
  }

  changeTo(noGigi: number, act: number) {
    var cActClass = this.getActClass(act);

    let element = document.getElementById("alt_gigi_gigi" + noGigi);
    this.baseGigiSingle(element);
    element.classList.add('is-selected');
    this.setStatusGigi(element, act);
  }

  getActClass(idstatus){
    for (let i = 0; i < this.actClass.length; i++){
      var item = this.actClass[i];
      if (item.no == idstatus){
        return item;
      }
    }
    return {
      no: 1,
      text: 'Gigi Sehat',
      class: 'sehat',
      inText: '',
      top: false,
      isMulti: false,
    };
  }

  getZoneActClass(idstatus){
    for (let i = 0; i < this.zoneActClass.length; i++){
      var item = this.zoneActClass[i];
      if (item.no == idstatus){
        return item;
      }
    }
    return {
      no: 1,
      class: '',
      color: '',
    };
  }

  async loadActClass(){
    if (this.auth.currentActClass != null && this.auth.currentActClass.length > 0){
      this.actClass = this.auth.currentActClass;
      this.zoneActClass = this.auth.currentZoneActClass;
    } else {
      this.auth.getOdontogramStatus();
      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(1000);
      this.loadActClass();
    }
  }

  isStatusZoned(idstatus){
    for (let i = 0; i < this.zoneActClass.length; i++){
      let item = this.zoneActClass[i];
      if (Number(idstatus) == item.no){
        return true;
      }
    }
    return false;
  }

  isStatusMultid(idstatus){
    for (let i = 0; i < this.actClass.length; i++){
      let item = this.actClass[i];
      if (Number(idstatus) == item.no && item.isMulti){
        return true;
      }
    }
    return false;
  }

  waitForElm(id){
    return new Promise(resolve => {
      if (document.getElementById(id)){
        return resolve(document.getElementById(id));
      }

      const observer = new MutationObserver(mutations => {
        if (document.querySelector(id)) {
            observer.disconnect();
            resolve(document.getElementById(id));
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    });
  }

  segmentChanged(e) {
    this.slider.slideTo(e.detail.value);
  }

  sliderChanged(e) {
    this.slider.getActiveIndex().then((v) => {
      this.selectedContent = v;
    });
  }

  openPicker() {
    this.mc.dismiss();
    this.nav.navigateForward('/dentalrecord');
  }

  changePeriodontal(type: string) {
    this.pemeriksaanPeriodontal = type;
  }

  pickPeriodontal(type, id) {

  }

  selectJadwal(jadwal) {
    if (jadwal.idstatus == 1 || jadwal.idstatus == 2) {
      return false;
    }

    if (jadwal.idstatus == 4) {
      this.jadwals.find(data => data.id == jadwal.id).idstatus = 3;
      this.jadwals.find(data => data.id == jadwal.id).status = 'available';

      const index = this.extendJadwal.indexOf(jadwal);
      this.extendJadwal.splice(index, 1);

      return false;
    }

    const isExtended = this.extendJadwal.find(data => data.id == jadwal.id);
    if (!isExtended) {
      this.extendJadwal.push(jadwal);
      this.jadwals.find(data => data.id == jadwal.id).idstatus = 4;
      this.jadwals.find(data => data.id == jadwal.id).status = 'extend';
    }
  }

  async deleteItem(action, id) {
    const alert = await this.ac.create({
      header: 'Konfirmasi Hapus',
      message: 'Apakah Anda yakin menghapus data ini?',
      buttons: [
        {
          text: 'Tidak',
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
          }
        }, {
          text: 'Ya',
          handler: () => {
            this.ac.dismiss();
          }
        }
      ]
    });
    await alert.present();
  }

  doSearchTindakan(reset: boolean = false) {
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.tindakans = [];
    }
    this.isLoadingList = true;

    this.isSearchingTindakan = true;

    if (this.searchTimeout != null){
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      let activePerawatanT = (this.activePerawatanT.length > 0) ? this.activePerawatanT[this.activePerawatanT.length - 1] : {id: '0', idpk: '0'};
      this.api.apiListTindakan(this.activePerawatanPKStatus.toString(), activePerawatanT.id, this.auth.USER_TOKEN, this.searchTindakan, this.activePage).subscribe(res => {
        this.tmpList = res;
        if (res != null && res.data != null){
          for (let item of res.data){
            if (item.suggested != null){
              this.tindakans.push({
                id: item.idtindakan,
                idkelompok: item.idkelompok,
                text: item.nama,
                harga: item.harga,
                bagihasil: item.bagihasil,
                suggested: item.suggested,
                kode: item.kode,
              });
            } else {
              this.tindakans.push({
                id: item.idtindakan,
                idkelompok: item.idkelompok,
                text: item.nama,
                harga: item.harga,
                bagihasil: item.bagihasil,
                suggested: 0,
                kode: item.kode,
              });
            }
          }
          this.tmpTindakans = JSON.parse(JSON.stringify(this.tindakans));
        }

        let activeFound = false;
        let tmp = [];
        for (let item of this.tindakans) {
          if (item.text.toUpperCase().includes(this.searchTindakan.toUpperCase())) {
            // this.tmpTindakans.push({ id: item.id, text: item.text });
            if (this.isActiveTindakan(item.id)) {
              activeFound = true;
              tmp.push(item.id);
            }
          }
        }
        if (!activeFound) {
          this.activeTindakan = [];
        } else {
          this.activeTindakan = JSON.parse(JSON.stringify(tmp));
        }
        this.isSearchingTindakan = false;
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      });
    }, 1000);
  }

  isActiveTindakan(id) {
    for (let item of this.activeTindakan) {
      if (item == id) {
        return true;
      }
    }
    return false;
  }

  setActiveTindakan(id) {
    if (!this.isActiveTindakan(id)) {
      this.activeTindakan.push(id);
    } else {
      let index = this.activeTindakan.indexOf(id);
      if (index > -1) {
        this.activeTindakan.splice(index, 1);
      }
    }
  }

  setActivePerawatanT(id, idpk) {
    // console.log(this.activePerawatanT);
    for (let item of this.perawatansT){
      if (item.id == id && item.idpk == idpk){
        let index = -1;
        for (let xyz = 0; xyz < this.activePerawatanT.length; xyz++){
          let pkitem = this.activePerawatanT[xyz];
          if (item.id == pkitem.id && item.idpk == pkitem.idpk){
            index = xyz;
            break;
          }
        }
        if (index == -1){
          this.diagnosaLainnya = '';
          this.perawatanLainnya = '';
          this.tindakanLainnya = '';
          this.searchDiagnosa = '';
          this.searchPrognosa = '';
          this.searchPerawatan = '';
          this.searchPerawatanICD = '';
          this.searchTindakan = '';

          this.activePerawatanT.push({id: item.id, idpk: item.idpk});
          // console.log(this.activePerawatanT);

          if (!this.activePerawatanPK.includes(item.idpk)){
            this.activePerawatanPK.push(item.idpk);
          }

          for (let xitem of this.auth.session_perawatan_data.pemeriksaan_klinis){
            for (let pkitem of this.activePerawatanPK){
              if (xitem.id == pkitem){
                this.activePerawatanPKStatus = xitem.idstatus;
              }
            }
          }
        } else {
          this.diagnosaLainnya = '';
          this.perawatanLainnya = '';
          this.tindakanLainnya = '';
          this.searchDiagnosa = '';
          this.searchPrognosa = '';
          this.searchPerawatan = '';
          this.searchPerawatanICD = '';
          this.searchTindakan = '';

          this.activePerawatanT.splice(index, 1);

          this.activePerawatanPK = [];
          this.activePerawatanPKStatus = 0;
        }
      }
    }
    if (this.actionForm == 'tindakan'){
      this.setTindakan(true);
    }
  }

  setActiveDiagnosaT(id, idpk) {
    for (let item of this.diagnosasT){
      if (item.id == id && item.idpk == idpk){
        let index = -1;
        for (let xyz = 0; xyz < this.activeDiagnosaT.length; xyz++){
          let pkitem = this.activeDiagnosaT[xyz];
          if (item.id == pkitem.id && item.idpk == pkitem.idpk){
            index = xyz;
            break;
          }
        }
        if (index == -1){
          this.diagnosaLainnya = '';
          this.perawatanLainnya = '';
          this.tindakanLainnya = '';
          this.searchDiagnosa = '';
          this.searchPrognosa = '';
          this.searchPerawatan = '';
          this.searchPerawatanICD = '';
          this.searchTindakan = '';

          this.activeDiagnosaT.push({id: item.id, idpk: item.idpk});

          if (!this.activePerawatanPK.includes(item.idpk)){
            this.activePerawatanPK.push(item.idpk);
          }

          for (let xitem of this.auth.session_perawatan_data.pemeriksaan_klinis){
            for (let pkitem of this.activePerawatanPK){
              if (xitem.id == pkitem){
                this.activePerawatanPKStatus = xitem.idstatus;
              }
            }
          }
        } else {
          this.diagnosaLainnya = '';
          this.perawatanLainnya = '';
          this.tindakanLainnya = '';
          this.searchDiagnosa = '';
          this.searchPrognosa = '';
          this.searchPerawatan = '';
          this.searchPerawatanICD = '';
          this.searchTindakan = '';

          this.activeDiagnosaT.splice(index, 1);

          this.activePerawatanPK = [];
          this.activePerawatanPKStatus = 0;
        }
      }
    }
    if (this.actionForm == 'prognosa'){
      this.setPrognosa(true);
    }
  }

  toggleTindakanLainnya() {
    this.showTindakanLainnya = !this.showTindakanLainnya;
  }

  doSearchPerawatanICD(reset: boolean = false) {
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.perawatanICDs = [];
    }
    this.isLoadingList = true;

    this.isSearchingPerawatanICD = true;

    if (this.searchTimeout != null){
      clearTimeout(this.searchTimeout);
    }

    let idrekomendasis = '';
    if (this.auth.session_perawatan_data.perawatan != null && this.auth.session_perawatan_data.perawatan.length > 0){
        let i = 1;
        for (let item of this.auth.session_perawatan_data.perawatan){
            if (i < this.auth.session_perawatan_data.perawatan.length){
                idrekomendasis += item.id + '::';
            } else {
                idrekomendasis += item.id;
            }
            i++;
        }
    }

    this.searchTimeout = setTimeout(() => {
      this.api.apiListPerawatanICD(idrekomendasis, this.auth.USER_TOKEN, this.searchPerawatanICD, this.activePage).subscribe(res => {
        this.tmpList = res;
        if (res != null && res.data != null){
          for (let item of res.data){
            this.perawatanICDs.push({
              code: item.code,
              desc: item.desc,
            });
          }
          this.tmpPerawatanICDs = JSON.parse(JSON.stringify(this.perawatanICDs));
        }

        let activeFound = false;
        let tmp = [];
        for (let item of this.perawatanICDs) {
          if (item.desc.toUpperCase().includes(this.searchPerawatanICD.toUpperCase())) {
            if (this.isActivePerawatanICD(item.code)) {
              activeFound = true;
              tmp.push(item.code);
            }
          }
        }
        if (!activeFound) {
          this.activePerawatanICD = [];
        } else {
          this.activePerawatanICD = JSON.parse(JSON.stringify(tmp));
        }
        this.isSearchingPerawatanICD = false;
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      });
    }, 1000);
  }

  isActivePerawatanICD(id) {
    for (let item of this.activePerawatanICD) {
      if (item == id) {
        return true;
      }
    }
    return false;
  }

  setActivePerawatanICD(id) {
    if (!this.isActivePerawatanICD(id)) {
      this.activePerawatanICD.push(id);
    } else {
      let index = this.activePerawatanICD.indexOf(id);
      if (index > -1) {
        this.activePerawatanICD.splice(index, 1);
      }
    }
  }

  togglePerawatanICDLainnya() {
    this.showPerawatanICDLainnya = !this.showPerawatanICDLainnya;
  }

  doSearchPerawatan(reset: boolean = false) {
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.perawatans = [];
    }
    this.isLoadingList = true;

    this.isSearchingPerawatan = true;

    if (this.searchTimeout != null){
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.api.apiListPerawatan(this.activePerawatanPKStatus.toString(), this.auth.USER_TOKEN, this.searchPerawatan, this.activePage).subscribe(res => {
        this.tmpList = res;
        if (res != null && res.data != null){
          for (let item of res.data){
            if (item.suggested != null){
              this.perawatans.push({
                id: item.kode,
                text: item.keterangan,
                suggested: item.suggested,
              });
            } else {
              this.perawatans.push({
                id: item.kode,
                text: item.keterangan,
                suggested: 0,
              });
            }
          }
          this.tmpPerawatans = JSON.parse(JSON.stringify(this.perawatans));
        }

        let activeFound = false;
        let tmp = [];
        for (let item of this.perawatans) {
          if (item.text.toUpperCase().includes(this.searchPerawatan.toUpperCase())) {
            // this.tmpPerawatans.push({ id: item.id, text: item.text });
            if (this.isActivePerawatan(item.id)) {
              activeFound = true;
              tmp.push(item.id);
            }
          }
        }
        if (!activeFound) {
          this.activePerawatan = [];
        } else {
          this.activePerawatan = JSON.parse(JSON.stringify(tmp));
        }
        this.isSearchingPerawatan = false;
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      });
    }, 1000);
  }

  isActivePerawatan(id) {
    for (let item of this.activePerawatan) {
      if (item == id) {
        return true;
      }
    }
    return false;
  }

  setActivePerawatan(id) {
    if (!this.isActivePerawatan(id)) {
      this.activePerawatan.push(id);
    } else {
      let index = this.activePerawatan.indexOf(id);
      if (index > -1) {
        this.activePerawatan.splice(index, 1);
      }
    }
  }

  setActivePerawatanPK(id) {
    for (let item of this.perawatansPK){
      if (item.id == id){
        if (this.activePerawatanPK.includes(id)){
          let index = this.activePerawatanPK.indexOf(item.id);
          this.activePerawatanPK.splice(index, 1);
          this.activePerawatanPKStatus = 0;
          this.diagnosaLainnya = '';
          this.perawatanLainnya = '';
          this.tindakanLainnya = '';
          this.searchDiagnosa = '';
          this.searchPerawatan = '';
          this.searchPerawatanICD = '';
          this.searchTindakan = '';
        } else {
          this.activePerawatanPK.push(item.id);
          this.activePerawatanPKStatus = item.status;
          this.diagnosaLainnya = '';
          this.perawatanLainnya = '';
          this.tindakanLainnya = '';
          this.searchDiagnosa = '';
          this.searchPerawatan = '';
          this.searchPerawatanICD = '';
          this.searchTindakan = '';
        }
      }
    }
    if (this.actionForm == 'diagnosa'){
      this.setDiagnosa(true);
    } else if (this.actionForm == 'rencana'){
      this.setPerawatan(true);
    } else if (this.actionForm == 'icd'){
      this.setPerawatanICD(true);
    }
  }

  togglePerawatanLainnya() {
    this.showPerawatanLainnya = !this.showPerawatanLainnya;
  }

  doSearchPrognosa(reset: boolean = false) {
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.prognosas = [];
    }
    this.isLoadingList = true;

    this.isSearchingPrognosa = true;

    if (this.searchTimeout != null){
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.api.apiListPrognosa(this.activePerawatanPKStatus.toString(), this.auth.USER_TOKEN, this.searchPrognosa, this.activePage).subscribe(res => {
        this.tmpList = res;
        if (res != null && res.data != null){
          for (let item of res.data){
            this.prognosas.push({
              idd: this.diagnosaPrognosa(),
              id: item.idprognosis,
              snomed_ct_id: item.snomed_ct_id,
              nama: item.nama,
              keterangan: '',
            });
          }
          this.tmpPrognosas = JSON.parse(JSON.stringify(this.prognosas));
        }

        let activeFound = false;
        let tmp = [];
        for (let item of this.prognosas) {
          if (item.text.toUpperCase().includes(this.searchPrognosa.toUpperCase())) {
            if (this.isActivePrognosa(item.id)) {
              activeFound = true;
              tmp.push(item.id);
            }
          }
        }
        if (!activeFound) {
          this.activePrognosa = [];
        } else {
          this.activePrognosa = JSON.parse(JSON.stringify(tmp));
        }
        this.isSearchingPrognosa = false;
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      });
    }, 1000);
  }

  diagnosaPrognosa(){
    if (this.activeDiagnosaT.length > 0){
      for (let item of this.diagnosasT){
        for (let pkitem of this.activeDiagnosaT){
          if (item.id == pkitem.id && item.idpk == pkitem.idpk){
            return pkitem.id;
          }
        }
      }
    }
    return 0;
  }

  isActivePrognosa(id) {
    for (let item of this.activePrognosa) {
      if (item == id) {
        return true;
      }
    }
    return false;
  }

  setActivePrognosa(id) {
    if (!this.isActivePrognosa(id)) {
      this.activePrognosa = [];
      this.activePrognosa.push(id);
      this.document.getElementById('prognosa-' + id).focus();
    } else {
      let index = this.activePrognosa.indexOf(id);
      if (index > -1) {
        this.activePrognosa.splice(index, 1);
      }
    }
    // console.log(this.activePrognosa);
    // console.log(this.tmpPrognosas);
    // console.log(this.isActivePrognosa(id));
  }

  doSearchDiagnosa(reset: boolean = false) {
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.diagnosas = [];
    }
    this.isLoadingList = true;

    this.isSearchingDiagnosa = true;

    if (this.searchTimeout != null){
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.api.apiListDiagnosa(this.activePerawatanPKStatus.toString(), this.auth.USER_TOKEN, this.searchDiagnosa, this.activePage).subscribe(res => {
        this.tmpList = res;
        if (res != null && res.data != null){
          for (let item of res.data){
            if (item.suggested != null){
              this.diagnosas.push({
                id: item.iddiagnosa,
                text: item.keterangan,
                satusehat: item.icd10,
                pcare: item.kodepcare,
                suggested: item.suggested,
              });
            } else {
              this.diagnosas.push({
                id: item.iddiagnosa,
                text: item.keterangan,
                satusehat: item.icd10,
                pcare: item.kodepcare,
                suggested: 0,
              });
            }
          }
          this.tmpDiagnosas = JSON.parse(JSON.stringify(this.diagnosas));
        }

        let activeFound = false;
        let tmp = [];
        for (let item of this.diagnosas) {
          if (item.text.toUpperCase().includes(this.searchDiagnosa.toUpperCase())) {
            //this.tmpDiagnosas.push({ id: item.id, text: item.text });
            if (this.isActiveDiagnosa(item.id)) {
              activeFound = true;
              tmp.push(item.id);
            }
          }
        }
        if (!activeFound) {
          this.activeDiagnosa = [];
        } else {
          this.activeDiagnosa = JSON.parse(JSON.stringify(tmp));
        }
        this.isSearchingDiagnosa = false;
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      });
    }, 1000);
  }

  isActiveDiagnosa(id) {
    for (let item of this.activeDiagnosa) {
      if (item == id) {
        return true;
      }
    }
    return false;
  }

  setActiveDiagnosa(id) {
    if (!this.isActiveDiagnosa(id)) {
      this.activeDiagnosa.push(id);
    } else {
      let index = this.activeDiagnosa.indexOf(id);
      if (index > -1) {
        this.activeDiagnosa.splice(index, 1);
      }
    }
  }

  toggleDiagnosaLainnya() {
    this.showDiagnosaLainnya = !this.showDiagnosaLainnya;
  }

  isSearching(){
    if (this.isSearchingDiagnosa || this.isSearchingTindakan || this.isSearchingPerawatan || this.isSearchingPerawatanICD || this.isSearchingPrognosa){
      return true;
    }
    return false;
  }

  doSearchObservasi(reset: boolean = false){
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.observasis = [];
    }
    this.isLoadingList = true;
    this.isSearchingObservasi = true;

    if (this.searchTimeout != null){
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.api.apiListObservasi(this.auth.USER_TOKEN, this.searchObservasi, this.activePage).subscribe(res => {
        this.tmpList = res;
        if (res != null && res.data != null){
          for (let item of res.data){
            this.observasis.push({
              id: item.idobservation,
              loinc_num: item.loinc_num,
              long_name: item.long_name,
              short_name: item.short_name,
              obs_code: item.obs_code,
              obs_unit: item.obs_unit,
              obs_unit_code: item.obs_unit_code,
              xvalue: '',
            });
          }
          this.tmpObservasis = JSON.parse(JSON.stringify(this.observasis));
        }

        let activeFound = false;
        let tmp = [];
        for (let item of this.observasis) {
          if (this.isActiveObservasi(item.id)) {
            activeFound = true;
            tmp.push(item.id);
          }
        }
        console.log(this.activeObservasi);
        if (!activeFound) {
          this.activeObservasi = [];
        } else {
          this.activeObservasi = JSON.parse(JSON.stringify(tmp));
        }
        this.isSearchingObservasi = false;
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      });
    }, 1000);
  }

  setObservasi(reset: boolean = false){
    if (reset){
      if (this.infiniteScroll != null){
        this.infiniteScroll.disabled = false;
      }
      this.activePage = 1;
      this.firstCall = true;
    }

    if (this.activePage == 1 && this.firstCall){
      this.observasis = [];
    }
    this.isLoadingList = true;

    this.api.apiListObservasi(this.auth.USER_TOKEN, this.searchObservasi, this.activePage).subscribe(res => {
      this.tmpList = res;

      if (res != null && res.data != null){
        for (let item of res.data){
          this.observasis.push({
            id: item.idobservation,
            loinc_num: item.loinc_num,
            long_name: item.long_name,
            short_name: item.short_name,
            obs_code: item.obs_code,
            obs_unit: item.obs_unit,
            obs_unit_code: item.obs_unit_code,
            xvalue: '',
          });
        }
        this.tmpObservasis = JSON.parse(JSON.stringify(this.observasis));
        if (this.firstCall){
          this.activePage++;
        }
        this.isLoadingList = false;
        this.firstCall = false;
      }
    });
  }

  setActiveObservasi(id){
    if (!this.isActiveObservasi(id)) {
      this.activeObservasi.push(id);
      this.document.getElementById('observasi-val-' + id).focus();
    } else {
      let index = this.activeObservasi.indexOf(id);
      if (index > -1) {
        this.activeObservasi.splice(index, 1);
      }
    }
  }

  isActiveObservasi(id){
    for (let item of this.activeObservasi) {
      if (item == id) {
        return true;
      }
    }
    return false;
  }

  observasiShowSearch(){
    return true;
  }

  saveObservasi(){
    for (let item of this.activeObservasi){
      for (let subitem of this.tmpObservasis){
        if (subitem.id == item){
          this.auth.postObservasi(subitem.id, subitem.xvalue, 'Action Panel Aplikasi Dokter');
        }
      }
    }
    this.mc.dismiss();
  }

  allowToSaveObservasi(){
    if (this.activeObservasi.length > 0){
      return true;
    }
    return false;
  }

  getPemKlinis(id, status, setItem) {
    if (id.length > 0){
      let rtext = '';
      for (let pkitem of id){
        for (let item of setItem) {
          if (item.id == pkitem) {
            rtext += item.text + ', ';
          }
        }
      }
      return rtext;
    } else {
      for (let item of setItem) {
        if (item.id == id && item.status == status) {
          return item.text;
        }
      }
    }
    return 0;
  }

  getKlinisByT(id, setItem) {
    if (id.length > 0){
      let rtext = '';
      for (let pkitem of id){
        for (let item of setItem){
          if (item.id == pkitem.idpk){
            rtext += item.text + ', ';
          }
        }
      }
      return rtext;
    } else {
      for (let item of setItem) {
        if (item.id == id) {
          return item.text;
        }
      }
    }
    return 0;
  }

  getKlinisByTT(id, setItem, setItem2) {
    if (id.length > 0){
      let rtext = '';
      for (let pkitem of id){
        for (let item of setItem){
          if (item.id == pkitem.idpk){
            let ext = '';
            for (let item2 of setItem2){
              if (item2.id == pkitem.id && item2.idpk == pkitem.idpk){
                ext = ' - ' + item2.text;
              }
            }
            rtext += item.text + ext + ', ';
          }
        }
      }
      return rtext;
    } else {
      for (let item of setItem) {
        if (item.id == id) {
          return item.text;
        }
      }
    }
    return 0;
  }

  getPerawatanT(id, setItem) {
    for (let item of setItem) {
      if (item.id == id) {
        return item.text;
      }
    }
    return 0;
  }

  foundPerawatanT(item){
    for (let pkitem of this.activePerawatanT){
      if (item.id == pkitem.id && item.idpk == pkitem.idpk){
        return true;
      }
    }
    return false;
  }

  foundDiagnosaT(item){
    for (let pkitem of this.activeDiagnosaT){
      if (item.id == pkitem.id && item.idpk == pkitem.idpk){
        return true;
      }
    }
    return false;
  }

  toggleTopOptions() {
    this.showTopOptions = !this.showTopOptions;
  }

  getHours(time: Date): string{
    if (time){
      let s = time.getHours() + ':' + time.getMinutes();
      let splitted = s.split(':');
      let h: string = ((parseInt(splitted[0])) < 10) ? '0' + (parseInt(splitted[0])).toString() : (parseInt(splitted[0])).toString();
      let m: string = (parseInt(splitted[1]) < 10) ? '0' + parseInt(splitted[1]).toString() : parseInt(splitted[1]).toString();
      return h + ':' + m;
    }
    return '00:00';
  }

  displayHarga(harga) {
    return Number(harga).toLocaleString('id');
  }

  invalidKlinis(no){
    let invalids = this.disabledOptions;
    let found = false;
    for (let i = 0; i < invalids.length; i++){
      if (invalids[i] == no){
        found = true;
      }
    }
    return found;
  }

  setBack(){
    this.mc.dismiss();
    this.auth.SEfrom = this.router.url;
  }

  async openDaftarEdukasi(){
    const isModalOpened = await this.mc.getTop();
    if (isModalOpened) {
      this.mc.dismiss();
      const modal = await this.mc.create({
        component: DaftaredukasiPage,
        cssClass: 'daftarHarga',
      });
      return await modal.present();
    } else {
      const modal = await this.mc.create({
        component: DaftaredukasiPage,
        cssClass: 'daftarHarga',
      });
      return await modal.present();
    }
  }

  async openPromo(){
    const isModalOpened = await this.mc.getTop();
    if (isModalOpened) {
      this.mc.dismiss();
      const modal = await this.mc.create({
        component: DaftarvoucherPage,
        cssClass: 'daftarHarga',
      });
      return await modal.present();
    } else {
      const modal = await this.mc.create({
        component: DaftarvoucherPage,
        cssClass: 'daftarHarga',
      });
      return await modal.present();
    }
  }

  openCicilan(){
    let target = 'https://instore.blibli.com/#cicilan_section';
    if (target != null){
      let x = this.iab.create(target, '_system', 'location=no');
    } else {
      this.auth.showToast("Tidak ada file edukasi");
    }
  }

  async openAsuransi(){
    const isModalOpened = await this.mc.getTop();
    if (isModalOpened) {
      this.mc.dismiss();
      const modal = await this.mc.create({
        component: DaftarasuransiPage,
        cssClass: 'daftarHarga',
      });
      return await modal.present();
    } else {
      const modal = await this.mc.create({
        component: DaftarasuransiPage,
        cssClass: 'daftarHarga',
      });
      return await modal.present();
    }
  }

  async openDaftarHarga(){
    const isModalOpened = await this.mc.getTop();
    if (isModalOpened) {
      this.mc.dismiss();
      const modal = await this.mc.create({
        component: DaftarhargaPage,
        cssClass: 'daftarHarga',
      });
      return await modal.present();
    } else {
      const modal = await this.mc.create({
        component: DaftarhargaPage,
        cssClass: 'daftarHarga',
      });
      return await modal.present();
    }
  }

  async openInfoPerawatan(){
    const isModalOpened = await this.mc.getTop();
    if (isModalOpened) {
      this.mc.dismiss();
      const modal = await this.mc.create({
        component: PopupinfoperawatanPage,
        cssClass: 'daftarHarga',
      });
      return await modal.present();
    } else {
      const modal = await this.mc.create({
        component: PopupinfoperawatanPage,
        cssClass: 'daftarHarga',
      });
      return await modal.present();
    }
  }

  doTempSave(){
    let idklinik = this.auth.ACTIVE_PATIENT_KLINIK;
    let idpenjadwalan = this.auth.ACTIVE_PATIENT_PENJADWALAN;
    let data = this.auth.session_perawatan_data;
    let is_posted = '0';
    let post_error_encounter = '0';
    this.api.apiPerawatanSaveTemp(idklinik, idpenjadwalan, data, null, null, is_posted, post_error_encounter, this.auth.USER_TOKEN).subscribe(res => {
      // console.log(data);
    });
  }
}
