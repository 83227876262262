import { Component, OnInit, ViewChild  } from '@angular/core';
import { Storage } from '@ionic/storage';
import { File, IWriteOptions } from '@ionic-native/file/ngx';
import { Platform, NavParams, ModalController } from '@ionic/angular';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { fromEvent } from 'rxjs';
import { map, tap, switchMap, takeUntil, finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AppSettings } from 'src/app/settings/settings';
import { CatatanPage } from 'src/app/pages/perawatan/catatan/catatan.page';

const STORAGE_KEY = "KLINIKOO_NOTES";

interface point{
  x: number,
  y: number,
}

interface path{
  initialPoint : point,
  nextPoint: point[],
  endPoint: point,
  color: string,
}

@Component({
  selector: 'app-draw-notes',
  templateUrl: './draw-notes.page.html',
  styleUrls: ['./draw-notes.page.scss'],
})
export class DrawNotesPage implements OnInit {

  colorPallete = [
    '#000000',
    '#9e2956',
    '#c2281d',
    '#de722f',
    '#edbf4c',
    '#5db37e',
    '#459cde',
    '#4250ad',
    '#802fa3'
  ];

  @ViewChild('notesCanvas') canvas: any;
  storedImages = [];
  canvasElement: any;
  activeColor = '#000000';
  activeX = 0;
  activeY = 0;
  strokeIndex = 0;
  trackPath : path[];
  tmpPath : path;
  root: CatatanPage;

  constructor(
    private auth: AuthService,
    private file: File,
    private storage: Storage,
    private plt: Platform,
    private webview: WebView,
    private params : NavParams,
    private sanitizer: DomSanitizer,
    private mc: ModalController,
  ) {
    // this.storage.ready().then(() => {
    //   this.storage.get(STORAGE_KEY).then(data => {
    //     this.storedImages = data;
    //   })
    // })
   }

  ngOnInit() {
    this.storage.remove(STORAGE_KEY);
    this.trackPath = [];
    this.tmpPath = {
      initialPoint : {x : 0, y: 0},
      nextPoint: [],
      endPoint: {x : 0, y: 0},
      color: "#000000",
    };
    if (this.params.data.root){
      this.root = this.params.data.root;
    }
    if (this.params.data.trackPath){
      this.trackPath = this.params.data.trackPath;
    }
  }

  ionViewDidEnter(){
    this.canvasElement = this.canvas.nativeElement;
    this.canvasElement.width = this.canvas.nativeElement.width;
    this.canvasElement.height = this.canvas.nativeElement.height;

    for (let item of this.trackPath){
      this.drawStroke(item);
    }

    if (this.canvasElement != null){
      const mouseDownStream = fromEvent(this.canvas.nativeElement, 'mousedown');
      const mouseMoveStream = fromEvent(this.canvas.nativeElement, 'mousemove');
      const mouseUpStream = fromEvent(window, 'mouseup');

      let context = this.canvasElement.getContext('2d');
      mouseDownStream.pipe(
        tap((event: MouseEvent) => {
          context.beginPath();
          context.strokeStyle = this.activeColor;
          context.lineWidth = 3;
          context.lineJoin = 'round';
          context.moveTo(event.offsetX, event.offsetY);

          this.activeX = event.offsetX;
          this.activeY = event.offsetY;

          this.tmpPath.initialPoint.x = this.activeX;
          this.tmpPath.initialPoint.y = this.activeY;
          this.tmpPath.color = this.activeColor;
        }),
        switchMap(() => mouseMoveStream.pipe(
          tap((event: MouseEvent) => {
            context.lineTo(event.offsetX, event.offsetY);
            context.stroke();
            this.activeX = event.offsetX;
            this.activeY = event.offsetY;

            this.tmpPath.nextPoint.push({
              x : this.activeX,
              y : this.activeY,
            });
          }),
          takeUntil(mouseUpStream),
          finalize(() => {
            context.closePath();
            this.createStack(null);
          })
        ))
      ).subscribe();
    }
  }

  selectColor(color){
    this.activeColor = color;
  }

  startDrawing(ev){
    var canvasPosition = (this.canvasElement != null) ? this.canvasElement.getBoundingClientRect() : {x: 0, y: 0};
    if (ev.touches != null){
      this.activeX = ev.touches[0].pageX - canvasPosition.x;
      this.activeY = ev.touches[0].pageY - canvasPosition.y;
    } else {
      this.activeX = ev.offsetX;
      this.activeY = ev.offsetY;
    }

    this.tmpPath.initialPoint.x = this.activeX;
    this.tmpPath.initialPoint.y = this.activeY;
    this.tmpPath.color = this.activeColor;
  }

  move(ev){
    var canvasPosition = (this.canvasElement != null) ? this.canvasElement.getBoundingClientRect() : {x: 0, y: 0};
    let context = this.canvasElement.getContext('2d');
    let currentX = 0;
    let currentY = 0;
    if (ev.touches != null){
      currentX = ev.touches[0].pageX - canvasPosition.x;
      currentY = ev.touches[0].pageY - canvasPosition.y;
    } else {
      currentX = ev.offsetX;
      currentY = ev.offsetY;
    }
    context.lineJoin = 'round';
    context.strokeStyle = this.activeColor;
    context.lineWidth = 3;

    context.beginPath();
    context.moveTo(this.activeX, this.activeY);
    context.lineTo(currentX, currentY);
    context.closePath();
    context.stroke();
    this.activeX = currentX;
    this.activeY = currentY;

    this.tmpPath.nextPoint.push({
      x : this.activeX,
      y : this.activeY,
    });
  }

  createStack(ev){
    let currentX = this.tmpPath.nextPoint[this.tmpPath.nextPoint.length - 1].x;
    let currentY = this.tmpPath.nextPoint[this.tmpPath.nextPoint.length - 1].y;
    this.tmpPath.endPoint.x = currentX;
    this.tmpPath.endPoint.y = currentY;
    this.strokeIndex += 1;
    this.trackPath.push(this.tmpPath);
    this.tmpPath = {
      initialPoint : {x : 0, y: 0},
      nextPoint: [],
      endPoint: {x : 0, y: 0},
      color: "#000000",
    };
    //let context = this.canvasElement.getContext('2d');
  }

  drawStroke(path: path){
    if (this.activeColor != path.color){
      this.activeColor = path.color;
    }

    let last : point = {
      x : path.initialPoint.x,
      y : path.initialPoint.y,
    }

    let context = this.canvasElement.getContext('2d');
    context.lineJoin = 'round';
    context.strokeStyle = this.activeColor;
    context.lineWidth = 3;

    for (let i = 0; i < path.nextPoint.length; i++){
      let item = path.nextPoint[i];
      context.beginPath();
      if (i == path.nextPoint.length - 1){
        context.moveTo(last.x, last.y);
        context.lineTo(path.endPoint.x, path.endPoint.y);
      } else {
        context.moveTo(last.x, last.y);
        context.lineTo(item.x, item.y);
        last.x = item.x;
        last.y = item.y;
      }
      context.stroke();
      context.closePath();
    }
  }

  canvasUndo(){
    //console.log(this.trackPath.length);
    let context = this.canvasElement.getContext('2d');
    context.clearRect(0,0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.trackPath.splice(this.trackPath.length - 1, 1);
    for (let item of this.trackPath){
      this.drawStroke(item);
    }
  }

  canvasClear(){
    //var canvasPosition = this.canvasElement.getBoundingClientRect();
    let context = this.canvasElement.getContext('2d');
    context.clearRect(0,0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.trackPath = [];
  }

  canvasImage(){
    // var dataUrl = this.canvasElement.toDataURL();
    // let context = this.canvasElement.getContext('2d');
    // context.clearRect(0,0, context.canvas.width, context.canvas.height);
    // let name = new Date().getTime() + '.png';
    // let path = this.file.dataDirectory;
    // let options : IWriteOptions = { replace: true, }
    //
    // var data = dataUrl.split(',')[1];
    // let blob = this.b64toBlob(data, 'image/png');
    //
    // this.file.writeFile(path, name, blob, options).then(res => {
    //   this.storeImage(name);
    //   this.mc.dismiss();
    // }, err => {
    //   console.log('error: ', err);
    // })

    var dataUrl = this.canvasElement.toDataURL();
    let context = this.canvasElement.getContext('2d');
    context.clearRect(0,0, context.canvas.width, context.canvas.height);

    var data = dataUrl.split(',')[1];
    let blob = this.b64toBlob(data, 'image/png');

    this.storeImage(blob);
    this.mc.dismiss();
  }

  b64toBlob(b64Data, contentType){
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize){
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++){
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  storeImage(imgBlob){
    // let filePath = this.file.dataDirectory + imageName;
    // let resPath = this.pathForImage(filePath);
    // let saveObj = { name: imageName, path: resPath, filePath: filePath };
    // this.root.fCatatanImg = saveObj;
    // this.root.lastDrawPath = this.trackPath;
    //this.storedImages.push(saveObj);
    //this.storage.set(STORAGE_KEY, this.storedImages);

    this.root.lastDrawPath = this.trackPath;
    // console.log(this.trackPath);
    this.auth.tmpCatatanGambarBlob = imgBlob;

    let objectURL = URL.createObjectURL(imgBlob);       
    this.auth.tmpCatatanGambar = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  pathForImage(img){
    if (img == null){
      return '';
    } else {
      if (this.plt.is('cordova')){
        const url = this.webview.convertFileSrc(img);
        return this.sanitizer.bypassSecurityTrustUrl(url);
      } else {
        let converted = this.webview.convertFileSrc(img);
        return converted;
      }
    }
  }

  removeImageAtIndex(index){
    let removed = this.storedImages.splice(index, 1);
    this.file.removeFile(this.file.dataDirectory, removed[0].img).then(res => {
    }, err => {
      console.log('remove err; ' ,err);
    });
    this.storage.set(STORAGE_KEY, this.storedImages);
  }

  isAppPWA(){
    return AppSettings.isAppPWA();
  }

}
