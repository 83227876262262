// Extra functionality to help api.service on managing basic issues with failed API requests

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { ToastController, AlertController } from '@ionic/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { AppSettings } from 'src/app/settings/settings';

@Injectable({
  providedIn: 'root'
})
export class ErrorManagementService{

  REQUEST_ERROR_MESSAGE_LIST = [
    {
      response: 404,
      kode: 'x01',
      show: false,
      message: 'Halaman tidak ditemukan',
    }, {
      response: 500,
      kode: 'x02',
      show: true,
      message: 'Ada masalah',
    }, {
      response: 403,
      kode: 'x03',
      show: true,
      message: 'Tidak bisa mengakses konten',
    }, {
      response: 401,
      kode: 'x04',
      show: true,
      message: 'Sesi sudah habis',
    },
  ]
  private current_total_api_list: number = AppSettings.API_COUNT;;
  private observables: Observable<any>[] = [];

  constructor(
    private ac: AlertController,
    private tc: ToastController,
  ) {
    this.observables = new Array(this.current_total_api_list + 1);
  }

  requestErrorHandler(toObserve: Observable<any>, nomor_api){
    let val = toObserve.pipe(share());
    if (this.observables[nomor_api] != null){
      if (this.observables[nomor_api] == val){
        val = this.observables[nomor_api];
      } else {
        this.observables[nomor_api] = val;
      }
    } else {
      this.observables[nomor_api] = val;
    }

    val.subscribe(() => {}, error => {
      this.showReqErrorToast(error, nomor_api);
    });

    return val;
  }

  async showReqErrorToast(ex: HttpErrorResponse, nomor_api){
    let item = this.getItem(ex.status);
    let code = this.getHexa(nomor_api) + item.kode;
    let message = item.message;
    if (item.show){
      const toast = await this.tc.create({
        message: 'Error: ' + code + '. ' + message,
        duration: 3000,
      })
      await toast.present();
    }
  }

  async showReqErrorAlert(ex: HttpErrorResponse, nomor_api){
    let item = this.getItem(ex.status);
    let code = this.getHexa(nomor_api) + item.kode;
    let message = item.message;
    if (item.show){
      const toast = await this.ac.create({
        header: 'Error: ' + code,
        message: message,
        buttons: ['OK'],
      })
      await toast.present();
    }
  }

  getItem(response: number){
    for (let item of this.REQUEST_ERROR_MESSAGE_LIST){
      if (item.response == response){
        return item;
      }
    }
  }

  getHexa(number: number){
    return number.toString(16);
  }
}
