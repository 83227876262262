import { NgModule, Injector } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Network } from '@ionic-native/network/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

import { AppComponent } from 'src/app/app.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { SelectiveLoadingStrategy } from 'src/app/others/preloader/selective-loading-strategy';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';
import { TabsService } from 'src/app/services/tabs/tabs.service';
import { ErrorManagementService } from 'src/app/services/api/error-management.service';
import { FotopasienPageModule } from 'src/app/pages/perawatan/fotopasien/fotopasien.module';
import { CatatandokterPageModule } from 'src/app/pages/modals/catatandokter/catatandokter.module';
import { DrawNotesPageModule } from 'src/app/pages/modals/draw-notes/draw-notes.module';
import { DaftarhargaPageModule } from 'src/app/pages/perawatan/daftarharga/daftarharga.module';
import { KonsulCancelPageModule } from 'src/app/pages/modals/konsul-cancel/konsul-cancel.module';
import { DaftaredukasiPageModule } from 'src/app/pages/perawatan/daftaredukasi/daftaredukasi.module';
import { PopuppenjadwalanPageModule } from 'src/app/pages/modals/popuppenjadwalan/popuppenjadwalan.module';
import { PopupcheckoutPageModule } from 'src/app/pages/modals/popupcheckout/popupcheckout.module';
import { PopupperawatanPageModule} from 'src/app/pages/modals/popupperawatan/popupperawatan.module';
import { PopupperiodontalPageModule } from 'src/app/pages/modals/popupperiodontal/popupperiodontal.module';
import { PopupttdPageModule } from 'src/app/pages/modals/popupttd/popupttd.module';
import { PopupdetailpenjadwalanPageModule } from 'src/app/pages/modals/popupdetailpenjadwalan/popupdetailpenjadwalan.module';
import { PopupperawatanpreviewPageModule } from 'src/app/pages/modals/popupperawatanpreview/popupperawatanpreview.module';
import { PopupnotesperawatanPageModule } from 'src/app/pages/modals/popupnotesperawatan/popupnotesperawatan.module';
import { PopupttdfinalPageModule } from 'src/app/pages/modals/popupttdfinal/popupttdfinal.module';
import { PopupviewjadwalPageModule } from 'src/app/pages/modals/popupviewjadwal/popupviewjadwal.module';
import { PopupinfoperawatanPageModule } from 'src/app/pages/modals/popupinfoperawatan/popupinfoperawatan.module';
import { PopupjadwalinputPageModule } from 'src/app/pages/modals/popupjadwalinput/popupjadwalinput.module';
import { PopupgigiPageModule } from 'src/app/pages/modals/popupgigi/popupgigi.module';
import { PopuppaymentPageModule } from 'src/app/pages/modals/popuppayment/popuppayment.module';
import { PopuptindakanPageModule } from 'src/app/pages/modals/popuptindakan/popuptindakan.module';
import { DaftarasuransiPageModule } from "./pages/perawatan/daftarasuransi/daftarasuransi.module";
import { DaftarvoucherPageModule } from "./pages/perawatan/daftarvoucher/daftarvoucher.module";
import { ViewpdfPageModule } from 'src/app/pages/perawatan/viewpdf/viewpdf.module';

const socketConfig: SocketIoConfig = { url: 'https://websocket.klinikoo.id', options: { transports: ['websocket'] } }

@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
       mode: 'ios',
    }),
    AppRoutingModule,
    PopupgigiPageModule,
    PopuppenjadwalanPageModule,
    PopupcheckoutPageModule,
    PopupperiodontalPageModule,
    PopupperawatanPageModule,
    PopupperawatanpreviewPageModule,
    PopupttdPageModule,
    PopupttdfinalPageModule,
    PopupdetailpenjadwalanPageModule,
    PopupnotesperawatanPageModule,
    PopupviewjadwalPageModule,
    PopupinfoperawatanPageModule,
    PopupjadwalinputPageModule,
    PopuppaymentPageModule,
    PopuptindakanPageModule,
    DaftarhargaPageModule,
    DaftaredukasiPageModule,
    DaftarasuransiPageModule,
    DaftarvoucherPageModule,
    DrawNotesPageModule,
    FotopasienPageModule,
    ViewpdfPageModule,
    CatatandokterPageModule,
    KonsulCancelPageModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    SocketIoModule.forRoot(socketConfig),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AuthService,
    ApiService,
    ErrorManagementService,
    TabsService,
    AppVersion,
    ScreenOrientation,
    Network,
    SelectiveLoadingStrategy,
    AppAvailability,
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;

  constructor(
    private auth: AuthService,
    private so: ScreenOrientation,
    private injector: Injector,
  ) {
    AppModule.injector = injector;
    this.so.lock(this.so.ORIENTATIONS.LANDSCAPE);
    this.auth.tokenLogin();
    this.auth.generateRandom();
  }
}

// platformBrowserDynamic().bootstrapModule(AppModule);
