import { Component, OnInit } from '@angular/core';
import { NavParams, AlertController, ToastController, ModalController } from '@ionic/angular';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LampiranfotoPage } from 'src/app/pages/perawatan/lampiranfoto/lampiranfoto.page';

@Component({
  selector: 'app-fotopasien',
  templateUrl: './fotopasien.page.html',
  styleUrls: ['./fotopasien.page.scss'],
})
export class FotopasienPage implements OnInit {

  image: any = {
    id : 0,
    src : "",
    date : "",
    caption: "",
  }

  type: number = 0;
  src: string = '';

  isLoading = true;
  root: LampiranfotoPage;

  constructor(
    public auth: AuthService,
    private params: NavParams,
    private ac: AlertController,
    private tc: ToastController,
    private mc: ModalController,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    //this.isLoading = true;
    this.isLoading = false;
    this.image = this.params.data.image;
    this.root = this.params.data.root;
    //this.loadImage();
  }

  loadImage() {
    var img = new Image();
    img.src = this.image.src;
    img.onload = ()=>{
      this.isLoading = false;
    }
    setTimeout(() => {
      if (this.isLoading){
        this.loadImage();
      } else {

      }
    }, 100)
  }

  async deleteFoto(){
    const alert = await this.ac.create({
      header : "Konfirmasi",
      message : "Yakin ingin menghapus foto ini?",
      buttons : [{
        text : "Ya",
        handler : ()=>{
          //Hapus
          const options = {
            headers : new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.USER_TOKEN)
            .set('key', this.auth.API_KEY)
          }

          this.http.get(this.auth.AUTH_SERVER_ADDRESS + "/api/delete-foto-lampiran/" + this.image.id, options)
          .subscribe(async res => {
            this.auth.session_checkup_data.lampiran.changed = true;
            const toast = await this.tc.create({
              message : res["success"].charAt(0).toUpperCase() + res["success"].slice(1),
              duration : 3000,
            })
            await toast.present();
            this.auth.session_checkup_data.lampiran.changed = true;
            this.root.isLoadingFoto = true;
            this.root.reloadFoto();
            this.mc.dismiss();
          });
        }
      },{
        text : "Tidak",
        role : 'cancel',
      }]
    });
    await alert.present();
  }

}
