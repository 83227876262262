// Store common used variables from the App
// Check index.html for settings that related to third party libraries like MidTrans

export class AppSettings {

  // API Settings
  // Update isProduction according to the use of the App
  // Please update API_COUNT according to number of API Request in the ApiService
  // Update the VERSION_POSTFIX according to the API version used

  static isProduction: boolean = true; //sementara api dev belum diset di server baru

  static API_COUNT: number = 109;
  static API_KEY: string = '12345678';
  static NON_PRODUCTION_ADDRESS: string = 'https://apidokter.klinikoo.id';
  static PRODUCTION_ADDRESS: string = 'https://apidokter.klinikoo.id';
  static VERSION_ADDRESS: string = 'https://klinikoo.id';
  static VERSION_POSTFIX_NON_PRODUCTION: string = '/api/v2';
  static VERSION_POSTFIX_PRODUCTION: string = '/api/v2';

  static getAPIAddress(){
    if (AppSettings.isProduction){
      return AppSettings.PRODUCTION_ADDRESS + AppSettings.VERSION_POSTFIX_PRODUCTION;
    } else {
      return AppSettings.NON_PRODUCTION_ADDRESS + AppSettings.VERSION_POSTFIX_NON_PRODUCTION;
    }
  }

  // Application type settings
  // Each number for the variable mean different type,
  // 1 for regular dentist mobile app
  // 2 for compact/lite version of the dentist mobile app
  // 3 for PWA version
  static ACTIVE_APP_TYPE = 3;
  static APP_TYPE_REGULAR = 1;
  static APP_TYPE_COMPACT = 2;
  static APP_TYPE_PWA = 3;

  static isAppRegular(){
    return this.ACTIVE_APP_TYPE == this.APP_TYPE_REGULAR;
  }

  static isAppCompact(){
    return this.ACTIVE_APP_TYPE == this.APP_TYPE_COMPACT;
  }

  static isAppPWA(){
    return this.ACTIVE_APP_TYPE == this.APP_TYPE_PWA;
  }

  // DEV Tools
  // Internal tools used to reduce some hassle when testing the App

  // Currently None

  // Minor Settings

  // Default time for Kode2Hours() in common.service
  // The function is used to translate time code from API (15 minutes per code)
  static kode2Rules = -1;
  static kode2Default = [0, 0, 0, 0];
  static kode2Base = 900; // 900 in seconds, 15 minutes

  static copyrightYear = 2020;
}
