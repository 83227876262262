import { Component, OnInit, NgZone } from '@angular/core';
import { ModalController, NavController, AlertController } from '@ionic/angular';
import { AppSettings } from 'src/app/settings/settings';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PopuppaymentPage } from 'src/app/pages/modals/popuppayment/popuppayment.page';
import { Router } from "@angular/router";

@Component({
  selector: 'app-popupcheckout',
  templateUrl: './popupcheckout.page.html',
  styleUrls: ['./popupcheckout.page.scss'],
})
export class PopupcheckoutPage implements OnInit {

  perawatan: string = '';
  isCheckedin: boolean = false;

  notesData = null;

  constructor(
    public auth: AuthService,
    private api: ApiService,
    private mc: ModalController,
    private ngZone: NgZone,
    private nc: NavController,
    private ac: AlertController,
    private router: Router,
  ) { }

  ngOnInit() {
    // console.log(this.auth.session_checkout_data);
    this.api.apiCekPasienCheckin(this.auth.DOKTER_KLINIK, this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res => {
      if (res.status){
        this.isCheckedin = true;
      } else {
        this.isCheckedin = false;
      }
    })
    this.api.apiPasienNext(this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res => {
      if (res != null && res.data != null){
        this.auth.session_checkout_data.penjadwalan.changed = true;
      } else {
        this.auth.session_checkout_data.penjadwalan.changed = false;
      }
    })

    if (this.auth.session_checkout_data.anamnesa.length > 0){
      this.perawatan = this.auth.session_checkout_data.anamnesa;
    }

    if (this.auth.FINISH_PERAWATAN){
      this.api.apiGetNotesPusat(this.auth.ACTIVE_PATIENT, this.auth.USER_TOKEN).subscribe(res => {
        this.notesData = res;
      });
    }

    if (!this.auth.in_checkout_process){
      if (!this.auth.isAccountLite){
        let that = this;
        setTimeout(async() =>{
          let alert = await this.ac.create({
            header: 'Pemberitahuan',
            message: 'Silahkan perbaharui rekam medis pasien',
            cssClass: 'modalsize',
            buttons: [
              {
                text: 'OK',
                handler: () => {
                  that.ngZone.run(() => {
                    that.auth.auto_show_popup_checkout = true;
                    that.auth.in_checkout_process = true;
                    that.router.navigateByUrl('/tabs/dentalrecord');
                    that.mc.dismiss();
                  });
                }
              }
            ]
          });
          await alert.present();
        }, 300);
      }
    }
  }

  async goto(target: string){
    this.ngZone.run(() => {
      this.nc.navigateForward('/tabs/' + target);
    });
    this.mc.dismiss();
  }

  skipOdontogram(){
    this.auth.session_checkout_data.odontogram.skip = !this.auth.session_checkout_data.odontogram.skip;
  }

  odontogramSkipped(){
    return this.auth.session_checkout_data.odontogram.skip;
  }

  isOdontogramChanged(){
    return this.auth.session_checkout_data.odontogram.changed;
  }

  skipOralExam(){
    this.auth.session_checkout_data.oralexam.skip = !this.auth.session_checkout_data.oralexam.skip;
  }

  oralExamSkipped(){
    return this.auth.session_checkout_data.oralexam.skip;
  }

  isOralExamChanged(){
    return this.auth.session_checkout_data.oralexam.changed;
  }

  skipPeriodontal(){
    this.auth.session_checkout_data.periodontal.skip = !this.auth.session_checkout_data.periodontal.skip;
  }

  periodontalSkipped(){
    return this.auth.session_checkout_data.periodontal.skip;
  }

  isPeriodontalChanged(){
    return this.auth.session_checkout_data.periodontal.changed;
  }

  isJadwalChanged(){
    return this.auth.session_checkout_data.penjadwalan.changed;
  }


  skipLampiran(){
    this.auth.session_checkout_data.lampiran.skip = !this.auth.session_checkout_data.lampiran.skip;
  }

  lampiranSkipped(){
    return this.auth.session_checkout_data.lampiran.skip;
  }

  isLampiranChanged(){
    return this.auth.session_checkout_data.lampiran.changed;
  }

  allowToCheckOut(){
    if ((this.auth.session_checkout_data.odontogram.skip || this.auth.session_checkout_data.odontogram.changed) &&
    (this.auth.session_checkout_data.oralexam.skip || this.auth.session_checkout_data.oralexam.changed) &&
    (this.auth.session_checkout_data.periodontal.skip || this.auth.session_checkout_data.periodontal.changed) &&
    (this.auth.session_checkout_data.lampiran.skip || this.auth.session_checkout_data.lampiran.changed)){
      return true;
    }
    return false;
  }

  submit(){
    if (!this.auth.FINISH_PERAWATAN){
      if (parseInt(this.auth.ACTIVE_PATIENT_PENJADWALAN) == 0 && parseInt(this.auth.ACTIVE_PATIENT_KLINIK) == 0){
        this.auth.showToast('Ada masalah, gagal check out');
      } else {
        let idpasien = '';
        let idperawatan = '';
        if (this.auth.session_checkout_data.idpasien != null && this.auth.session_checkout_data.idpasien != 0 &&
        this.auth.session_checkout_data.idperawatan != null && this.auth.session_checkout_data.idperawatan != 0){
          idpasien = this.auth.session_checkout_data.idpasien + '';
          idperawatan = this.auth.session_checkout_data.idperawatan + '';
        }
        // console.log(idpasien + '-' + idperawatan);
        this.api.apiCheckoutPasien(this.auth.ACTIVE_PATIENT_PENJADWALAN,
          this.auth.ACTIVE_PATIENT_KLINIK, this.auth.session_checkout_data.anamnesa, this.auth.USER_TOKEN,
          idpasien, idperawatan).subscribe(res => {
            this.auth.showToast(res.message);
            this.auth.resetSesiPasien();
            this.auth.resetSessionCheckout();
        });
      }
    }
    else {
      this.auth.FINISH_PERAWATAN = false;
      if (parseInt(this.auth.ACTIVE_PATIENT_PENJADWALAN) == 0 && parseInt(this.auth.ACTIVE_PATIENT_KLINIK) == 0){
        this.auth.showToast('Ada masalah, gagal check out');
      } else {
        let idpasien = '';
        let idperawatan = '';
        if (this.auth.session_checkout_data.idpasien != null && this.auth.session_checkout_data.idpasien != 0 &&
        this.auth.session_checkout_data.idperawatan != null && this.auth.session_checkout_data.idperawatan != 0){
          idpasien = this.auth.session_checkout_data.idpasien + '';
          idperawatan = this.auth.session_checkout_data.idperawatan + '';
        }
        // console.log(idpasien + '-' + idperawatan);
        this.api.apiCheckoutPasien(this.auth.ACTIVE_PATIENT_PENJADWALAN,
          this.auth.ACTIVE_PATIENT_KLINIK, this.auth.session_checkout_data.anamnesa, this.auth.USER_TOKEN,
          idpasien, idperawatan).subscribe(res => {
            this.auth.showToast(res.message);
            this.auth.resetSesiPasien();
            this.auth.resetSessionCheckout();
        });
      }
    }

    this.mc.dismiss();

    if (!AppSettings.isAppCompact()){
      this.ngZone.run(() => this.nc.navigateBack('/dentist')).then();
    } else {
      this.checkoutPayment();
    }

    this.auth.timerPemeriksaan = null;
  }

  async checkoutPayment() {
    const modal = await this.mc.create({
      component: PopuppaymentPage,
    });
    return await modal.present();
  }


  updatePerawatan(){
    this.auth.session_checkout_data.anamnesa = this.perawatan;
  }

}
