import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { IonContent, IonSlides, ModalController, NavController, NavParams } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-popuppayment',
  templateUrl: './popuppayment.page.html',
  styleUrls: ['./popuppayment.page.scss'],
})
export class PopuppaymentPage implements OnInit {
  @ViewChild('slider') slider: IonSlides;
  @ViewChild('content') content: IonContent;

  selectedContent = 0;

  idklinik = '0';
  idpenjadwalan = '0';

  value: {
    namapasien: string,
    namaperawatan: string,
    biayaperawatan: number,
    biayaperawatantxt: string,
    keterangantambahan: string,
    biayatambahan: number,
    biayatambahantxt: string,
    usevoucher: boolean,
    vouchercode: string,
    usepoint: boolean,
    totalpoint: number,
    pointusage: number,
    pointRP: number,
    pointHP: string,
    pembayar: string,
    telppembayar: string,
    metodepembayaran: number,
    isaction: boolean,
    totaltagihan: number,
  } = {
    namapasien: '',
    namaperawatan: '',
    biayaperawatan: 0,
    biayaperawatantxt: '',
    keterangantambahan: '',
    biayatambahan: 0,
    biayatambahantxt: '',
    usevoucher: false,
    vouchercode: '',
    usepoint: false,
    totalpoint: 0,
    pointusage: 0,
    pointRP: 0,
    pointHP: '',
    pembayar: '',
    telppembayar: '',
    metodepembayaran: 1,
    isaction: false,
    totaltagihan: 0,
  }

  infocheckout: any = {};

  voucheralert: {
    valid: boolean,
    message: string,
    checked: boolean,
  } = {
    valid: false,
    message: '',
    checked: false,
  }

  isAlternate: boolean = false;

  constructor(
    public auth: AuthService,
    private mc: ModalController,
    private api: ApiService,
    private ngZone: NgZone,
    private nc: NavController,
    private iab: InAppBrowser,
    private params: NavParams,
  ) { }

  ngOnInit() {
    if (this.params.data.alternate != null){
      this.isAlternate = this.params.data.alternate;
    }

    this.slider.lockSwipes(true);

    this.idklinik = this.auth.ACTIVE_PATIENT_KLINIK;
    this.idpenjadwalan = this.auth.ACTIVE_PATIENT_PENJADWALAN;

    this.api.apiInformasiCheckout(this.idklinik, this.idpenjadwalan, this.auth.USER_TOKEN).subscribe(res => {
      if (res.data != null){
        this.infocheckout = res.data;
        this.value.isaction = res.data.isaction;
        this.value.namapasien = res.data.pasien;
        this.value.pembayar = res.data.pasien;

        if (this.value.isaction){
          if (res.data.perawatan != null && res.data.perawatan.length > 0){
            this.value.biayaperawatan = 0;
            for (let item of res.data.perawatan){
              this.value.biayaperawatan += item.harga;
            }
          }
          this.value.totaltagihan = this.value.biayaperawatan + this.value.biayatambahan;
        }
      }
    });
  }

  refreshPoint(){
    this.api.apiTotalPoin(this.value.pointHP, this.auth.USER_TOKEN).subscribe(res => {
      this.value.totalpoint = res.poin;
    })
  }

  submit(){
    this.api.apiDoPayment(this.idklinik, this.idpenjadwalan, this.value.totaltagihan, this.value.keterangantambahan, this.value.biayatambahan, this.value.usevoucher, this.value.vouchercode, this.value.usepoint,
    this.value.pointHP, this.value.pembayar, this.value.metodepembayaran, this.auth.USER_TOKEN).subscribe(res => {
      this.auth.showToast(res.message);

      if (this.isAlternate){
        this.auth.ACTIVE_PATIENT = '1';
        this.auth.ACTIVE_PATIENT_KLINIK = '0';
        this.auth.ACTIVE_PATIENT_PENJADWALAN = '0';
      }

      let file = (res.file != null) ? res.file : (res.data != null && res.data.file != null) ? res.data.file : null;
      if (file != null){
        let x = this.iab.create(file, '_system', 'location=no');
      }

      this.mc.dismiss();

      this.ngZone.run(() => this.nc.navigateBack('/dentist')).then();
    });
  }

  checkVoucher(){
    if (this.value.vouchercode.length > 0){
      this.api.apiVoucherAvailability(this.value.vouchercode, this.auth.USER_TOKEN).subscribe(res => {
        this.voucheralert.checked = true;

        if (res.data != null){
          this.voucheralert.valid = res.data.valid;
          this.voucheralert.message = res.data.message;
        }
      });
    }
  }

  getMetodeText(){
    if (this.value.metodepembayaran == 1){
      return 'Tunai';
    } else if (this.value.metodepembayaran == 2){
      return 'Kartu Kredit';
    } else if (this.value.metodepembayaran == 3){
      return 'Asuransi';
    } else if (this.value.metodepembayaran == 4){
      return 'Pembayaran Online (Midtrans)';
    }
  }

  typingBiayaTambahan(){
    let onlyNumbers = '';
    for (let item of this.value.biayatambahantxt){
      if (item == ',' || item == '.'){

      } else {
        onlyNumbers += item;
      }
    }

    if (onlyNumbers.length > 0 && parseInt(onlyNumbers) > 0){
      this.value.biayatambahan = Number(onlyNumbers);
      this.value.biayatambahantxt = Number(onlyNumbers).toLocaleString('id');
    }

    this.value.totaltagihan = this.value.biayaperawatan + this.value.biayatambahan;
  }

  typingBiayaPerawatan(){
    let onlyNumbers = '';
    for (let item of this.value.biayaperawatantxt){
      if (item == ',' || item == '.'){

      } else {
        onlyNumbers += item;
      }
    }

    if (onlyNumbers.length > 0 && parseInt(onlyNumbers) > 0){
      this.value.biayaperawatan = Number(onlyNumbers);
      this.value.biayaperawatantxt = Number(onlyNumbers).toLocaleString('id');
    }

    this.value.totaltagihan = this.value.biayaperawatan + this.value.biayatambahan;
  }

  displayHarga(harga) {
    return Number(harga).toLocaleString('id');
  }

  kode2Hours(kode: number) {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    today.setMinutes((kode) * 15);
    return this.getHours(today);
  }

  getHours(time: Date): string {
    if (time) {
      let s = time.getHours() + ':' + time.getMinutes();
      let splitted = s.split(':');
      let h: string = ((parseInt(splitted[0])) < 10) ? '0' + (parseInt(splitted[0])).toString() : (parseInt(splitted[0])).toString();
      let m: string = (parseInt(splitted[1]) < 10) ? '0' + parseInt(splitted[1]).toString() : parseInt(splitted[1]).toString();
      return h + ':' + m;
    }
    return '00:00';
  }

  segmentChanged(e) {
    this.slider.lockSwipes(false);
    this.slider.slideTo(e.detail.value);
    this.selectedContent = e.detail.value;
    this.slider.lockSwipes(true);
  }

  sliderChanged(e) {
    this.content.scrollToTop();
    this.slider.getActiveIndex().then((v) => {
        this.selectedContent = v;
    });
  }

  nextSlide() {
    this.slider.lockSwipes(false);
    this.slider.slideNext();
    this.slider.lockSwipes(true);
  }

  previousSlide() {
    this.slider.lockSwipes(false);
    this.slider.slidePrev();
    this.slider.lockSwipes(true);
  }
}
