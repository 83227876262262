import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';
import { ReviewjadwalPage } from 'src/app/pages/pra-perawatan/reviewjadwal/reviewjadwal.page';
import { PopuppenjadwalanPage } from 'src/app/pages/modals/popuppenjadwalan/popuppenjadwalan.page';
import { kliniksResponse } from 'src/app/services/api/api-response';

@Component({
  selector: 'app-popupdetailpenjadwalan',
  templateUrl: './popupdetailpenjadwalan.page.html',
  styleUrls: ['./popupdetailpenjadwalan.page.scss'],
})
export class PopupdetailpenjadwalanPage implements OnInit {
  @ViewChild('content') content: any;

  idklinik: any = null;
  idpenjadwalan: any = null;
  foto: any = null;
  nama: any = null;
  tanggallahir: any = null;
  namaklinik: any = null;
  tanggalpenjadwalan: any = null;
  mulai: any = null;
  selesai: any = null;
  tujuan: any = null;
  keluhan: any = null;
  waktu: any = null;
  status: any = null;
  idpasien: any = null;
  root: ReviewjadwalPage = null;

  new_tanggal: string = new Date().toISOString();
  new_mulai: string;
  new_selesai: string;
  new_klinik: string;
  new_penjadwalan: string;
  isPindahOpen: boolean = false;

  kliniks: kliniksResponse = null;
  jams = [{ text: "", value: 0 }];

  minDate = new Date().toISOString();

  textualValue = {
    nama: '',
    namaklinik: '',
    jam: '',
    tanggal: '',
  }

  constructor(
    public auth: AuthService,
    private params : NavParams,
    private mc: ModalController,
    private api: ApiService,
    private ac: AlertController,
    private zone: NgZone,
    private router: Router,
  ) { }

  ngOnInit() {
    this.idklinik = this.params.data.idklinik;
    this.idpenjadwalan = this.params.data.idpenjadwalan;
    this.foto = this.params.data.foto;
    this.nama = this.params.data.nama;
    this.tanggallahir = this.params.data.tanggallahir;
    this.namaklinik = this.params.data.namaklinik;
    this.tanggalpenjadwalan = this.params.data.tanggalpenjadwalan;
    this.mulai = this.params.data.mulai;
    this.selesai = this.params.data.selesai;
    this.tujuan = this.params.data.tujuan;
    this.keluhan = this.params.data.keluhan;
    this.waktu = this.params.data.waktu;
    this.status = this.params.data.status;
    this.idpasien = this.params.data.idpasien;

    this.root = this.params.data.root;
    this.setMinDate();
  }

  getListKlinik(){
    this.api.apiGetKlinikList(this.auth.USER_TOKEN).subscribe(res => {
      this.zone.run(()=> {
        this.kliniks = res;
      })
    })
  }

  getJadwalKosong(){
    if (this.new_klinik == null || this.new_klinik == ''){
      this.generateJam();
    } else {
      this.jams = [];
      let dt = new Date(this.new_tanggal);
      let x = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
      this.api.apiGetJadwalKosong(this.new_klinik, x, this.auth.USER_TOKEN).subscribe(res => {
        for (let item of res){
          if (item.keterangan.includes(':30-') || item.keterangan.includes(':00-')){
            this.jams.push({
              text: this.getWaktu(item.keterangan.split('-')[0]),
              value: item.kodepenjadwalan,
            })
          }
        }
      }, err => {
        this.generateJam();
      })
    }
  }

  generateJam(){
    this.jams = [];
    const dayInMinutes = 24 * 60;
    for (let i = 1; i < dayInMinutes / 15; i++){
      let minutes = (i * 15) % 60;
      let hours = Math.floor((i * 15) / 60);
      if (minutes % 30 == 0){
        this.jams.push({value: i + 1, text: this.getWaktu(hours + ':' + minutes)});
      }
    }
  }

  getWaktu(str: string){
    let splitted = str.split(':');
    let h: string = ((parseInt(splitted[0])) < 10) ? '0' + (parseInt(splitted[0])).toString() : (parseInt(splitted[0])).toString();
    let m: string = (parseInt(splitted[1]) < 10) ? '0' + parseInt(splitted[1]).toString() : parseInt(splitted[1]).toString();
    return h + ':' + m;
  }

  setMinDate() {
    let today = new Date();
    if (today.getHours() >= 15) {
      this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2, today.getHours()).toISOString();
    } else {
      this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, today.getHours()).toISOString();
    }
    this.new_tanggal = this.minDate;
  }

  isReady(){
    if (this.new_klinik == null || this.new_klinik == ''){
      return false;
    } else if (this.new_mulai == null || this.new_mulai == ''){
      return false;
    }
    return true;
  }

  async doPindah(idklinik, idpenjadwalan){
    // this.new_klinik = idklinik;
    // this.new_penjadwalan = idpenjadwalan;
    // this.isPindahOpen = true;
    //
    // this.getListKlinik();
    // this.getJadwalKosong();
    // this.scrollToBottom();

    let tp = new Date().toISOString();
    if (this.tanggalpenjadwalan != null){
      tp = new Date(this.tanggalpenjadwalan).toISOString();
    }

    const isModalOpened = await this.mc.getTop();
    const modal = await this.mc.create({
      component: PopuppenjadwalanPage,
      cssClass: 'modal-penjadwalan',
      componentProps: {
        root: this,
        mode: 2,
        idklinik: idklinik,
        idpenjadwalan: idpenjadwalan,
        tanggalpenjadwalan: tp,
      }
    });
    return await modal.present();

  }

  async doBatal(idklinik, idpenjadwalan){
    const alert = await this.ac.create({
      header: 'Konfirmasi Pembatalan',
      message: 'Apakah Anda yakin?',
      buttons: [
        {
          text: 'Tidak',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Ya',
          handler: () => {
            this.api.apiCancelJadwal(idklinik, idpenjadwalan, this.auth.USER_TOKEN).subscribe(async res => {
              //this.auth.session_checkup_data.penjadwalan.changed = true;
              this.auth.showToast(res.message);

              if (res.success){
                const subalert = await this.ac.create({
                  header: 'Pemberitahuan',
                  message: 'Pengajuan sudah dibuat dan akan diproses oleh petugas. Harap menunggu',
                });
                await subalert.present();
              }

              this.root.getJumlah30();
              this.root.getJadwalHari();
              this.mc.dismiss();
            })
          }
        }
      ]
    });
    await alert.present();
  }

  async toSubmit(){
    this.generateTextual();
    let mulai = this.new_mulai;
    let selesai = this.new_selesai;

    const alert = await this.ac.create({
      header: 'Konfirmasi Perpindahan',
      message: '' +
        '<strong>Klinik</strong><p class="ion-no-margin-top">'+ this.textualValue.namaklinik +'</p> ' +
        '<strong>Tanggal</strong><p class="ion-no-margin-top">'+ this.textualValue.tanggal +'</p> ' +
        '<strong>Pukul</strong><p class="ion-no-margin-top">'+ this.textualValue.jam +'</p> ',
      buttons: [
        {
          text: 'Batal',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Ok',
          handler: () => {
            let dt = new Date(this.new_tanggal);
            let tmp = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();

            this.api.apiPindahJadwal(this.idklinik, this.new_penjadwalan, tmp,
              mulai, selesai, this.new_klinik, this.auth.USER_TOKEN).subscribe(async res => {
              this.auth.showToast(res.message);

              if (res.success){
                const subalert = await this.ac.create({
                  header: 'Pemberitahuan',
                  message: 'Pengajuan sudah dibuat dan akan diproses oleh petugas. Harap menunggu',
                });
                await subalert.present();
              }

              //this.auth.session_checkup_data.penjadwalan.changed = true;
              this.isPindahOpen = false;
              this.root.getJumlah30();
              this.root.getJadwalHari();
              this.mc.dismiss();
            })
          }
        }
      ]
    });

    await alert.present();
  }

  openPasien(idpasien) {
    this.auth.ACTIVE_PATIENT = idpasien;
    this.zone.run(() => {
      this.router.navigateByUrl('/pasien');
      this.mc.dismiss();
    });
  }

  resetWaktu(){
    this.new_mulai = '';
    this.new_selesai = '';
  }

  scrollToBottom(){
    var el = document.getElementById('padd');
    if (el != null){
      var height = el.scrollHeight;
      this.content.scrollToPoint(0, height);
    } else {
      setTimeout(()=> {
        this.scrollToBottom();
      }, 100);
    }
  }

  generateTextual(){
    for (let item of this.kliniks.data){
      if (item.idklinik == parseInt(this.new_klinik)){
        this.textualValue.namaklinik = this.capitalize(item.namaklinik);
      }
    }
    for (let item of this.jams){
      if (item.value == parseInt(this.new_mulai)){
        this.textualValue.jam = item.text;
      }
    }
    this.new_tanggal = this.new_tanggal.split('T')[0];
    this.textualValue.tanggal = this.auth.getTanggalv2(this.new_tanggal);
    this.new_selesai = (parseInt(this.new_mulai) + 1).toString();
  }

  capitalize(str: string){
    let splitted = str.split(' ');
    let res = [];
    for (let item of splitted){
      item = item.charAt(0).toUpperCase() + item.substring(1, item.length);
      res.push(item);
    }
    return res.join(' ');
  }
}
